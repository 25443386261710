.goog-te-gadget img {
  vertical-align: middle;
  border: none;
  display: none;
}

.goog-te-gadget-simple {
  background-color: #fff;

  font-size: 10pt;
  display: inline-block;
  padding-top: 1px;
  padding-bottom: 2px;
  cursor: pointer;
  border-radius: 20px;
}

.goog-te-gadget-simple .VIpgJd-ZVi9od-xl07Ob-lTBxed span {
  text-decoration: none;
  font-size: 16px;
  padding: 3px;
}
.goog-te-gadget-simple .VIpgJd-ZVi9od-xl07Ob-lTBxed {
  text-decoration: none;
  font-size: 16px;
}
.VIpgJd-ZVi9od-aZ2wEe-wOHMyf {
  display: none;
}

.goog-te-combo,
.VIpgJd-ZVi9od-ORHb *,
.VIpgJd-ZVi9od-SmfZ *,
.VIpgJd-ZVi9od-xl07Ob *,
.VIpgJd-ZVi9od-vH1Gmf *,
.VIpgJd-ZVi9od-l9xktf * {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  display: none;
}

.goog-te-gadget {
  font-family: "Roboto", sans-serif;
  font-size: 11px;
  color: #666;
  white-space: nowrap;
}

#google_translate_element select {
  color: black !important;
  border: none !important;
  border-radius: 3px !important;
  padding: 6px 8px !important;
}

.skiptranslate iframe {
  display: none !important;
}

.fixed-con {
  z-index: 9724790009779558 !important;
  background-color: #f7f8fc;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
}

.VIpgJd-ZVi9od-aZ2wEe-OiiCO {
  width: 80px;
  height: 80px;
}
#google_translate_element {
  margin: 10px;
}
.goog-te-gadget-simple:before {
  font-size: 12px;
}
.goog-te-banner-frame .skiptranslate,
.goog-te-gadget-simple img,
img.goog-te-gadget-icon,
.goog-te-menu-value span,
.goog-text-highlight {
  display: none !important;
}

.goog-te-gadget-simple {
  background-color: transparent;
  border: 2px solid #eee;
  border-radius: 5px;
  padding: 5px 10px;
  box-shadow: 0 2px 10px -7px rgba(0, 0, 0, 0.2);
  font-size: 0;
}

.VIpgJd-ZVi9od-vH1Gmf-ibnC6b div .indicator {
  display: none !important;
}
.VIpgJd-ZVi9od-vH1Gmf div .text {
  font-family: "Roboto", sans-serif !important;
  font-size: 14px !important;
}

body {
  position: static !important;
  top: 0px !important;
}
/*---------------------------------------useful--------------------------------- */
#goog-gt-tt,
.goog-te-balloon-frame {
  display: none !important;
}
.goog-text-highlight {
  background: none !important;
  box-shadow: none !important;
}

#google_translate_element {
  position: absolute;
  right: 0;
  z-index: 100;
}

.VIpgJd-yAWNEb-VIpgJd-fmcmS-sn54Q {
  background-color: transparent !important;
  box-shadow: none !important;
}

.goog-te-gadget-simple .VIpgJd-ZVi9od-xl07Ob-lTBxed span:first-child {
  padding-right: 10px;
}
