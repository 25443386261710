.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 10px;
}

.item {
  background: #bbb;
  padding: 0px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  font-size: 22px;
}

.item img {
  width: 30vw;
  height: 30vh;
  object-fit: cover;
  border-radius: 8px;
}

.item a {
  line-height: 1.6 !important;
  font-size: 22px;
}

.thumb {
  margin-left: 15px !important;
}

.headTitle {
  line-height: 0.7 !important;
}

#image2 {
  height: 50vh;
}

.img-holder1 {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 75%;
  overflow: hidden;
  font-size: 10px !important;
}
.item2 {
  height: 40vh;
}
.item1 {
  height: 40vh;
}
#img1 {
  height: 50vh;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  padding: 10px;
}

.grid__item {
  text-align: left;
  font-size: 10px !important;
}

.grid__item:nth-child(1) {
  grid-row: 1 / 3;
  grid-column: 1 / 3;
}

.grid__item:nth-child(2) {
  grid-column: 3 / 5;
}

@media screen and (max-width: 600px) {
  .grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .grid__item:nth-child(1) {
    grid-row: auto;
    grid-column: auto;
  }

  .grid__item:nth-child(2) {
    grid-column: auto;
  }
}

.wpo-blog-hero-area .wpo-blog-grids .grid {
  width: 25%;
  float: left;
  padding: 0 7.5px 15px;
  width: 100% !important;
}

.thumb {
  margin-left: 15px;
  padding-top: 20px;
}

@media (max-width: 767px) {
  h2 {
    line-height: 1.1;
  }

  .thumb {
    margin-left: 10px;
    padding-top: 15px;
  }
}

@media (max-width: 576px) {
  h2 {
    line-height: 1;
  }

  .thumb {
    margin-left: 5px;
    padding-top: 10px;
  }
}
