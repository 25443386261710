ul.smothscroll {
    position: fixed;
    bottom: 25px;
    right:30px;
    list-style: none;
    z-index: 99;
}
ul.smothscroll a {
    background-color: rgba(55, 86, 247, 0.7);
    width: 45px;
    height: 45px;
    line-height: 45px;
    border-radius:50%;
    display: block;
    text-align: center;
    color: #fff;
    background-size: 200%,1px;
    background-position: 0;
    border: 2px solid #3756f7;
}
ul.smothscroll a:hover {
    background-color: #3756f7;
    color: #fff;
}

@media(max-width:767px){
    ul.smothscroll a {
        width: 30px;
        height: 30px;
        line-height: 25px;
    }
}

table{
    border-collapse: collapse;
    }
    
    .ck-table-resized th,tr,td{
    
    border:1px solid black;
    border-collapse: none;
    }
    
    td{
    padding:5px 7px
    
    }
    
    th h1{
    font-size:inherit;
    
    
    }


    .ck-table-resized colgroup col{
        border:1px solid black;
    }

    .ck-table-resized tr td {
        border:1px solid black;
    }