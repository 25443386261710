@charset "UTF-8";
/*-----------------------------------------------------------------------------------

    Theme Name: Template Name – HTML5 Template
    Author: Theme Pure
    Support: basictheme@gmail.com
    Description: Template Name  – HTML5 Template
    Version: 1.0

-----------------------------------------------------------------------------------

/************ TABLE OF CONTENTS ***************

    01. THEME DEFAULT CSS
    02. HEADER CSS
    03. MEAN MENU CSS
	04. HERO CSS
	05. SLIDER CSS
	06. ABOUT CSS
	07. TEAM CSS
	08. SERVICES CSS
	09. TESTIMONIAL CSS
	10. BRAND CSS
	11. PROJECT CSS
	12. PORTFOLIO CSS
	13. GALLERY CSS
	14. CTA CSS
	15. FAQ CSS
	16. JOB CSS
	17. HELP CSS
	18. PRICE CSS
	19. FEATURE CSS 
	20. NEWSLETTER CSS
	21. SEARCH CCSS 
	22. BLOG CSS
	23. SIDEBAR CSS
	24. SHOP CSS 
	25. CHECKOUT CSS 
	26. FOOTER CSS

**********************************************/
/*----------------------------------------*/
/*  01. THEME DEFAULT CSS
/*----------------------------------------*/
:root {
  /**
  @font family declaration
  */
  --tp-ff-body: "Roboto", sans-serif;
  --tp-ff-heading: "Roboto", sans-serif;
  --tp-ff-p: "Roboto", sans-serif;
  /* --tp-ff-body: 'Archivo', sans-serif;
  --tp-ff-heading: 'Archivo', sans-serif;;
  --tp-ff-p: 'Archivo', sans-serif;; */
  --tp-ff-fontawesome: "Font Awesome 5 Pro";
  /**
  @color declaration
  */
  --tp-common-white: #ffffff;
  --tp-common-black: #070707;
  --tp-common-dark: #0b0b0b;
  --tp-common-dark-2: #0f0f0f;
  --tp-heading-primary: #070707;
  --tp-grey-1: #777777;
  --tp-grey-2: #acb3b7;
  --tp-grey-3: #757c80;
  --tp-grey-4: #999999;
  --tp-grey-5: #bbbbbb;
  --tp-grey-6: #c8c8c8;
  --tp-grey-7: #6e6e6e;
  --tp-grey-8: #a6a6a6;
  --tp-text-body: #000;
  --tp-text-1: #000;
  --tp-theme-bg: #f8fcfc;
  --tp-theme-bg-2: #f6f6f6;
  --tp-theme-bg-3: #f8fbff;
  --tp-theme-bg-4: #d3dbdf;
  --tp-theme-bg-5: #eef4f7;
  --tp-theme-bg-6: #f7f7f7;
  --tp-theme-bg-7: #f2f5f7;
  --tp-theme-bg-8: #e5eaee;
  --tp-theme-bg-9: #ecf8ff;
  --tp-theme-redical: #ff3c58;
  --tp-theme-red: #cd5c5c;
  --tp-theme-cornblue: #6c60fe;
  --tp-theme-vogue: #032b5f;
  --tp-theme-orange: #ff3221;
  --tp-theme-mirage: #1b1c31;
  --tp-theme-bouquet: #b685a1;
  --tp-theme-wine: #50223c;
  --tp-theme-mandalay: #b08920;
  --tp-theme-seagreen: #0d496b;
  --tp-theme-lochmara: #0686d8;
  --tp-theme-atlantis: #80be2d;
  --tp-theme-picton: #5fb0e5;
  --tp-border-1: #e6e6e6;
  --tp-border-2: #dce9e9;
  --tp-border-3: #ececec;
  --tp-border-4: #efefef;
  --tp-border-5: #f0f0f0;
  --tp-border-6: #f3f3f3;
  --tp-border-7: #e4e4e4;
  --tp-border-8: #ededed;
  --tp-border-9: #f0f0f0;
  --tp-border-10: #1c1c1c;
  --tp-border-11: #ebebeb;
  --tp-border-12: #e7e7e7;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/*---------------------------------
	typography css start 
---------------------------------*/
body {
  font-family: var(--tp-ff-body);
  font-size: 14px;
  font-weight: 400;
  color: var(--tp-text-body);
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--tp-ff-heading);
  color: var(--tp-heading-primary);
  margin-top: 0px;
  line-height: 1.2;
  font-weight: 400;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

h1 {
  font-size: 40px;
}

h2 {
  font-size: 32px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 16px;
}

ul {
  margin: 0px;
  padding: 0px;
}

p {
  font-family: var(--tp-ff-p);
  font-size: 14px;
  font-weight: 400;
  color: var(--tp-text-body);
  margin-bottom: 15px;
}

/* li {
  list-style: none;
} */

a,
.btn,
button,
span,
p,
i,
input,
select,
textarea,
li,
img,
svg path,
*::after,
*::before,
.transition-3,
h1,
h2,
h3,
h4,
h5,
h6 {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

a:focus,
.button:focus {
  text-decoration: none;
  outline: none;
}

a:focus,
a:hover {
  color: inherit;
  text-decoration: none;
}

a,
button {
  color: inherit;
  outline: none;
  border: none;
  background: transparent;
}

button:hover {
  cursor: pointer;
}

button:focus {
  outline: 0;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

input {
  outline: none;
}

input[type="color"] {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: none;
  border: 0;
  cursor: pointer;
  height: 100%;
  width: 100%;
  padding: 0;
  border-radius: 50%;
}

*::-moz-selection {
  background: var(--tp-common-black);
  color: var(--tp-common-white);
  text-shadow: none;
}

::-moz-selection {
  background: var(--tp-common-black);
  color: var(--tp-common-white);
  text-shadow: none;
}

::selection {
  background: var(--tp-common-black);
  color: var(--tp-common-white);
  text-shadow: none;
}

*::-moz-placeholder {
  color: var(--tp-common-black);
  font-size: var(--tp-fz-body);
  opacity: 1;
}

*::-webkit-input-placeholder {
  color: var(--tp-common-black);
  font-size: var(--tp-fz-body);
  opacity: 1;
}

*:-ms-input-placeholder {
  color: var(--tp-common-black);
  font-size: var(--tp-fz-body);
  opacity: 1;
}

*::-ms-input-placeholder {
  color: var(--tp-common-black);
  font-size: var(--tp-fz-body);
  opacity: 1;
}

*::placeholder {
  color: var(--tp-common-black);
  font-size: var(--tp-fz-body);
  opacity: 1;
}

/*---------------------------------
    common classes css start 
---------------------------------*/
.w-img img {
  width: 100%;
}

.m-img img {
  max-width: 100%;
}

.fix {
  overflow: hidden;
}

.clear {
  clear: both;
}

.z-index-1 {
  z-index: 1;
}

.z-index-11 {
  z-index: 11;
}

.overflow-y-visible {
  overflow-x: hidden;
  overflow-y: visible;
}

.p-relative {
  position: relative;
}

.p-absolute {
  position: absolute;
}

.include-bg {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.tp-border-bottom {
  border-bottom: 1px solid var(--tp-border-1);
}

.tp-border-top {
  border-top: 1px solid var(--tp-border-1);
}

.tp-border-bottom-dark {
  border-bottom: 1px solid #1c1c1c;
}

.tp-border-top-dark {
  border-top: 1px solid #1c1c1c;
}

.body-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 99;
  left: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.body-overlay.opened {
  opacity: 1;
  visibility: visible;
  cursor: pointer;
}

.tp-back-to-top {
  position: fixed;
  bottom: -40px;
  right: 40px;
  display: block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  background: var(--tp-theme-redical);
  color: #fff;
  text-align: center;
  text-decoration: none;
  border-radius: 50%;
  opacity: 0;
  transform: scale(0.3);
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);
  z-index: 9;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.tp-back-to-top:hover {
  color: white;
}
.tp-back-to-top.show {
  /* bottom: 180px;
  right: 40px; */
  bottom: 40px;
  right: 40px;
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  color: white;
}

/*--
    - Spacing
-----------------------------------------*/
.mt-5 {
  margin-top: 5px !important;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-55 {
  margin-top: 55px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-65 {
  margin-top: 65px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-75 {
  margin-top: 75px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-85 {
  margin-top: 85px;
}

.mt-90 {
  margin-top: 90px;
}

.mt-95 {
  margin-top: 95px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-105 {
  margin-top: 105px;
}

.mt-110 {
  margin-top: 110px;
}

.mt-115 {
  margin-top: 115px;
}

.mt-120 {
  margin-top: 120px;
}

.mt-125 {
  margin-top: 125px;
}

.mt-130 {
  margin-top: 130px;
}

.mt-135 {
  margin-top: 135px;
}

.mt-140 {
  margin-top: 140px;
}

.mt-145 {
  margin-top: 145px;
}

.mt-150 {
  margin-top: 150px;
}

.mt-155 {
  margin-top: 155px;
}

.mt-160 {
  margin-top: 160px;
}

.mt-165 {
  margin-top: 165px;
}

.mt-170 {
  margin-top: 170px;
}

.mt-175 {
  margin-top: 175px;
}

.mt-180 {
  margin-top: 180px;
}

.mt-185 {
  margin-top: 185px;
}

.mt-190 {
  margin-top: 190px;
}

.mt-195 {
  margin-top: 195px;
}

.mt-200 {
  margin-top: 200px;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-55 {
  margin-bottom: 55px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-65 {
  margin-bottom: 65px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-75 {
  margin-bottom: 75px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-85 {
  margin-bottom: 85px;
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-95 {
  margin-bottom: 95px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mb-105 {
  margin-bottom: 105px;
}

.mb-110 {
  margin-bottom: 110px;
}

.mb-115 {
  margin-bottom: 115px;
}

.mb-120 {
  margin-bottom: 120px;
}

.mb-125 {
  margin-bottom: 125px;
}

.mb-130 {
  margin-bottom: 130px;
}

.mb-135 {
  margin-bottom: 135px;
}

.mb-140 {
  margin-bottom: 140px;
}

.mb-145 {
  margin-bottom: 145px;
}

.mb-150 {
  margin-bottom: 150px;
}

.mb-155 {
  margin-bottom: 155px;
}

.mb-160 {
  margin-bottom: 160px;
}

.mb-165 {
  margin-bottom: 165px;
}

.mb-170 {
  margin-bottom: 170px;
}

.mb-175 {
  margin-bottom: 175px;
}

.mb-180 {
  margin-bottom: 180px;
}

.mb-185 {
  margin-bottom: 185px;
}

.mb-190 {
  margin-bottom: 190px;
}

.mb-195 {
  margin-bottom: 195px;
}

.mb-200 {
  margin-bottom: 200px;
}

.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-25 {
  margin-left: 25px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-35 {
  margin-left: 35px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-45 {
  margin-left: 45px;
}

.ml-50 {
  margin-left: 50px;
}

.ml-55 {
  margin-left: 55px;
}

.ml-60 {
  margin-left: 60px;
}

.ml-65 {
  margin-left: 65px;
}

.ml-70 {
  margin-left: 70px;
}

.ml-75 {
  margin-left: 75px;
}

.ml-80 {
  margin-left: 80px;
}

.ml-85 {
  margin-left: 85px;
}

.ml-90 {
  margin-left: 90px;
}

.ml-95 {
  margin-left: 95px;
}

.ml-100 {
  margin-left: 100px;
}

.ml-105 {
  margin-left: 105px;
}

.ml-110 {
  margin-left: 110px;
}

.ml-115 {
  margin-left: 115px;
}

.ml-120 {
  margin-left: 120px;
}

.ml-125 {
  margin-left: 125px;
}

.ml-130 {
  margin-left: 130px;
}

.ml-135 {
  margin-left: 135px;
}

.ml-140 {
  margin-left: 140px;
}

.ml-145 {
  margin-left: 145px;
}

.ml-150 {
  margin-left: 150px;
}

.ml-155 {
  margin-left: 155px;
}

.ml-160 {
  margin-left: 160px;
}

.ml-165 {
  margin-left: 165px;
}

.ml-170 {
  margin-left: 170px;
}

.ml-175 {
  margin-left: 175px;
}

.ml-180 {
  margin-left: 180px;
}

.ml-185 {
  margin-left: 185px;
}

.ml-190 {
  margin-left: 190px;
}

.ml-195 {
  margin-left: 195px;
}

.ml-200 {
  margin-left: 200px;
}

.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-25 {
  margin-right: 25px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-35 {
  margin-right: 35px;
}

.mr-40 {
  margin-right: 40px;
}

.mr-45 {
  margin-right: 45px;
}

.mr-50 {
  margin-right: 50px;
}

.mr-55 {
  margin-right: 55px;
}

.mr-60 {
  margin-right: 60px;
}

.mr-65 {
  margin-right: 65px;
}

.mr-70 {
  margin-right: 70px;
}

.mr-75 {
  margin-right: 75px;
}

.mr-80 {
  margin-right: 80px;
}

.mr-85 {
  margin-right: 85px;
}

.mr-90 {
  margin-right: 90px;
}

.mr-95 {
  margin-right: 95px;
}

.mr-100 {
  margin-right: 100px;
}

.mr-105 {
  margin-right: 105px;
}

.mr-110 {
  margin-right: 110px;
}

.mr-115 {
  margin-right: 115px;
}

.mr-120 {
  margin-right: 120px;
}

.mr-125 {
  margin-right: 125px;
}

.mr-130 {
  margin-right: 130px;
}

.mr-135 {
  margin-right: 135px;
}

.mr-140 {
  margin-right: 140px;
}

.mr-145 {
  margin-right: 145px;
}

.mr-150 {
  margin-right: 150px;
}

.mr-155 {
  margin-right: 155px;
}

.mr-160 {
  margin-right: 160px;
}

.mr-165 {
  margin-right: 165px;
}

.mr-170 {
  margin-right: 170px;
}

.mr-175 {
  margin-right: 175px;
}

.mr-180 {
  margin-right: 180px;
}

.mr-185 {
  margin-right: 185px;
}

.mr-190 {
  margin-right: 190px;
}

.mr-195 {
  margin-right: 195px;
}

.mr-200 {
  margin-right: 200px;
}

.pt-5 {
  padding-top: 5px !important;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-35 {
  padding-top: 35px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-45 {
  padding-top: 45px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-55 {
  padding-top: 55px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-65 {
  padding-top: 65px;
}

.pt-70 {
  padding-top: 70px;
}

.pt-75 {
  padding-top: 75px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-85 {
  padding-top: 85px;
}

.pt-90 {
  padding-top: 90px;
}

.pt-95 {
  padding-top: 95px;
}

.pt-100 {
  padding-top: 100px;
}

.pt-105 {
  padding-top: 105px;
}

.pt-110 {
  padding-top: 110px;
}

.pt-115 {
  padding-top: 115px;
}

.pt-120 {
  padding-top: 120px;
}

.pt-125 {
  padding-top: 125px;
}

.pt-130 {
  padding-top: 130px;
}

.pt-135 {
  padding-top: 135px;
}

.pt-140 {
  padding-top: 140px;
}

.pt-145 {
  padding-top: 145px;
}

.pt-150 {
  padding-top: 150px;
}

.pt-155 {
  padding-top: 155px;
}

.pt-160 {
  padding-top: 160px;
}

.pt-165 {
  padding-top: 165px;
}

.pt-170 {
  padding-top: 170px;
}

.pt-175 {
  padding-top: 175px;
}

.pt-180 {
  padding-top: 180px;
}

.pt-185 {
  padding-top: 185px;
}

.pt-190 {
  padding-top: 190px;
}

.pt-195 {
  padding-top: 195px;
}

.pt-200 {
  padding-top: 200px;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-55 {
  padding-bottom: 55px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-65 {
  padding-bottom: 65px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-75 {
  padding-bottom: 75px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-85 {
  padding-bottom: 85px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pb-95 {
  padding-bottom: 95px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-105 {
  padding-bottom: 105px;
}

.pb-110 {
  padding-bottom: 110px;
}

.pb-115 {
  padding-bottom: 115px;
}

.pb-120 {
  padding-bottom: 120px;
}

.pb-125 {
  padding-bottom: 125px;
}

.pb-130 {
  padding-bottom: 130px;
}

.pb-135 {
  padding-bottom: 135px;
}

.pb-140 {
  padding-bottom: 140px;
}

.pb-145 {
  padding-bottom: 145px;
}

.pb-150 {
  padding-bottom: 150px;
}

.pb-155 {
  padding-bottom: 155px;
}

.pb-160 {
  padding-bottom: 160px;
}

.pb-165 {
  padding-bottom: 165px;
}

.pb-170 {
  padding-bottom: 170px;
}

.pb-175 {
  padding-bottom: 175px;
}

.pb-180 {
  padding-bottom: 180px;
}

.pb-185 {
  padding-bottom: 185px;
}

.pb-190 {
  padding-bottom: 190px;
}

.pb-195 {
  padding-bottom: 195px;
}

.pb-200 {
  padding-bottom: 200px;
}

.pl-5 {
  padding-left: 5px;
}

.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-25 {
  padding-left: 25px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-35 {
  padding-left: 35px;
}

.pl-40 {
  padding-left: 40px;
}

.pl-45 {
  padding-left: 45px;
}

.pl-50 {
  padding-left: 50px;
}

.pl-55 {
  padding-left: 55px;
}

.pl-60 {
  padding-left: 60px;
}

.pl-65 {
  padding-left: 65px;
}

.pl-70 {
  padding-left: 70px;
}

.pl-75 {
  padding-left: 75px;
}

.pl-80 {
  padding-left: 80px;
}

.pl-85 {
  padding-left: 85px;
}

.pl-90 {
  padding-left: 90px;
}

.pl-95 {
  padding-left: 95px;
}

.pl-100 {
  padding-left: 100px;
}

.pl-105 {
  padding-left: 105px;
}

.pl-110 {
  padding-left: 110px;
}

.pl-115 {
  padding-left: 115px;
}

.pl-120 {
  padding-left: 120px;
}

.pl-125 {
  padding-left: 125px;
}

.pl-130 {
  padding-left: 130px;
}

.pl-135 {
  padding-left: 135px;
}

.pl-140 {
  padding-left: 140px;
}

.pl-145 {
  padding-left: 145px;
}

.pl-150 {
  padding-left: 150px;
}

.pl-155 {
  padding-left: 155px;
}

.pl-160 {
  padding-left: 160px;
}

.pl-165 {
  padding-left: 165px;
}

.pl-170 {
  padding-left: 170px;
}

.pl-175 {
  padding-left: 175px;
}

.pl-180 {
  padding-left: 180px;
}

.pl-185 {
  padding-left: 185px;
}

.pl-190 {
  padding-left: 190px;
}

.pl-195 {
  padding-left: 195px;
}

.pl-200 {
  padding-left: 200px;
}

.pr-5 {
  padding-right: 5px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-25 {
  padding-right: 25px;
}

.pr-30 {
  padding-right: 30px;
}

.pr-35 {
  padding-right: 35px;
}

.pr-40 {
  padding-right: 40px;
}

.pr-45 {
  padding-right: 45px;
}

.pr-50 {
  padding-right: 50px;
}

.pr-55 {
  padding-right: 55px;
}

.pr-60 {
  padding-right: 60px;
}

.pr-65 {
  padding-right: 65px;
}

.pr-70 {
  padding-right: 70px;
}

.pr-75 {
  padding-right: 75px;
}

.pr-80 {
  padding-right: 80px;
}

.pr-85 {
  padding-right: 85px;
}

.pr-90 {
  padding-right: 90px;
}

.pr-95 {
  padding-right: 95px;
}

.pr-100 {
  padding-right: 100px;
}

.pr-105 {
  padding-right: 105px;
}

.pr-110 {
  padding-right: 110px;
}

.pr-115 {
  padding-right: 115px;
}

.pr-120 {
  padding-right: 120px;
}

.pr-125 {
  padding-right: 125px;
}

.pr-130 {
  padding-right: 130px;
}

.pr-135 {
  padding-right: 135px;
}

.pr-140 {
  padding-right: 140px;
}

.pr-145 {
  padding-right: 145px;
}

.pr-150 {
  padding-right: 150px;
}

.pr-155 {
  padding-right: 155px;
}

.pr-160 {
  padding-right: 160px;
}

.pr-165 {
  padding-right: 165px;
}

.pr-170 {
  padding-right: 170px;
}

.pr-175 {
  padding-right: 175px;
}

.pr-180 {
  padding-right: 180px;
}

.pr-185 {
  padding-right: 185px;
}

.pr-190 {
  padding-right: 190px;
}

.pr-195 {
  padding-right: 195px;
}

.pr-200 {
  padding-right: 200px;
}

.tp-btn-hover {
  position: relative;
  z-index: 9;
  overflow: hidden;
}
.tp-btn-hover b {
  position: absolute;
  width: 0px;
  height: 0px;
  background-color: var(--tp-theme-cornblue);
  border-radius: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  z-index: -1;
}
.tp-btn-hover:hover b {
  width: 500px;
  height: 500px;
  display: inline-block;
}
.tp-btn-hover.alt-color b {
  background-color: var(--tp-theme-redical);
}
.tp-btn-hover.alt-bg-orange b {
  background-color: var(--tp-theme-orange);
}
.tp-btn-hover.alt-black-color b {
  background-color: black;
}
.tp-btn-hover.alt-bg-white b {
  background-color: #fff;
}
.tp-btn-hover.alt-bg-red b {
  background-color: var(--tp-theme-red);
}

.tp-btn {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 400;
  background-color: var(--tp-theme-redical);
  color: var(--tp-common-white);
  padding: 18px 29px;
  border-radius: 8px;
  display: inline-block;
}
.tp-btn:hover {
  color: var(--tp-common-white);
}
.tp-btn:hover span svg:first-child,
.tp-btn:hover span i:first-child {
  opacity: 1;
  transform: translateZ(0);
}
.tp-btn:hover span svg:last-child,
.tp-btn:hover span i:last-child {
  opacity: 0;
  transform: translateX(30px) translateY(0);
}
.tp-btn i {
  margin-left: 15px;
}
.tp-btn span {
  display: inline-block;
  position: relative;
}
.tp-btn span svg,
.tp-btn span i {
  transition: transform 0.6s cubic-bezier(0.77, 0, 0.175, 1),
    opacity 0.6s cubic-bezier(0.77, 0, 0.175, 1);
}
.tp-btn span svg:first-child,
.tp-btn span i:first-child {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transform: translateX(-20px) translateY(0);
}
.tp-btn span svg:last-child,
.tp-btn span i:last-child {
  opacity: 1;
  display: block;
  transform: translateY(0) translateY(0);
}
@media (max-width: 767px) {
  .tp-btn {
    font-size: 14px;
    padding: 15px 29px;
  }
}

.tp-common-btn {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 400;
  background-color: var(--tp-theme-cornblue);
  color: var(--tp-common-white);
  padding: 18px 29px;
  border-radius: 8px;
  display: inline-block;
}
.tp-common-btn:hover {
  color: var(--tp-common-white);
}
.tp-common-btn:hover span svg:first-child,
.tp-common-btn:hover span i:first-child {
  opacity: 1;
  transform: translateZ(0);
}
.tp-common-btn:hover span svg:last-child,
.tp-common-btn:hover span i:last-child {
  opacity: 0;
  transform: translateX(30px) translateY(0);
}
.tp-common-btn i {
  margin-left: 15px;
}
.tp-common-btn span {
  /* margin-left: 5px; */
  padding: 0 3px;
  display: inline-block;
  position: relative;
  font-size: 13px;
}
.tp-common-btn span svg,
.tp-common-btn span i {
  transition: transform 0.6s cubic-bezier(0.77, 0, 0.175, 1),
    opacity 0.6s cubic-bezier(0.77, 0, 0.175, 1);
}
.tp-common-btn span svg:first-child,
.tp-common-btn span i:first-child {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transform: translateX(-20px) translateY(0);
}
.tp-common-btn span svg:last-child,
.tp-common-btn span i:last-child {
  opacity: 1;
  display: block;
  transform: translateY(0) translateY(0);
}
@media (max-width: 767px) {
  .tp-common-btn {
    font-size: 14px;
    padding: 15px 29px;
  }
}

.tp-white-btn,
.tp-slider-btn {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 400;
  background-color: var(--tp-common-white);
  color: var(--tp-common-black);
  padding: 18px 29px;
  border-radius: 8px;
  display: inline-block;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06);
}
.tp-white-btn:hover,
.tp-slider-btn:hover {
  color: var(--tp-common-white);
  background-color: var(--tp-theme-redical);
}
.tp-white-btn:hover span svg:first-child,
.tp-white-btn:hover span i:first-child,
.tp-slider-btn:hover span svg:first-child,
.tp-slider-btn:hover span i:first-child {
  opacity: 1;
  transform: translateZ(0);
}
.tp-white-btn:hover span svg:last-child,
.tp-white-btn:hover span i:last-child,
.tp-slider-btn:hover span svg:last-child,
.tp-slider-btn:hover span i:last-child {
  opacity: 0;
  transform: translateX(30px) translateY(0);
}
.tp-white-btn:hover span i,
.tp-slider-btn:hover span i {
  color: var(--tp-common-white);
}
.tp-white-btn span,
.tp-slider-btn span {
  display: inline-block;
  position: relative;
}
.tp-white-btn span svg,
.tp-white-btn span i,
.tp-slider-btn span svg,
.tp-slider-btn span i {
  transition: transform 0.6s cubic-bezier(0.77, 0, 0.175, 1),
    opacity 0.6s cubic-bezier(0.77, 0, 0.175, 1);
}
.tp-white-btn span svg:first-child,
.tp-white-btn span i:first-child,
.tp-slider-btn span svg:first-child,
.tp-slider-btn span i:first-child {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transform: translateX(-20px) translateY(0);
}
.tp-white-btn span svg:last-child,
.tp-white-btn span i:last-child,
.tp-slider-btn span svg:last-child,
.tp-slider-btn span i:last-child {
  opacity: 1;
  display: block;
  transform: translateY(0) translateY(0);
}
@media (max-width: 767px) {
  .tp-white-btn,
  .tp-slider-btn {
    font-size: 14px;
    padding: 15px 29px;
  }
}

.tp-white-btn-rgb {
  position: relative;
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 400;
  background-color: var(--tp-common-white);
  color: var(--tp-common-black);
  padding: 18px 29px;
  display: inline-block;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06);
  z-index: 2;
}
.tp-white-btn-rgb::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: linear-gradient(
    100.16deg,
    #5a6ef8 9.94%,
    #a874c6 54.82%,
    #fe7a8f 95.56%
  );
  z-index: -1;
  opacity: 0;
}
.tp-white-btn-rgb:hover {
  color: var(--tp-common-white);
}
.tp-white-btn-rgb:hover span svg:first-child,
.tp-white-btn-rgb:hover span i:first-child {
  opacity: 1;
  transform: translateZ(0);
}
.tp-white-btn-rgb:hover span svg:last-child,
.tp-white-btn-rgb:hover span i:last-child {
  opacity: 0;
  transform: translateX(30px) translateY(0);
}
.tp-white-btn-rgb:hover span i {
  color: var(--tp-common-white);
}
.tp-white-btn-rgb:hover::after {
  opacity: 1;
}
.tp-white-btn-rgb span {
  display: inline-block;
  position: relative;
}
.tp-white-btn-rgb span svg,
.tp-white-btn-rgb span i {
  transition: transform 0.6s cubic-bezier(0.77, 0, 0.175, 1),
    opacity 0.6s cubic-bezier(0.77, 0, 0.175, 1);
}
.tp-white-btn-rgb span svg:first-child,
.tp-white-btn-rgb span i:first-child {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transform: translateX(-20px) translateY(0);
}
.tp-white-btn-rgb span svg:last-child,
.tp-white-btn-rgb span i:last-child {
  opacity: 1;
  display: block;
  transform: translateY(0) translateY(0);
}
@media (max-width: 767px) {
  .tp-white-btn-rgb {
    font-size: 14px;
    padding: 15px 29px;
  }
}

.tp-border-btn {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 400;
  background-color: var(--tp-common-white);
  color: inherit;
  padding: 16px 29px;
  border-radius: 8px;
  display: inline-block;
  border: 2px solid var(--tp-border-9);
}
.tp-border-btn:hover {
  color: var(--tp-common-white);
  border-color: transparent;
}
.tp-border-btn:hover.br-btn-bg-dark {
  background-color: var(--tp-common-black);
}
.tp-border-btn:hover span svg:first-child,
.tp-border-btn:hover span i:first-child {
  opacity: 1;
  transform: translateZ(0);
}
.tp-border-btn:hover span svg:last-child,
.tp-border-btn:hover span i:last-child {
  opacity: 0;
  transform: translateX(30px) translateY(0);
}
.tp-border-btn:hover span i {
  color: var(--tp-common-white);
}
.tp-border-btn i {
  margin-left: 15px;
}
.tp-border-btn span {
  margin-left: 5px;
  display: inline-block;
  position: relative;
}
.tp-border-btn span svg,
.tp-border-btn span i {
  transition: transform 0.6s cubic-bezier(0.77, 0, 0.175, 1),
    opacity 0.6s cubic-bezier(0.77, 0, 0.175, 1);
}
.tp-border-btn span svg:first-child,
.tp-border-btn span i:first-child {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transform: translateX(-20px) translateY(0);
}
.tp-border-btn span svg:last-child,
.tp-border-btn span i:last-child {
  opacity: 1;
  display: block;
  transform: translateY(0) translateY(0);
}
@media (max-width: 767px) {
  .tp-border-btn {
    font-size: 14px;
    padding: 15px 29px;
  }
}

.tp-white-border-btn {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 400;
  background-color: var(--tp-common-white);
  color: inherit;
  padding: 16px 29px;
  border-radius: 8px;
  display: inline-block;
  border: 2px solid var(--tp-border-9);
}
.tp-white-border-btn:hover {
  color: var(--tp-common-white);
  background-color: var(--tp-common-black);
}
.tp-white-border-btn:hover.br-btn-bg-dark {
  background-color: var(--tp-common-black);
}
.tp-white-border-btn:hover span svg:first-child,
.tp-white-border-btn:hover span i:first-child {
  opacity: 1;
  transform: translateZ(0);
}
.tp-white-border-btn:hover span svg:last-child,
.tp-white-border-btn:hover span i:last-child {
  opacity: 0;
  transform: translateX(30px) translateY(0);
}
.tp-white-border-btn:hover span i {
  color: var(--tp-common-white);
}
.tp-white-border-btn i {
  margin-left: 15px;
}
.tp-white-border-btn span {
  margin-left: 5px;
  display: inline-block;
  position: relative;
}
.tp-white-border-btn span svg,
.tp-white-border-btn span i {
  transition: transform 0.6s cubic-bezier(0.77, 0, 0.175, 1),
    opacity 0.6s cubic-bezier(0.77, 0, 0.175, 1);
}
.tp-white-border-btn span svg:first-child,
.tp-white-border-btn span i:first-child {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transform: translateX(-20px) translateY(0);
}
.tp-white-border-btn span svg:last-child,
.tp-white-border-btn span i:last-child {
  opacity: 1;
  display: block;
  transform: translateY(0) translateY(0);
}
@media (max-width: 767px) {
  .tp-white-border-btn {
    font-size: 14px;
    padding: 15px 29px;
  }
}

.tp-border-btn-rgb {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 400;
  background-color: var(--tp-common-white);
  color: inherit;
  padding: 16px 29px;
  border-radius: 8px;
  display: inline-block;
  border: 2px solid var(--tp-border-9);
}
.tp-border-btn-rgb:hover {
  color: var(--tp-common-white);
  background-image: linear-gradient(
    100.16deg,
    #5a6ef8 9.94%,
    #a874c6 54.82%,
    #fe7a8f 95.56%
  );
  border-color: transparent;
}
.tp-border-btn-rgb:hover.br-btn-bg-dark {
  background-color: var(--tp-common-black);
}
.tp-border-btn-rgb:hover span svg:first-child,
.tp-border-btn-rgb:hover span i:first-child {
  opacity: 1;
  transform: translateZ(0);
}
.tp-border-btn-rgb:hover span svg:last-child,
.tp-border-btn-rgb:hover span i:last-child {
  opacity: 0;
  transform: translateX(30px) translateY(0);
}
.tp-border-btn-rgb:hover span i {
  color: var(--tp-common-white);
}
.tp-border-btn-rgb i {
  margin-left: 15px;
}
.tp-border-btn-rgb span {
  margin-left: 5px;
  display: inline-block;
  position: relative;
}
.tp-border-btn-rgb span svg,
.tp-border-btn-rgb span i {
  transition: transform 0.6s cubic-bezier(0.77, 0, 0.175, 1),
    opacity 0.6s cubic-bezier(0.77, 0, 0.175, 1);
}
.tp-border-btn-rgb span svg:first-child,
.tp-border-btn-rgb span i:first-child {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transform: translateX(-20px) translateY(0);
}
.tp-border-btn-rgb span svg:last-child,
.tp-border-btn-rgb span i:last-child {
  opacity: 1;
  display: block;
  transform: translateY(0) translateY(0);
}
@media (max-width: 767px) {
  .tp-border-btn-rgb {
    font-size: 14px;
    padding: 15px 29px;
  }
}

.tp-black-btn {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 400;
  background-color: rgba(var(--tp-common-dark), 0.2);
  color: white;
  padding: 16px 29px;
  border-radius: 8px;
  display: inline-block;
  border: 2px solid rgba(255, 255, 255, 0.2);
}
.tp-black-btn:hover {
  color: var(--tp-common-white);
  background-color: var(--tp-theme-orange);
}
.tp-black-btn:hover.br-btn-bg-dark {
  background-color: var(--tp-common-black);
}
.tp-black-btn:hover span svg:first-child,
.tp-black-btn:hover span i:first-child {
  opacity: 1;
  transform: translateZ(0);
}
.tp-black-btn:hover span svg:last-child,
.tp-black-btn:hover span i:last-child {
  opacity: 0;
  transform: translateX(30px) translateY(0);
}
.tp-black-btn:hover span i {
  color: var(--tp-common-white);
}
.tp-black-btn i {
  margin-left: 15px;
}
.tp-black-btn span {
  margin-left: 5px;
  display: inline-block;
  position: relative;
}
.tp-black-btn span svg,
.tp-black-btn span i {
  transition: transform 0.6s cubic-bezier(0.77, 0, 0.175, 1),
    opacity 0.6s cubic-bezier(0.77, 0, 0.175, 1);
}
.tp-black-btn span svg:first-child,
.tp-black-btn span i:first-child {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transform: translateX(-20px) translateY(0);
}
.tp-black-btn span svg:last-child,
.tp-black-btn span i:last-child {
  opacity: 1;
  display: block;
  transform: translateY(0) translateY(0);
}
@media (max-width: 767px) {
  .tp-black-btn {
    font-size: 14px;
    padding: 15px 29px;
  }
}

.tp-black-btn-rgb {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 400;
  background-color: rgba(var(--tp-common-dark), 0.2);
  color: white;
  padding: 16px 29px;
  border-radius: 8px;
  display: inline-block;
  border: 1px solid rgba(255, 255, 255, 0.2);
  position: relative;
  z-index: 3;
}
.tp-black-btn-rgb::after {
  content: "";
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    100.16deg,
    #5a6ef8 9.94%,
    #a874c6 54.82%,
    #fe7a8f 95.56%
  );
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  border-radius: 6px;
  opacity: 0;
}
.tp-black-btn-rgb:hover {
  color: var(--tp-common-white);
  border-color: transparent;
}
.tp-black-btn-rgb:hover.br-btn-bg-dark {
  background-color: var(--tp-common-black);
}
.tp-black-btn-rgb:hover span svg:first-child,
.tp-black-btn-rgb:hover span i:first-child {
  opacity: 1;
  transform: translateZ(0);
}
.tp-black-btn-rgb:hover span svg:last-child,
.tp-black-btn-rgb:hover span i:last-child {
  opacity: 0;
  transform: translateX(30px) translateY(0);
}
.tp-black-btn-rgb:hover span i {
  color: var(--tp-common-white);
}
.tp-black-btn-rgb:hover::after {
  opacity: 1;
}
.tp-black-btn-rgb i {
  margin-left: 15px;
}
.tp-black-btn-rgb span {
  margin-left: 5px;
  display: inline-block;
  position: relative;
}
.tp-black-btn-rgb span svg,
.tp-black-btn-rgb span i {
  transition: transform 0.6s cubic-bezier(0.77, 0, 0.175, 1),
    opacity 0.6s cubic-bezier(0.77, 0, 0.175, 1);
}
.tp-black-btn-rgb span svg:first-child,
.tp-black-btn-rgb span i:first-child {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transform: translateX(-20px) translateY(0);
}
.tp-black-btn-rgb span svg:last-child,
.tp-black-btn-rgb span i:last-child {
  opacity: 1;
  display: block;
  transform: translateY(0) translateY(0);
}
@media (max-width: 767px) {
  .tp-black-btn-rgb {
    font-size: 14px;
    padding: 15px 29px;
  }
}

.tp-black-bg-btn {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 400;
  background-color: #000;
  color: white;
  padding: 17px 30px;
  border-radius: 8px;
  display: inline-block;
}
.tp-black-bg-btn:hover {
  color: var(--tp-common-white);
}
.tp-black-bg-btn:hover span svg:first-child,
.tp-black-bg-btn:hover span i:first-child {
  opacity: 1;
  transform: translateZ(0);
}
.tp-black-bg-btn:hover span svg:last-child,
.tp-black-bg-btn:hover span i:last-child {
  opacity: 0;
  transform: translateX(30px) translateY(0);
}
.tp-black-bg-btn:hover span i {
  color: var(--tp-common-white);
}
.tp-black-bg-btn span {
  margin-left: 10px;
  display: inline-block;
  position: relative;
}
.tp-black-bg-btn span svg,
.tp-black-bg-btn span i {
  transition: transform 0.6s cubic-bezier(0.77, 0, 0.175, 1),
    opacity 0.6s cubic-bezier(0.77, 0, 0.175, 1);
}
.tp-black-bg-btn span svg:first-child,
.tp-black-bg-btn span i:first-child {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transform: translateX(-20px) translateY(0);
}
.tp-black-bg-btn span svg:last-child,
.tp-black-bg-btn span i:last-child {
  opacity: 1;
  display: block;
  transform: translateY(0) translateY(0);
}
@media (max-width: 767px) {
  .tp-black-bg-btn {
    font-size: 14px;
    padding: 15px 29px;
  }
}

.hero-submit-btn {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 400;
  background-color: var(--tp-theme-cornblue);
  color: var(--tp-common-white);
  padding: 13px 24px;
  border-radius: 8px;
  display: inline-block;
}
.hero-submit-btn:hover {
  color: var(--tp-common-white);
  background-color: var(--tp-theme-redical);
}
.hero-submit-btn:hover span svg:first-child,
.hero-submit-btn:hover span i:first-child {
  opacity: 1;
  transform: translateZ(0);
}
.hero-submit-btn:hover span svg:last-child,
.hero-submit-btn:hover span i:last-child {
  opacity: 0;
  transform: translateX(30px) translateY(0);
}
.hero-submit-btn i {
  margin-left: 15px;
}
.hero-submit-btn span {
  margin-left: 5px;
  display: inline-block;
  position: relative;
}
.hero-submit-btn span svg,
.hero-submit-btn span i {
  transition: transform 0.6s cubic-bezier(0.77, 0, 0.175, 1),
    opacity 0.6s cubic-bezier(0.77, 0, 0.175, 1);
}
.hero-submit-btn span svg:first-child,
.hero-submit-btn span i:first-child {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transform: translateX(-20px) translateY(0);
}
.hero-submit-btn span svg:last-child,
.hero-submit-btn span i:last-child {
  opacity: 1;
  display: block;
  transform: translateY(0) translateY(0);
}
@media (max-width: 767px) {
  .hero-submit-btn {
    font-size: 14px;
    padding: 15px 29px;
  }
}

.hero-submit-btn.color-alt {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 400;
  background-color: var(--tp-theme-redical);
  color: var(--tp-common-white);
  padding: 14px 24px;
  border-radius: 8px;
  display: inline-block;
}
.hero-submit-btn.color-alt:hover {
  color: var(--tp-common-white);
  background-color: var(--tp-theme-cornblue);
}

.tp-grd-btn {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 400;
  color: var(--tp-common-white);
  background-size: 130% 100%;
  padding: 16px 34px;
  border-radius: 8px;
  display: inline-block;
  background-image: linear-gradient(
    100.16deg,
    #5a6ef8 9.94%,
    #a874c6 54.82%,
    #fe7a8f 95.56%
  );
}
.tp-grd-btn:hover {
  color: var(--tp-common-white);
  background-position: 100% 50%;
}
.tp-grd-btn:hover span svg:first-child,
.tp-grd-btn:hover span i:first-child {
  opacity: 1;
  transform: translateZ(0);
}
.tp-grd-btn:hover span svg:last-child,
.tp-grd-btn:hover span i:last-child {
  opacity: 0;
  transform: translateX(30px) translateY(0);
}
.tp-grd-btn span {
  display: inline-block;
  position: relative;
}
.tp-grd-btn span svg,
.tp-grd-btn span i {
  transition: transform 0.6s cubic-bezier(0.77, 0, 0.175, 1),
    opacity 0.6s cubic-bezier(0.77, 0, 0.175, 1);
}
.tp-grd-btn span svg:first-child,
.tp-grd-btn span i:first-child {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transform: translateX(-20px) translateY(0);
}
.tp-grd-btn span svg:last-child,
.tp-grd-btn span i:last-child {
  opacity: 1;
  display: block;
  transform: translateY(0) translateY(0);
}
@media (max-width: 767px) {
  .tp-grd-btn {
    font-size: 14px;
    padding: 15px 29px;
  }
}

.tp-grd-border-btn {
  position: relative;
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 400;
  color: var(--tp-common-black);
  background-size: 130% 100%;
  padding: 16px 30px;
  border-radius: 8px;
  display: block;
  background-image: linear-gradient(
    100.16deg,
    #5a6ef8 9.94%,
    #a874c6 54.82%,
    #fe7a8f 95.56%
  );
  z-index: 1;
  text-align: center;
}
.tp-grd-border-btn::after {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  background-color: var(--tp-common-white);
  display: inline-block;
  z-index: -1;
  right: 2px;
  bottom: 2px;
  border-radius: 6px;
}
.tp-grd-border-btn:hover {
  color: var(--tp-common-white);
  background-position: 100% 50%;
}
.tp-grd-border-btn:hover span svg:first-child,
.tp-grd-border-btn:hover span i:first-child {
  opacity: 1;
  transform: translateZ(0);
}
.tp-grd-border-btn:hover span svg:last-child,
.tp-grd-border-btn:hover span i:last-child {
  opacity: 0;
  transform: translateX(30px) translateY(0);
}
.tp-grd-border-btn:hover::after {
  opacity: 0;
}
.tp-grd-border-btn span {
  display: inline-block;
  position: relative;
}
.tp-grd-border-btn span svg,
.tp-grd-border-btn span i {
  transition: transform 0.6s cubic-bezier(0.77, 0, 0.175, 1),
    opacity 0.6s cubic-bezier(0.77, 0, 0.175, 1);
}
.tp-grd-border-btn span svg:first-child,
.tp-grd-border-btn span i:first-child {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transform: translateX(-20px) translateY(0);
}
.tp-grd-border-btn span svg:last-child,
.tp-grd-border-btn span i:last-child {
  opacity: 1;
  display: block;
  transform: translateY(0) translateY(0);
}
@media (max-width: 767px) {
  .tp-grd-border-btn {
    font-size: 14px;
    padding: 15px 29px;
  }
}

.tp-grey-btn {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 400;
  color: var(--tp-common-black);
  background-color: var(--tp-theme-bg-2);
  background-color: var();
  padding: 18px 44px;
  border-radius: 8px;
  display: inline-block;
}
.tp-grey-btn:hover {
  color: var(--tp-common-white);
  background-color: var(--tp-common-black);
}
.tp-grey-btn:hover span svg:first-child,
.tp-grey-btn:hover span i:first-child {
  opacity: 1;
  transform: translateZ(0);
}
.tp-grey-btn:hover span svg:last-child,
.tp-grey-btn:hover span i:last-child {
  opacity: 0;
  transform: translateX(30px) translateY(0);
}
.tp-grey-btn span {
  display: inline-block;
  position: relative;
}
.tp-grey-btn span svg,
.tp-grey-btn span i {
  transition: transform 0.6s cubic-bezier(0.77, 0, 0.175, 1),
    opacity 0.6s cubic-bezier(0.77, 0, 0.175, 1);
}
.tp-grey-btn span svg:first-child,
.tp-grey-btn span i:first-child {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transform: translateX(-20px) translateY(0);
}
.tp-grey-btn span svg:last-child,
.tp-grey-btn span i:last-child {
  opacity: 1;
  display: block;
  transform: translateY(0) translateY(0);
}
@media (max-width: 767px) {
  .tp-grey-btn {
    font-size: 14px;
    padding: 15px 29px;
  }
}

.ptg-slider-btn {
  font-size: 18px;
  color: white;
  text-transform: uppercase;
}
.ptg-slider-btn span {
  display: inline-block;
  position: relative;
}
.ptg-slider-btn span.circle {
  width: 60px;
  height: 60px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 50%;
}
.ptg-slider-btn span.circle i {
  line-height: 54px;
}
.ptg-slider-btn span svg,
.ptg-slider-btn span i {
  transition: transform 0.6s cubic-bezier(0.77, 0, 0.175, 1),
    opacity 0.6s cubic-bezier(0.77, 0, 0.175, 1);
}
.ptg-slider-btn span svg:first-child,
.ptg-slider-btn span i:first-child {
  position: absolute;
  top: 0;
  left: 20px;
  opacity: 0;
  transform: translateX(-20px) translateY(0);
}
.ptg-slider-btn span svg:last-child,
.ptg-slider-btn span i:last-child {
  opacity: 1;
  display: block;
  transform: translateY(0) translateY(0);
}
.ptg-slider-btn:hover {
  color: white;
}
.ptg-slider-btn:hover span svg:first-child,
.ptg-slider-btn:hover span i:first-child {
  opacity: 1;
  transform: translateZ(0);
}
.ptg-slider-btn:hover span svg:last-child,
.ptg-slider-btn:hover span i:last-child {
  opacity: 0;
  transform: translateX(30px) translateY(0);
}
@media (max-width: 767px) {
  .ptg-slider-btn {
    font-size: 14px;
    padding: 15px 29px;
  }
}

.tp-red-btn {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 400;
  background-color: var(--tp-theme-red);
  color: #fff;
  padding: 18px 29px;
  border-radius: 8px;
  display: inline-block;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06);
}
.tp-red-btn:hover {
  color: var(--tp-theme-red);
  background-color: #fff;
}
.tp-red-btn:hover span svg:first-child,
.tp-red-btn:hover span i:first-child {
  opacity: 1;
  transform: translateZ(0);
}
.tp-red-btn:hover span svg:last-child,
.tp-red-btn:hover span i:last-child {
  opacity: 0;
  transform: translateX(30px) translateY(0);
}
.tp-red-btn:hover span i {
  color: var(--tp-theme-red);
}
.tp-red-btn span {
  display: inline-block;
  position: relative;
}
.tp-red-btn span svg,
.tp-red-btn span i {
  transition: transform 0.6s cubic-bezier(0.77, 0, 0.175, 1),
    opacity 0.6s cubic-bezier(0.77, 0, 0.175, 1);
}
.tp-red-btn span svg:first-child,
.tp-red-btn span i:first-child {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transform: translateX(-20px) translateY(0);
}
.tp-red-btn span svg:last-child,
.tp-red-btn span i:last-child {
  opacity: 1;
  display: block;
  transform: translateY(0) translateY(0);
}
@media (max-width: 767px) {
  .tp-red-btn {
    font-size: 14px;
    padding: 15px 29px;
  }
}

.tp-red-btn-alt {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 400;
  background-color: #fff;
  color: var(--tp-theme-wine);
  padding: 18px 29px;
  border-radius: 8px;
  display: inline-block;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06);
}
.tp-red-btn-alt:hover {
  background-color: var(--tp-theme-red);
  color: #fff;
}
.tp-red-btn-alt:hover span svg:first-child,
.tp-red-btn-alt:hover span i:first-child {
  opacity: 1;
  transform: translateZ(0);
}
.tp-red-btn-alt:hover span svg:last-child,
.tp-red-btn-alt:hover span i:last-child {
  opacity: 0;
  transform: translateX(30px) translateY(0);
}
.tp-red-btn-alt:hover span i {
  color: #fff;
}
.tp-red-btn-alt span {
  display: inline-block;
  position: relative;
}
.tp-red-btn-alt span svg,
.tp-red-btn-alt span i {
  transition: transform 0.6s cubic-bezier(0.77, 0, 0.175, 1),
    opacity 0.6s cubic-bezier(0.77, 0, 0.175, 1);
}
.tp-red-btn-alt span svg:first-child,
.tp-red-btn-alt span i:first-child {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transform: translateX(-20px) translateY(0);
}
.tp-red-btn-alt span svg:last-child,
.tp-red-btn-alt span i:last-child {
  opacity: 1;
  display: block;
  transform: translateY(0) translateY(0);
}
@media (max-width: 767px) {
  .tp-red-btn-alt {
    font-size: 14px;
    padding: 15px 29px;
  }
}

.tp-solid-btn {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 400;
  background-color: var(--tp-theme-lochmara);
  color: #fff;
  padding: 18px 29px;
  border-radius: 30px;
  display: inline-block;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06);
}
.tp-solid-btn:hover {
  color: #fff;
  background-color: var(--tp-theme-atlantis);
}
.tp-solid-btn:hover span svg:first-child,
.tp-solid-btn:hover span i:first-child {
  opacity: 1;
  transform: translateZ(0);
}
.tp-solid-btn:hover span svg:last-child,
.tp-solid-btn:hover span i:last-child {
  opacity: 0;
  transform: translateX(30px) translateY(0);
}
.tp-solid-btn:hover span i {
  color: #fff;
}
.tp-solid-btn span {
  display: inline-block;
  position: relative;
}
.tp-solid-btn span svg,
.tp-solid-btn span i {
  transition: transform 0.6s cubic-bezier(0.77, 0, 0.175, 1),
    opacity 0.6s cubic-bezier(0.77, 0, 0.175, 1);
}
.tp-solid-btn span svg:first-child,
.tp-solid-btn span i:first-child {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transform: translateX(-20px) translateY(0);
}
.tp-solid-btn span svg:last-child,
.tp-solid-btn span i:last-child {
  opacity: 1;
  display: block;
  transform: translateY(0) translateY(0);
}
@media (max-width: 767px) {
  .tp-solid-btn {
    font-size: 14px;
    padding: 15px 29px;
  }
}

.tp-seo-btn {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 400;
  background-color: var(--tp-theme-atlantis);
  color: #fff;
  padding: 18px 29px;
  border-radius: 30px;
  display: inline-block;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06);
}
.tp-seo-btn:hover {
  color: #fff;
  background-color: var(--tp-theme-lochmara);
}
.tp-seo-btn:hover span svg:first-child,
.tp-seo-btn:hover span i:first-child {
  opacity: 1;
  transform: translateZ(0);
}
.tp-seo-btn:hover span svg:last-child,
.tp-seo-btn:hover span i:last-child {
  opacity: 0;
  transform: translateX(30px) translateY(0);
}
.tp-seo-btn:hover span i {
  color: #fff;
}
.tp-seo-btn span {
  display: inline-block;
  position: relative;
}
.tp-seo-btn span svg,
.tp-seo-btn span i {
  transition: transform 0.6s cubic-bezier(0.77, 0, 0.175, 1),
    opacity 0.6s cubic-bezier(0.77, 0, 0.175, 1);
}
.tp-seo-btn span svg:first-child,
.tp-seo-btn span i:first-child {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transform: translateX(-20px) translateY(0);
}
.tp-seo-btn span svg:last-child,
.tp-seo-btn span i:last-child {
  opacity: 1;
  display: block;
  transform: translateY(0) translateY(0);
}
@media (max-width: 767px) {
  .tp-seo-btn {
    font-size: 14px;
    padding: 15px 29px;
  }
}

.tp-white-rounder-btn {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 400;
  background-color: var(--tp-common-white);
  color: var(--tp-theme-seagreen);
  padding: 18px 35px;
  border-radius: 30px;
  display: inline-block;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06);
}
.tp-white-rounder-btn:hover {
  color: var(--tp-common-white);
  background-color: var(--tp-theme-lochmara);
}
.tp-white-rounder-btn:hover span svg:first-child,
.tp-white-rounder-btn:hover span i:first-child {
  opacity: 1;
  transform: translateZ(0);
}
.tp-white-rounder-btn:hover span svg:last-child,
.tp-white-rounder-btn:hover span i:last-child {
  opacity: 0;
  transform: translateX(30px) translateY(0);
}
.tp-white-rounder-btn:hover span i {
  color: var(--tp-common-white);
}
.tp-white-rounder-btn span {
  display: inline-block;
  position: relative;
}
.tp-white-rounder-btn span svg,
.tp-white-rounder-btn span i {
  transition: transform 0.6s cubic-bezier(0.77, 0, 0.175, 1),
    opacity 0.6s cubic-bezier(0.77, 0, 0.175, 1);
}
.tp-white-rounder-btn span svg:first-child,
.tp-white-rounder-btn span i:first-child {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transform: translateX(-20px) translateY(0);
}
.tp-white-rounder-btn span svg:last-child,
.tp-white-rounder-btn span i:last-child {
  opacity: 1;
  display: block;
  transform: translateY(0) translateY(0);
}
@media (max-width: 767px) {
  .tp-white-rounder-btn {
    font-size: 14px;
    padding: 15px 29px;
  }
}

.playstole-btn {
  position: relative;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 400;
  background-color: var(--tp-theme-redical);
  color: var(--tp-common-white);
  padding: 18px 28px;
  border-radius: 8px;
  padding-right: 30px;
  display: inline-block;
}
.playstole-btn b {
  font-size: 16px;
  font-weight: 400;
  display: block;
  margin-left: 45px;
}
.playstole-btn:hover {
  color: var(--tp-common-white);
  background-color: var(--tp-theme-cornblue);
}
.playstole-btn:hover span svg:first-child,
.playstole-btn:hover span i:first-child {
  opacity: 1;
  transform: translateZ(0);
}
.playstole-btn:hover span svg:last-child,
.playstole-btn:hover span i:last-child {
  opacity: 0;
  transform: translateX(30px) translateY(0);
}
.playstole-btn i {
  margin-left: 15px;
}
.playstole-btn span {
  display: inline-block;
  position: absolute;
  left: 20px;
  font-size: 24px;
  top: 50%;
  transform: translateY(-50%);
}
.playstole-btn span svg,
.playstole-btn span i {
  transition: transform 0.6s cubic-bezier(0.77, 0, 0.175, 1),
    opacity 0.6s cubic-bezier(0.77, 0, 0.175, 1);
}
.playstole-btn span svg:first-child,
.playstole-btn span i:first-child {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transform: translateX(-20px) translateY(0);
}
.playstole-btn span svg:last-child,
.playstole-btn span i:last-child {
  opacity: 1;
  display: block;
  transform: translateY(0) translateY(0);
}
@media (max-width: 767px) {
  .playstole-btn {
    font-size: 14px;
    padding: 15px 29px;
  }
}

.applestole-btn {
  position: relative;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 400;
  background-color: var(--tp-common-white-1);
  color: var(--tp-common-black);
  padding: 18px 28px;
  border-radius: 8px;
  padding-left: 70px;
  padding-right: 40px;
  display: inline-block;
  border-style: solid;
  border-width: 2px;
  border-color: rgb(243, 243, 243);
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.08);
}
.applestole-btn b {
  font-size: 16px;
  font-weight: 400;
  display: block;
}
.applestole-btn:hover {
  color: var(--tp-common-white);
  background-color: var(--tp-theme-cornblue);
}
.applestole-btn:hover span svg:first-child,
.applestole-btn:hover span i:first-child {
  opacity: 1;
  transform: translateZ(0);
}
.applestole-btn:hover span svg:last-child,
.applestole-btn:hover span i:last-child {
  opacity: 0;
  transform: translateX(30px) translateY(0);
}
.applestole-btn i {
  margin-left: 15px;
}
.applestole-btn span {
  display: inline-block;
  position: absolute;
  left: 20px;
  font-size: 24px;
  top: 50%;
  transform: translateY(-50%);
}
.applestole-btn span svg,
.applestole-btn span i {
  transition: transform 0.6s cubic-bezier(0.77, 0, 0.175, 1),
    opacity 0.6s cubic-bezier(0.77, 0, 0.175, 1);
}
.applestole-btn span svg:first-child,
.applestole-btn span i:first-child {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transform: translateX(-20px) translateY(0);
}
.applestole-btn span svg:last-child,
.applestole-btn span i:last-child {
  opacity: 1;
  display: block;
  transform: translateY(0) translateY(0);
}
@media (max-width: 767px) {
  .applestole-btn {
    font-size: 14px;
    padding: 15px 60px;
  }
}

.down-arrow-btn {
  color: #fff;
  font-size: 18px;
  margin: 0 auto;
  display: block;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  border-radius: 50%;
  background-image: -moz-linear-gradient(
    0deg,
    rgb(90, 110, 248) 0%,
    rgb(254, 122, 143) 100%
  );
  background-image: -webkit-linear-gradient(
    0deg,
    rgb(90, 110, 248) 0%,
    rgb(254, 122, 143) 100%
  );
  background-image: -ms-linear-gradient(
    0deg,
    rgb(90, 110, 248) 0%,
    rgb(254, 122, 143) 100%
  );
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.down-arrow-btn i {
  animation: scroll 1.5s infinite ease-in-out;
}
.down-arrow-btn:hover {
  color: white;
  opacity: 0.9;
}

.border-radious-none {
  border-radius: 0;
}

/* pulse effect animation */
@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 45px rgba(255, 255, 255, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }
  70% {
    box-shadow: 0 0 0 45px rgba(255, 255, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
@-webkit-keyframes scroll {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-10px);
  }
  100% {
    transform: translatey(0px);
  }
}
@keyframes scroll {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-10px);
  }
  100% {
    transform: translatey(0px);
  }
}
@-webkit-keyframes marquee {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}
@keyframes marquee {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}
@keyframes wobble-vertical {
  16.65% {
    transform: translateY(8px);
  }
  33.3% {
    transform: translateY(-6px);
  }
  49.95% {
    transform: translateY(4px);
  }
  66.6% {
    transform: translateY(-2px);
  }
  83.25% {
    transform: translateY(1px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes zoom {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes scroll1 {
  0% {
    top: 0px;
    opacity: 1;
  }
  50% {
    top: 50%;
  }
  100% {
    top: 100%;
    opacity: 0.5;
  }
}
@keyframes scroll2 {
  0% {
    opacity: 1;
    bottom: 0px;
  }
  50% {
    bottom: 50%;
  }
  100% {
    bottom: 100%;
    opacity: 0.5;
  }
}
@keyframes preloader {
  0% {
    transform: scale(0.7, 0.7);
  }
  50% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(0.7, 0.7);
  }
}
@-webkit-keyframes tpfadeUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes tpfadeUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}
.tpfadeUp {
  -webkit-animation-name: tpfadeUp;
  animation-name: tpfadeUp;
}

@-webkit-keyframes tpfadeLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes tpfadeLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}
.tpfadeLeft {
  -webkit-animation-name: tpfadeLeft;
  animation-name: tpfadeLeft;
}

@-webkit-keyframes tpfadeRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes tpfadeRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}
.tpfadeRight {
  -webkit-animation-name: tpfadeRight;
  animation-name: tpfadeRight;
}

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}
.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

@-webkit-keyframes tpfadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes tpfadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.tpfadeIn {
  -webkit-animation-name: tpfadeIn;
  animation-name: tpfadeIn;
}

/*--
    - Background color
-----------------------------------------*/
.white-bg {
  background-color: var(--tp-common-white);
}

.black-bg {
  background-color: var(--tp-common-black);
}

.red-bg {
  background-color: var(--tp-theme-red);
}

.dark-bg {
  background-color: var(--tp-common-dark);
}

.dark-bg-2 {
  background-color: var(--tp-common-dark-2);
}

.cornblue-bg {
  background-color: var(--tp-theme-cornblue);
}

.vogue-bg {
  background-color: var(--tp-theme-vogue);
}

.wine-bg {
  background-color: var(--tp-theme-wine);
}

.seagreen-bg {
  background-color: var(--tp-theme-seagreen);
}

.theme-bg {
  background-color: var(--tp-theme-bg);
}

.theme-bg-2 {
  background-color: var(--tp-theme-bg-2);
}

.theme-bg-3 {
  background-color: var(--tp-theme-bg-3);
}

.theme-bg-4 {
  background-color: var(--tp-theme-bg-4);
}

.theme-bg-5 {
  background-color: var(--tp-theme-bg-5);
}

.theme-bg-6 {
  background-color: var(--tp-theme-bg-6);
}

.theme-bg-7 {
  background-color: var(--tp-theme-bg-7);
}

.theme-bg-8 {
  background-color: var(--tp-theme-bg-8);
}

.theme-bg-9 {
  background-color: var(--tp-theme-bg-9);
}

.tp-offcanvas {
  position: fixed;
  top: 0;
  right: -390px;
  width: 390px;
  height: 100%;
  padding: 60px;
  overflow-y: scroll;
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.tp-offcanvas:-webkit-scrollbar {
  display: none;
}
.tp-offcanvas-close {
  position: absolute;
  top: 10px;
  right: 30px;
  font-size: 16px;
  width: 50px;
  height: 50px;
  background-color: white;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}
.tp-offcanvas-close i {
  line-height: 50px;
}
.tp-offcanvas-close:hover {
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
}
.tp-offcanvas__social a {
  font-size: 16px;
  display: inline-block;
  width: 50px;
  height: 50px;
  background-color: var(--tp-common-white);
  border: 2px solid var(--tp-border-6);
  text-align: center;
  line-height: 49px;
  margin-right: 5px;
  border-radius: 8px;
}
.tp-offcanvas__social a:hover {
  background-color: var(--tp-theme-redical);
  color: var(--tp-common-white);
}
.tp-offcanvas.opened {
  right: 0;
  opacity: 1;
  visibility: visible;
}
@media (max-width: 576px) {
  .tp-offcanvas {
    width: 300px;
    padding: 50px 20px;
  }
}
@media (max-width: 300px) {
  .tp-offcanvas {
    padding: 50px 20px 50px 30px;
  }
}

.offc-top-pattern {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}

.offc-bottom-pattern {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
}

/*----------------------------------------*/
/*  24. BREADCRUMB CSS START
/*----------------------------------------*/
.bg {
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06);
  position: absolute;
  left: 315px;
  top: 233px;
  width: 188px;
  height: 36px;
  z-index: 165;
}

.breadcrumb__area {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}
.breadcrumb__title {
  font-size: 50px;
  color: var(--tp-common-black);
}
.breadcrumb__title-2 {
  font-size: 50px;
  line-height: 1.1;
  margin-top: 12px;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .breadcrumb__title-2 {
    font-size: 40px;
  }
}
@media (max-width: 767px) {
  .breadcrumb__title-2 {
    font-size: 26px;
  }
}
.breadcrumb__title-pre {
  display: inline-block;
  height: 24px;
  line-height: 26px;
  font-size: 14px;
  color: #ffffff;
  font-weight: 400;
  background: var(--tp-theme-2);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  padding: 0 7px;
  margin-bottom: 12px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb__title {
    font-size: 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb__title {
    font-size: 40px;
  }
}
@media (max-width: 767px) {
  .breadcrumb__title {
    font-size: 26px;
  }
}
.breadcrumb__list {
  background-color: var(--tp-common-white);
  display: inline-block;
  padding: 7px 20px;
  border-radius: 18px;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06);
}
.breadcrumb__list span {
  font-size: 14px;
  color: var(--tp-grey-1);
  padding-right: 3px;
  margin-right: 3px;
  text-transform: capitalize;
}
.breadcrumb__list span a:hover {
  color: var(--tp-theme-1);
}
.breadcrumb__list span.tp-bc-acive-menu {
  color: var(--tp-common-black);
  font-weight: 400;
}
.breadcrumb__list span.dvdr-line {
  width: 24px;
  height: 2px;
  background-color: var(--tp-theme-redical);
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 4px;
}
.breadcrumb__list-2 span {
  font-size: 14px;
  color: var(--tp-text-11);
  font-weight: 400;
  padding-right: 3px;
  margin-right: 3px;
  text-transform: capitalize;
}
.breadcrumb__list-2 span a:hover {
  color: var(--tp-theme-1);
}
.breadcrumb__overlay {
  position: relative;
}
.breadcrumb__overlay::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 3, 32, 0.6);
}

.tp-accordion .accordion-header {
  margin-bottom: 20px;
}
.tp-accordion .accordion-body {
  font-size: 16px;
  padding: 20px 10px 20px 30px;
  padding-top: 0;
  line-height: 1.7;
}
@media (max-width: 576px) {
  .tp-accordion .accordion-body {
    padding: 10px 10px;
  }
}
.tp-accordion .accordion-item {
  border: 0;
}
.tp-accordion .accordion-item:first-of-type .accordion-button,
.tp-accordion .accordion-item:last-of-type .accordion-button {
  border-radius: 30px;
}
.tp-accordion .accordion-button {
  font-size: 18px;
  color: var(--tp-common-black);
  font-weight: 400;
  padding: 20px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
  border: 1px solid transparent;
  border-radius: 30px;
}
@media (max-width: 576px) {
  .tp-accordion .accordion-button {
    font-size: 14px;
    padding-left: 15px;
    padding-right: 10px;
  }
}
.tp-accordion .accordion-button:not(.collapsed) {
  background: none;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
  border: 1px solid #eaeaea;
}
.tp-accordion .accordion-button:not(.collapsed)::after {
  color: white;
  width: auto;
  height: auto;
  content: "\f068";
  transform: rotate(0deg);
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  background-color: var(--tp-theme-redical);
}
@media (max-width: 576px) {
  .tp-accordion .accordion-button:not(.collapsed)::after {
    width: 20px;
    height: 20px;
    line-height: 20px;
  }
}
.tp-accordion .accordion-button:not(.collapsed):focus {
  border: 1px solid #eaeaea;
}
.tp-accordion .accordion-button:focus {
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
  border: 1px solid #eaeaea;
}
.tp-accordion .accordion-button::after {
  position: absolute;
  right: 10px;
  content: "\f067";
  font-weight: 300;
  font-family: var(--tp-ff-fontawesome);
  background-image: none;
  font-size: 18px;
  color: var(--tp-theme-redical);
  background-color: rgb(255, 241, 243);
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
}
@media (max-width: 576px) {
  .tp-accordion .accordion-button::after {
    width: 20px;
    height: 20px;
    line-height: 20px;
  }
}

.test .accordion-body {
  padding: 25px 0;
  padding-top: 0;
}
.test .accordion-item {
  border: none;
  margin-bottom: 25px;
}
.test .accordion-button {
  padding: 15px 0;
  font-size: 24px;
}
.test .accordion-button:focus {
  border-color: transparent;
  box-shadow: none;
}
.test .accordion-button.collapsed {
  border-bottom: 1px solid red;
  margin-bottom: 35px;
}
.test .accordion-button::after {
  background-image: none;
  content: "\f067";
  font-family: var(--tp-ff-fontawesome);
  text-decoration: none;
}
.test .accordion-button:not(.collapsed) {
  background-color: var(--tp-common-white);
  color: var(--tp-common-black);
  box-shadow: none;
  text-decoration: underline;
  border-bottom: 1px solid red;
  margin-bottom: 35px;
}
.test .accordion-button:not(.collapsed)::after {
  background-image: none;
  content: "\f068";
  font-family: var(--tp-ff-fontawesome);
  text-decoration: none;
}

.tp-section {
  position: relative;
  z-index: 1;
}
.tp-section.text-center .tp-section__bigtitle {
  left: 0;
  right: 0;
}
.tp-section__bigtitle {
  color: transparent;
  font-size: 160px;
  position: absolute;
  top: -60px;
  line-height: 0.438;
  font-weight: 400;
  background-image: linear-gradient(
    0deg,
    rgb(255, 255, 255) 0%,
    rgb(242, 245, 247) 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
  line-height: 1;
  z-index: -1;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-section__bigtitle {
    font-size: 120px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-section__bigtitle {
    font-size: 100px;
  }
}
@media (max-width: 767px) {
  .tp-section__bigtitle {
    font-size: 80px;
  }
}
@media (max-width: 576px) {
  .tp-section__bigtitle {
    font-size: 60px;
  }
}
.tp-section__subtitle {
  font-size: 16px;
  font-weight: 400;
  padding: 6px 18px;
  border-radius: 18px;
  text-transform: capitalize;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06);
  display: inline-block;
}
.tp-section__subtitle.text-rgb {
  position: relative;
  background-image: linear-gradient(90deg, #5a6ef8, #fe7a8f);
  color: var(--tp-grey-1);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.tp-section__subtitle.change-icon-size {
  font-weight: 400;
}
.tp-section__subtitle.change-icon-size i {
  margin-right: 5px;
  font-size: 9px;
  line-height: 17px;
  transform: translateY(-3px);
}
.tp-section__subtitle.change-icon-size i:nth-child(2) {
  margin-right: 0;
  margin-left: 5px;
}
.tp-section__title {
  font-size: 60px;
  letter-spacing: -2px;
  line-height: 1.125;
  font-weight: 400;
}
.tp-section__title span {
  position: relative;
  background-image: linear-gradient(90deg, #5a6ef8, #fe7a8f);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.tp-section__title span::before {
  content: "";
  background-color: #e2e4ff;
  position: absolute;
  bottom: 7px;
  right: 0;
  width: 100%;
  height: 10px;
  z-index: -1;
}
@media (max-width: 767px) {
  .tp-section__title span::before {
    height: 3px;
  }
}
.tp-section__title.black-line span::before {
  background-color: rgb(26, 26, 26);
}
@media (max-width: 767px),
  only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-section__title br {
    display: none;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-section__title {
    font-size: 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-section__title {
    font-size: 40px;
    letter-spacing: 0;
  }
}
@media (max-width: 767px) {
  .tp-section__title {
    font-size: 35px;
    letter-spacing: 0;
  }
}
@media (max-width: 576px) {
  .tp-section__title {
    font-size: 27px;
    letter-spacing: 0;
  }
}
.tp-section__title.tp-rgb-border span {
  position: relative;
  background-image: linear-gradient(90deg, #5a6ef8, #fe7a8f);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: white;
}
.tp-section__title.tp-rgb-border span::before {
  content: "";
  background-image: -moz-linear-gradient(
    0deg,
    rgb(90, 110, 248) 0%,
    rgb(254, 122, 143) 100%
  );
  background-image: -webkit-linear-gradient(
    0deg,
    rgb(90, 110, 248) 0%,
    rgb(254, 122, 143) 100%
  );
  background-image: -ms-linear-gradient(
    0deg,
    rgb(90, 110, 248) 0%,
    rgb(254, 122, 143) 100%
  );
  position: absolute;
  bottom: 7px;
  right: 0;
  width: 100%;
  height: 10px;
  z-index: -1;
}
@media (max-width: 767px) {
  .tp-section__title.tp-rgb-border span::before {
    height: 3px;
  }
}
.tp-section p {
  /* font-size: 18px; */
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: var(--tp-grey-3);
  margin-bottom: 45px;
}
.tp-section p.text-grey {
  color: var(--tp-grey-1);
}
.tp-section p.text-fog {
  color: #dedbff;
}
.tp-section p.text-lily {
  color: #c9b2bf;
}
@media (max-width: 767px),
  only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-section p br {
    display: none;
  }
}

.text-grey {
  color: var(--tp-grey-1);
  font-weight: 400;
}

.text-mirage {
  color: var(--tp-theme-mirage);
}

.text-cornblue {
  color: var(--tp-theme-cornblue);
}

.vogue-text-color {
  color: var(--tp-theme-vogue);
}

.wine-text-color {
  color: var(--tp-theme-wine);
}

.seagreen-text-color {
  color: var(--tp-theme-seagreen);
}

.lochmara-text-color {
  color: var(--tp-theme-lochmara);
}

.text-fog {
  color: #dedbff;
}

.text-redical {
  color: var(--tp-theme-redical);
}

.catskill-white-bg {
  background-color: #f6f9fb;
}

.before-border {
  transform: translateY(-5px);
  height: 2px;
  width: 24px;
  margin-right: 8px;
  background-color: var(--tp-theme-redical);
  display: inline-block;
}

.after-border {
  transform: translateY(-5px);
  height: 2px;
  width: 24px;
  margin-left: 8px;
  background-color: var(--tp-theme-redical);
  display: inline-block;
}

.tp-inner-pt-section__title {
  font-size: 26px;
}

.tp-inner-pt-section p {
  color: var(--tp-grey-1);
  font-size: 18px;
}

/*----------------------------------------*/
/*   02. HEADER CSS
/*----------------------------------------*/
.header__top-toast span {
  padding: 10px 0;
  font-size: 16px;
  color: var(--tp-common-white);
}

.tp-header__1-right .tp-btn {
  padding: 13px 29px;
}
@media (max-width: 576px) {
  .tp-header__1-right .tp-btn {
    display: none;
  }
}
@media only screen and (min-width: 1500px) and (max-width: 1700px) {
  .tp-header__1-main {
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1499px) {
  .tp-header__1-main {
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-header__1-main {
    padding-right: 50px;
    padding-left: 50px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-header__1-main {
    padding-right: 40px;
    padding-left: 40px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-header__1-main {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .tp-header__1-main {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .tp-header__1-main {
    padding-left: 0;
    padding-right: 0;
  }
}
.tp-header-search-nav {
  display: flex;
}
.tp-header-search {
  margin-right: 15px;
}
.tp-header-search input {
  padding: 20px;
  height: 40px;
  width: 0px;
  border: none;
  background-color: #fff;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  opacity: 0;
  transition: 0.8s;
  color: var(--tp-grey-8);
}
.tp-header-search input::-webkit-input-placeholder {
  color: var(--tp-grey-8);
}
.tp-header-search input:-moz-placeholder {
  color: var(--tp-grey-8);
}
.tp-header-search input::-moz-placeholder {
  color: var(--tp-grey-8);
}
.tp-header-search input:-ms-input-placeholder {
  color: var(--tp-grey-8);
}
.tp-header-search button {
  font-size: 16px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: white;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.1);
}
.tp-header-search:hover input {
  opacity: 1;
  width: 210px;
}
.tp-header-search:hover button {
  box-shadow: none;
}
@media (max-width: 576px) {
  .tp-header-search {
    display: none;
  }
}
.tp-header-nav {
  border-radius: 50%;
  background-color: var(--tp-theme-cornblue);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
  width: 40px;
  height: 40px;
  cursor: pointer;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding-left: 12px;
}
.tp-header-nav-black {
  background-color: var(--tp-common-black);
}
.tp-header-nav span:first-child {
  width: 10px;
  height: 2px;
  background-color: #fff;
  margin-bottom: 4px;
}
.tp-header-nav span:last-child {
  width: 15px;
  height: 2px;
  background-color: #fff;
}
.tp-header-nav:hover span:first-child {
  width: 15px;
}
.tp-header-nav:hover span:last-child {
  width: 10px;
}
.tp-header-sing-icon {
  margin-right: 15px;
}
.tp-header-sing-icon span {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  line-height: 40px;
}
.tp-header-sing-icon i {
  color: var(--tp-theme-redical);
  margin-right: 5px;
}

.tp-header-search-2 input {
  font-size: 16px;
  background-color: transparent;
  border: transparent;
  color: #525252;
  padding-left: 25px;
}
.tp-header-search-2 input::-webkit-input-placeholder {
  color: #525252;
}
.tp-header-search-2 input:-moz-placeholder {
  color: #525252;
}
.tp-header-search-2 input::-moz-placeholder {
  color: #525252;
}
.tp-header-search-2 input:-ms-input-placeholder {
  color: #525252;
}
.tp-header-search-2 label {
  font-size: 16px;
  color: white;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.tp-header-nav-2 {
  width: 110px;
  height: 110px;
  font-size: 38px;
  line-height: 110px;
  color: white;
  display: block;
  border-radius: 0;
  text-align: center;
  flex: 0 0 auto;
  background-image: linear-gradient(
    100.16deg,
    #5a6ef8 9.94%,
    #a874c6 54.82%,
    #fe7a8f 95.56%
  );
  padding-left: 0;
  margin-left: 30px;
}
@media (max-width: 767px) {
  .tp-header-nav-2 {
    width: 60px;
    height: 60px;
    line-height: 60px;
    font-size: 22px;
    margin: 15px;
  }
}

.transparent-header {
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 999;
}

.header-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  background-color: #1e2222;
  animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06);
}
.header-sticky.dark-header {
  border-bottom: 1px solid #2b2a2a;
}

.tp-it-header__main.header-sticky {
  margin-right: 0;
  margin-left: 0;
  border-radius: 0;
}

.header-sticky .da-header-cta-btn {
  margin-top: 0;
}
.header-sticky .da-header-cta-btn p {
  display: none;
}
.header-sticky.tp-ptg-header {
  background-color: var(--tp-common-black);
  border-color: #525252;
}
.header-sticky.tp-law-header__main {
  background-color: var(--tp-theme-wine);
}
.header-sticky.tp-ca-header__main {
  background-color: #b6bbbe;
}
.header-sticky.tp-ha-header__main {
  background-color: var(--tp-common-black);
  padding-top: 0;
  border-bottom: 1px solid #2b2a2a;
}
@media (max-width: 767px) {
  .header-sticky.tp-ha-header__main {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
.header-sticky.tp-seo-header__main {
  background-color: var(--tp-theme-bg-9);
}

#loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--tp-common-white);
  z-index: 999999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}
#loading img {
  width: 70px;
  animation: preloader 3s linear infinite;
}

.tp-mobile-menu.mean-container {
  overflow: hidden;
  clear: both;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu {
    margin-left: 80px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .main-menu ul {
    padding-left: 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu ul {
    float: right;
  }
}

.main-menu ul {
  display: flex;
  height: 110px;
  align-items: center;
  gap: 10px;
}

.main-menu ul li a {
  max-width: 125px;
  display: block;
  /* height: 110px; */
  /* align-items: center; */
  /* display: inline-block; */
  font-size: 16px;
  font-weight: 400;
  color: #fff !important;
  /* padding: 43px 0; */
  text-transform: uppercase;
}

.main-menu ul li {
  position: relative;
  list-style: none;
  display: inline-block;
  margin-right: 48px;
}
@media only screen and (min-width: 1500px) and (max-width: 1700px) {
  .main-menu ul li {
    margin-right: 40px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1499px) {
  .main-menu ul li {
    margin-right: 30px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu ul li {
    margin-right: 25px;
  }
}
.main-menu ul li a {
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  color: var(--tp-common-black);
  padding: 43px 0;
  text-transform: uppercase;
}
.main-menu ul li a:hover {
  color: var(--tp-theme-red) !important;
  font-weight: 700;
}
.main-menu ul li.has-dropdown > a {
  position: relative;
}
.main-menu ul li.has-dropdown > a::after {
  content: "\f067";
  -webkit-transform: translateY(1px);
  -moz-transform: translateY(1px);
  -ms-transform: translateY(1px);
  -o-transform: translateY(1px);
  transform: translateY(1px);
  font-size: 16px;
  color: var(--tp-theme-redical);
  font-family: var(--tp-ff-fontawesome);
  font-weight: 400;
  margin-left: 5px;
  display: inline-block;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.main-menu ul li.has-megamenu {
  position: static;
}
.main-menu ul li .submenu {
  padding: 25px 10px;
  position: absolute;
  top: 120%;
  left: 0;
  min-width: 200px;
  background-color: var(--tp-common-black);
  z-index: 99;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  visibility: hidden;
  opacity: 0;
  -webkit-box-shadow: 0px 20px 30px rgba(3, 6, 31, 0.2);
  -moz-box-shadow: 0px 20px 30px rgba(3, 6, 31, 0.2);
  -ms-box-shadow: 0px 20px 30px rgba(3, 6, 31, 0.2);
  -o-box-shadow: 0px 20px 30px rgba(3, 6, 31, 0.2);
  box-shadow: 0px 20px 30px rgba(3, 6, 31, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.08);
  transform-origin: top;
  -webkit-transform: perspective(300px) rotateX(-18deg);
  -moz-transform: perspective(300px) rotateX(-18deg);
  -ms-transform: perspective(300px) rotateX(-18deg);
  -o-transform: perspective(300px) rotateX(-18deg);
  transform: perspective(300px) rotateX(-18deg);
  border-radius: 0 0 10px 10px;
}
.main-menu ul li .submenu li {
  display: block;
  width: 100%;
  margin: 0;
  padding: 0 0;
}
.main-menu ul li .submenu li.has-dropdown > a::after {
  position: absolute;
  top: 50%;
  right: 30px;
  -webkit-transform: translateY(-50%) rotate(-90deg);
  -moz-transform: translateY(-50%) rotate(-90deg);
  -ms-transform: translateY(-50%) rotate(-90deg);
  -o-transform: translateY(-50%) rotate(-90deg);
  transform: translateY(-50%) rotate(-90deg);
}
.main-menu ul li .submenu li a {
  position: relative;
  z-index: 1;
  width: 100%;
  display: block;
  font-size: 14px;
  color: var(--tp-common-white);
  padding: 8px 20px;
  font-weight: 400;
  text-transform: capitalize;
  letter-spacing: 0;
  text-align: left;
}
.main-menu ul li .submenu li a::before {
  position: absolute;
  top: 50%;
  left: 30px;
  content: "";
  width: 14px;
  height: 1px;
  display: inline-block;
  background-color: var(--tp-theme-redical);
  transform: translateY(-50%);
  opacity: 0;
}
.main-menu ul li .submenu li .submenu {
  left: 120%;
  top: 0;
  visibility: hidden;
  opacity: 0;
}
.main-menu ul li .submenu li:hover > a {
  color: var(--tp-theme-redical);
  background-color: rgba(150, 144, 162, 0.06);
  padding-left: 40px;
}
.main-menu ul li .submenu li:hover > a::after {
  color: var(--tp-theme-redical);
  -webkit-transform: translateY(-50%) rotate(45deg);
  -moz-transform: translateY(-50%) rotate(45deg);
  -ms-transform: translateY(-50%) rotate(45deg);
  -o-transform: translateY(-50%) rotate(45deg);
  transform: translateY(-50%) rotate(45deg);
}
.main-menu ul li .submenu li:hover > a::before {
  opacity: 1;
  left: 20px;
}
.main-menu ul li .submenu li:hover > .submenu {
  left: 100%;
  visibility: visible;
  opacity: 1;
}
.main-menu ul li .mega-menu {
  padding: 35px 50px;
  position: absolute;
  top: 120%;
  width: 1200px;
  background-color: var(--tp-common-black);
  left: 0;
  right: 0;
  z-index: 99;
  margin-left: -50px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  visibility: hidden;
  opacity: 0;
  -webkit-box-shadow: 0px 20px 30px rgba(3, 6, 31, 0.2);
  -moz-box-shadow: 0px 20px 30px rgba(3, 6, 31, 0.2);
  -ms-box-shadow: 0px 20px 30px rgba(3, 6, 31, 0.2);
  -o-box-shadow: 0px 20px 30px rgba(3, 6, 31, 0.2);
  box-shadow: 0px 20px 30px rgba(3, 6, 31, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.08);
  transform-origin: top;
  -webkit-transform: perspective(300px) rotateX(-18deg);
  -moz-transform: perspective(300px) rotateX(-18deg);
  -ms-transform: perspective(300px) rotateX(-18deg);
  -o-transform: perspective(300px) rotateX(-18deg);
  transform: perspective(300px) rotateX(-18deg);
  border-radius: 0 0 10px 10px;
  display: flex;
  justify-content: space-between;
  flex: 0 0 auto;
  margin: 0 auto;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .main-menu ul li .mega-menu {
    width: 1100px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .main-menu ul li .mega-menu {
    width: 1000px;
    margin-right: -100px;
    margin-left: -100px;
  }
}
.main-menu ul li .mega-menu li {
  width: 150px;
  text-align: left;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .main-menu ul li .mega-menu li {
    width: 120px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .main-menu ul li .mega-menu li {
    width: 120px;
  }
}
.main-menu ul li .mega-menu li a {
  color: var(--tp-grey-1);
  padding: 0;
  padding: 10px;
}
.main-menu ul li .mega-menu li ul {
  padding-left: 0;
  text-align: left;
}
.main-menu ul li .mega-menu li ul li a {
  position: relative;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  color: white;
  text-transform: capitalize;
  text-align: start;
}
.main-menu ul li .mega-menu li ul li a::before {
  position: absolute;
  top: 50%;
  left: 30px;
  content: "";
  width: 14px;
  height: 1px;
  display: inline-block;
  background-color: var(--tp-theme-redical);
  transform: translateY(-50%);
  opacity: 0;
}
.main-menu ul li .mega-menu li ul li:hover > a {
  color: var(--tp-theme-redical);
  transform: translateX(25px);
}
.main-menu ul li .mega-menu li ul li:hover > a::after {
  color: var(--tp-theme-redical);
  -webkit-transform: translateY(-50%) rotate(45deg);
  -moz-transform: translateY(-50%) rotate(45deg);
  -ms-transform: translateY(-50%) rotate(45deg);
  -o-transform: translateY(-50%) rotate(45deg);
  transform: translateY(-50%) rotate(45deg);
}
.main-menu ul li .mega-menu li ul li:hover > a::before {
  opacity: 1;
  transform: translateX(-40px);
}
.main-menu ul li .mega-menu li ul li:hover > .submenu {
  left: 100%;
  visibility: visible;
  opacity: 1;
}
.main-menu ul li:hover > a {
  color: #3756f7;
}
.main-menu ul li:hover > a::after {
  color: var(--tp-theme-redical);
}
.main-menu ul li:hover.has-dropdown > a::after {
  content: "\f068";
}
.main-menu ul li:hover > .submenu {
  top: 100%;
  visibility: visible;
  opacity: 1;
  -webkit-transform: perspective(300px) rotateX(0deg);
  -moz-transform: perspective(300px) rotateX(0deg);
  -ms-transform: perspective(300px) rotateX(0deg);
  -o-transform: perspective(300px) rotateX(0deg);
  transform: perspective(300px) rotateX(0deg);
}
.main-menu ul li:hover > .mega-menu {
  top: 100%;
  visibility: visible;
  opacity: 1;
  -webkit-transform: perspective(300px) rotateX(0deg);
  -moz-transform: perspective(300px) rotateX(0deg);
  -ms-transform: perspective(300px) rotateX(0deg);
  -o-transform: perspective(300px) rotateX(0deg);
  transform: perspective(300px) rotateX(0deg);
}

.main-manu-before::before {
  position: absolute;
  left: -50px;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  display: inline-block;
  width: 1px;
  height: 40px;
  background-color: #e2e2e2;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px),
  only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-manu-before::before {
    display: none;
  }
}

.header-mail-info {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 400;
  color: #000;
}
.header-mail-info i {
  margin-right: 7px;
  color: #3756f7;
}

/* .header-border-button {
  border-bottom: 1px solid var(--tp-border-2);
} */

@media only screen and (min-width: 1500px) and (max-width: 1700px) {
  .header-mail-info {
    margin-right: 20px;
  }
}
@media only screen and (min-width: 0px) and (max-width: 1499px) {
  .header-mail-info {
    display: none;
  }
}

.app-hero-img-2 {
  position: absolute;
  bottom: 0;
  left: 480px;
}

.tp-it-header__top {
  padding-left: 105px;
  padding-right: 105px;
  padding-bottom: 10px;
  padding-top: 10px;
}
.tp-it-header__top-left span {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 400;
  color: var(--tp-theme-vogue);
}
.tp-it-header__top-left a {
  color: var(--tp-theme-vogue);
  margin: 0 8px;
}
.tp-it-header__top-left a:hover {
  color: var(--tp-theme-orange);
}
.tp-it-header__top-right {
  display: flex;
  justify-content: flex-end;
}
.tp-it-header__top-right-menu ul li {
  display: inline-block;
  margin-right: 40px;
}
.tp-it-header__top-right-menu ul li a {
  color: var(--tp-theme-vogue);
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
}
.tp-it-header__top-right-menu ul li a:hover {
  color: var(--tp-theme-orange);
}
.tp-it-header__top-right-lang {
  position: relative;
}
.tp-it-header__top-right-lang:hover ul {
  top: 110%;
  opacity: 1;
  visibility: visible;
}
.tp-it-header__top-right-lang:hover span i {
  transform: rotate(-180deg);
}
.tp-it-header__top-right-lang span {
  position: relative;
  margin-left: 40px;
  font-weight: 400;
  color: var(--tp-theme-vogue);
  text-transform: uppercase;
}
.tp-it-header__top-right-lang span i {
  color: var(--tp-theme-orange);
}
.tp-it-header__top-right-lang span::before {
  content: "";
  width: 2px;
  height: 13px;
  background-color: #d0d1d1;
  display: inline-block;
  position: absolute;
  left: -40px;
}
.tp-it-header__top-right-lang ul {
  background-color: var(--tp-theme-vogue);
  text-align: start;
  position: absolute;
  top: 100%;
  left: 40px;
  padding: 20px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  z-index: 99;
  border-radius: 5px;
}
.tp-it-header__top-right-lang ul li a {
  color: var(--tp-common-white);
  padding: 3px 0;
  display: inline-block;
}
.tp-it-header__main {
  border-radius: 10px;
  margin-left: 120px;
  margin-right: 120px;
  padding-left: 25px;
  padding-right: 25px;
  box-shadow: inset 0px 8px 16px 0px rgba(101, 101, 101, 0.2);
  background-color: var(--tp-common-white);
  margin-bottom: -50px;
  z-index: 9;
}
@media only screen and (min-width: 1300px) and (max-width: 1500px) {
  .tp-it-header__main {
    margin-right: 50px;
    margin-left: 50px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-it-header__main {
    margin-right: 40px;
    margin-left: 40px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-it-header__main {
    margin-right: 40px;
    margin-left: 40px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
@media (max-width: 767px),
  only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-it-header__main {
    padding-top: 20px;
    padding-bottom: 20px;
    margin-right: 0;
    margin-left: 0;
    border-radius: 0;
    margin-bottom: 0;
  }
}
@media (max-width: 576px) {
  .tp-it-header__main {
    padding-right: 0;
    padding-left: 0;
  }
}
.tp-it-header__main-cta span {
  font-size: 16px;
  display: block;
  text-transform: uppercase;
  color: var(--tp-theme-vogue);
}
.tp-it-header__main-cta span i {
  color: var(--tp-theme-orange);
  margin-right: 3px;
}
.tp-it-header__main-cta b {
  color: var(--tp-theme-vogue);
  font-size: 20px;
  display: block;
  text-transform: uppercase;
}

.it-menu {
  text-align: center;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .it-menu ul {
    padding-left: 0;
  }
}
.it-menu ul li {
  position: relative;
  list-style: none;
  display: inline-block;
  margin-right: 48px;
}
@media only screen and (min-width: 1550px) and (max-width: 1710px) {
  .it-menu ul li {
    margin-right: 35px;
  }
}
@media only screen and (min-width: 1500px) and (max-width: 1550px) {
  .it-menu ul li {
    margin-right: 30px;
  }
}
@media only screen and (min-width: 1300px) and (max-width: 1500px) {
  .it-menu ul li {
    margin-right: 30px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 992px) and (max-width: 1199px) {
  .it-menu ul li {
    margin-right: 22px;
  }
}
.it-menu ul li a {
  color: var(--tp-theme-vogue);
  padding: 38px 0;
}
@media only screen and (min-width: 1300px) and (max-width: 1500px) {
  .it-menu ul li a {
    font-size: 14px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .it-menu ul li a {
    font-size: 14px;
  }
}
.it-menu ul li.has-dropdown > a {
  position: relative;
}
.it-menu ul li.has-dropdown > a::after {
  color: var(--tp-theme-orange);
}
.it-menu ul li .submenu {
  padding: 35px 10px;
  position: absolute;
  top: 120%;
  left: 0;
  min-width: 220px;
  background: var(--tp-common-black);
  z-index: 99;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  visibility: hidden;
  opacity: 0;
  -webkit-box-shadow: 0px 20px 30px rgba(3, 6, 31, 0.2);
  -moz-box-shadow: 0px 20px 30px rgba(3, 6, 31, 0.2);
  -ms-box-shadow: 0px 20px 30px rgba(3, 6, 31, 0.2);
  -o-box-shadow: 0px 20px 30px rgba(3, 6, 31, 0.2);
  box-shadow: 0px 20px 30px rgba(3, 6, 31, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.08);
  transform-origin: top;
  -webkit-transform: perspective(300px) rotateX(-18deg);
  -moz-transform: perspective(300px) rotateX(-18deg);
  -ms-transform: perspective(300px) rotateX(-18deg);
  -o-transform: perspective(300px) rotateX(-18deg);
  transform: perspective(300px) rotateX(-18deg);
}
.it-menu ul li .submenu li {
  display: block;
  width: 100%;
  margin: 0;
  padding: 0 0;
}
.it-menu ul li .submenu li.has-dropdown > a::after {
  position: absolute;
  top: 50%;
  right: 30px;
  -webkit-transform: translateY(-50%) rotate(-90deg);
  -moz-transform: translateY(-50%) rotate(-90deg);
  -ms-transform: translateY(-50%) rotate(-90deg);
  -o-transform: translateY(-50%) rotate(-90deg);
  transform: translateY(-50%) rotate(-90deg);
}
.it-menu ul li .submenu li a {
  position: relative;
  z-index: 1;
  width: 100%;
  display: block;
  font-size: 14px;
  color: var(--tp-common-white);
  padding: 8px 20px;
  font-weight: 400;
  text-transform: capitalize;
  letter-spacing: 0;
  text-align: left;
}
.it-menu ul li .submenu li .submenu {
  left: 120%;
  top: 0;
  visibility: hidden;
  opacity: 0;
}
.it-menu ul li .submenu li:hover > a {
  color: var(--tp-theme-redical);
  background-color: rgba(150, 144, 162, 0.06);
}
.it-menu ul li .submenu li:hover > a::after {
  color: var(--tp-theme-redical);
  -webkit-transform: translateY(-50%) rotate(45deg);
  -moz-transform: translateY(-50%) rotate(45deg);
  -ms-transform: translateY(-50%) rotate(45deg);
  -o-transform: translateY(-50%) rotate(45deg);
  transform: translateY(-50%) rotate(45deg);
}
.it-menu ul li .submenu li:hover > .submenu {
  left: 100%;
  visibility: visible;
  opacity: 1;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .it-menu ul li .mega-menu {
    margin-right: 0;
    margin-left: 150px;
  }
}
.it-menu ul li:hover > a {
  color: var(--tp-theme-redical);
}
.it-menu ul li:hover > a::after {
  color: var(--tp-theme-redical);
}
.it-menu ul li:hover.has-dropdown > a::after {
  content: "\f068";
}
.it-menu ul li:hover > .submenu {
  top: 100%;
  visibility: visible;
  opacity: 1;
  -webkit-transform: perspective(300px) rotateX(0deg);
  -moz-transform: perspective(300px) rotateX(0deg);
  -ms-transform: perspective(300px) rotateX(0deg);
  -o-transform: perspective(300px) rotateX(0deg);
  transform: perspective(300px) rotateX(0deg);
}
.it-menu::before {
  display: none;
}

.da-menu {
  text-align: center;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .da-menu ul {
    padding-left: 0;
  }
}
.da-menu ul li {
  position: relative;
  list-style: none;
  display: inline-block;
  margin-right: 48px;
}
@media only screen and (min-width: 1711px) and (max-width: 1850px) {
  .da-menu ul li {
    margin-right: 35px;
  }
}
@media only screen and (min-width: 1550px) and (max-width: 1710px) {
  .da-menu ul li {
    margin-right: 35px;
  }
}
@media only screen and (min-width: 1500px) and (max-width: 1550px) {
  .da-menu ul li {
    margin-right: 30px;
  }
}
@media only screen and (min-width: 1300px) and (max-width: 1500px) {
  .da-menu ul li {
    margin-right: 30px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 992px) and (max-width: 1199px) {
  .da-menu ul li {
    margin-right: 22px;
  }
}
.da-menu ul li a {
  color: var(--tp-common-black);
  padding: 38px 0;
}
@media only screen and (min-width: 1300px) and (max-width: 1500px) {
  .da-menu ul li a {
    font-size: 14px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .da-menu ul li a {
    font-size: 14px;
  }
}
.da-menu ul li.has-dropdown > a {
  position: relative;
}
.da-menu ul li.has-dropdown > a::after {
  color: var(--tp-common-black);
}
.da-menu ul li .submenu {
  padding: 15px 0;
  position: absolute;
  top: 120%;
  left: 0;
  min-width: 220px;
  background: var(--tp-common-white);
  z-index: 99;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  visibility: hidden;
  opacity: 0;
  -webkit-box-shadow: 0px 20px 30px rgba(3, 6, 31, 0.2);
  -moz-box-shadow: 0px 20px 30px rgba(3, 6, 31, 0.2);
  -ms-box-shadow: 0px 20px 30px rgba(3, 6, 31, 0.2);
  -o-box-shadow: 0px 20px 30px rgba(3, 6, 31, 0.2);
  box-shadow: 0px 20px 30px rgba(3, 6, 31, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.08);
  transform-origin: top;
  -webkit-transform: perspective(300px) rotateX(-18deg);
  -moz-transform: perspective(300px) rotateX(-18deg);
  -ms-transform: perspective(300px) rotateX(-18deg);
  -o-transform: perspective(300px) rotateX(-18deg);
  transform: perspective(300px) rotateX(-18deg);
}
.da-menu ul li .submenu li {
  display: block;
  width: 100%;
  margin: 0;
  padding: 0 0;
}
.da-menu ul li .submenu li.has-dropdown > a::after {
  position: absolute;
  top: 50%;
  right: 30px;
  -webkit-transform: translateY(-50%) rotate(-90deg);
  -moz-transform: translateY(-50%) rotate(-90deg);
  -ms-transform: translateY(-50%) rotate(-90deg);
  -o-transform: translateY(-50%) rotate(-90deg);
  transform: translateY(-50%) rotate(-90deg);
}
.da-menu ul li .submenu li a {
  position: relative;
  z-index: 1;
  width: 100%;
  display: block;
  font-size: 14px;
  color: var(--tp-text-body);
  padding: 5px 20px;
  font-weight: 400;
  text-transform: capitalize;
  letter-spacing: 0;
  text-align: left;
}
.da-menu ul li .submenu li .submenu {
  left: 120%;
  top: 0;
  visibility: hidden;
  opacity: 0;
}
.da-menu ul li .submenu li:hover > a {
  color: var(--tp-theme-redical);
  background-color: rgba(150, 144, 162, 0.06);
}
.da-menu ul li .submenu li:hover > a::after {
  color: var(--tp-theme-redical);
  -webkit-transform: translateY(-50%) rotate(45deg);
  -moz-transform: translateY(-50%) rotate(45deg);
  -ms-transform: translateY(-50%) rotate(45deg);
  -o-transform: translateY(-50%) rotate(45deg);
  transform: translateY(-50%) rotate(45deg);
}
.da-menu ul li .submenu li:hover > .submenu {
  left: 100%;
  visibility: visible;
  opacity: 1;
}
.da-menu ul li .mega-menu {
  background-color: white;
}
.da-menu ul li .mega-menu li ul li a {
  color: var(--tp-common-black);
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .da-menu ul li .mega-menu {
    margin-right: 0;
    margin-left: 150px;
  }
}
.da-menu ul li:hover > a {
  color: var(--tp-theme-redical);
}
.da-menu ul li:hover > a::after {
  color: var(--tp-theme-redical);
}
.da-menu ul li:hover.has-dropdown > a::after {
  content: "\f068";
}
.da-menu ul li:hover > .submenu {
  top: 100%;
  visibility: visible;
  opacity: 1;
  -webkit-transform: perspective(300px) rotateX(0deg);
  -moz-transform: perspective(300px) rotateX(0deg);
  -ms-transform: perspective(300px) rotateX(0deg);
  -o-transform: perspective(300px) rotateX(0deg);
  transform: perspective(300px) rotateX(0deg);
}
.da-menu::before {
  display: none;
}

.app-menu {
  text-align: center;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .app-menu ul {
    padding-left: 0;
  }
}
.app-menu ul li {
  position: relative;
  list-style: none;
  display: inline-block;
  margin-right: 48px;
}
@media only screen and (min-width: 1711px) and (max-width: 1850px) {
  .app-menu ul li {
    margin-right: 35px;
  }
}
@media only screen and (min-width: 1550px) and (max-width: 1710px) {
  .app-menu ul li {
    margin-right: 35px;
  }
}
@media only screen and (min-width: 1500px) and (max-width: 1550px) {
  .app-menu ul li {
    margin-right: 30px;
  }
}
@media only screen and (min-width: 1300px) and (max-width: 1500px) {
  .app-menu ul li {
    margin-right: 30px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 992px) and (max-width: 1199px) {
  .app-menu ul li {
    margin-right: 22px;
  }
}
.app-menu ul li a {
  color: var(--tp-common-black);
  padding: 38px 0;
}
@media only screen and (min-width: 1300px) and (max-width: 1500px) {
  .app-menu ul li a {
    font-size: 14px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .app-menu ul li a {
    font-size: 14px;
  }
}
.app-menu ul li.has-dropdown > a {
  position: relative;
}
.app-menu ul li.has-dropdown > a::after {
  color: var(--tp-common-black);
}
.app-menu ul li .submenu {
  padding: 15px 0;
  position: absolute;
  top: 120%;
  left: 0;
  min-width: 220px;
  background: var(--tp-common-white);
  z-index: 99;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  visibility: hidden;
  opacity: 0;
  -webkit-box-shadow: 0px 20px 30px rgba(3, 6, 31, 0.2);
  -moz-box-shadow: 0px 20px 30px rgba(3, 6, 31, 0.2);
  -ms-box-shadow: 0px 20px 30px rgba(3, 6, 31, 0.2);
  -o-box-shadow: 0px 20px 30px rgba(3, 6, 31, 0.2);
  box-shadow: 0px 20px 30px rgba(3, 6, 31, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.08);
  transform-origin: top;
  -webkit-transform: perspective(300px) rotateX(-18deg);
  -moz-transform: perspective(300px) rotateX(-18deg);
  -ms-transform: perspective(300px) rotateX(-18deg);
  -o-transform: perspective(300px) rotateX(-18deg);
  transform: perspective(300px) rotateX(-18deg);
}
.app-menu ul li .submenu li {
  display: block;
  width: 100%;
  margin: 0;
  padding: 0 0;
}
.app-menu ul li .submenu li.has-dropdown > a::after {
  position: absolute;
  top: 50%;
  right: 30px;
  -webkit-transform: translateY(-50%) rotate(-90deg);
  -moz-transform: translateY(-50%) rotate(-90deg);
  -ms-transform: translateY(-50%) rotate(-90deg);
  -o-transform: translateY(-50%) rotate(-90deg);
  transform: translateY(-50%) rotate(-90deg);
}
.app-menu ul li .submenu li a {
  position: relative;
  z-index: 1;
  width: 100%;
  display: block;
  font-size: 14px;
  color: var(--tp-text-body);
  padding: 5px 20px;
  font-weight: 400;
  text-transform: capitalize;
  letter-spacing: 0;
  text-align: left;
}
.app-menu ul li .submenu li .submenu {
  left: 120%;
  top: 0;
  visibility: hidden;
  opacity: 0;
}
.app-menu ul li .submenu li:hover > a {
  color: var(--tp-theme-redical);
  background-color: rgba(150, 144, 162, 0.06);
}
.app-menu ul li .submenu li:hover > a::after {
  color: var(--tp-theme-redical);
  -webkit-transform: translateY(-50%) rotate(45deg);
  -moz-transform: translateY(-50%) rotate(45deg);
  -ms-transform: translateY(-50%) rotate(45deg);
  -o-transform: translateY(-50%) rotate(45deg);
  transform: translateY(-50%) rotate(45deg);
}
.app-menu ul li .submenu li:hover > .submenu {
  left: 100%;
  visibility: visible;
  opacity: 1;
}
.app-menu ul li .mega-menu {
  background-color: white;
}
.app-menu ul li .mega-menu li ul li a {
  color: var(--tp-common-black);
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .app-menu ul li .mega-menu {
    margin-right: 0;
    margin-left: 150px;
  }
}
.app-menu ul li:hover > a {
  color: var(--tp-theme-redical);
}
.app-menu ul li:hover > a::after {
  color: var(--tp-theme-redical);
}
.app-menu ul li:hover.has-dropdown > a::after {
  content: "\f068";
}
.app-menu ul li:hover > .submenu {
  top: 100%;
  visibility: visible;
  opacity: 1;
  -webkit-transform: perspective(300px) rotateX(0deg);
  -moz-transform: perspective(300px) rotateX(0deg);
  -ms-transform: perspective(300px) rotateX(0deg);
  -o-transform: perspective(300px) rotateX(0deg);
  transform: perspective(300px) rotateX(0deg);
}
.app-menu::before {
  display: none;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .ptg-menu ul {
    padding-left: 40;
  }
}
.ptg-menu ul li {
  position: relative;
  list-style: none;
  display: inline-block;
  margin-right: 48px;
}
@media only screen and (min-width: 1711px) and (max-width: 1850px) {
  .ptg-menu ul li {
    margin-right: 35px;
  }
}
@media only screen and (min-width: 1550px) and (max-width: 1710px) {
  .ptg-menu ul li {
    margin-right: 35px;
  }
}
@media only screen and (min-width: 1500px) and (max-width: 1550px) {
  .ptg-menu ul li {
    margin-right: 30px;
  }
}
@media only screen and (min-width: 1300px) and (max-width: 1500px) {
  .ptg-menu ul li {
    margin-right: 30px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 992px) and (max-width: 1199px) {
  .ptg-menu ul li {
    margin-right: 22px;
  }
}
.ptg-menu ul li a {
  color: var(--tp-common-white);
  padding: 38px 0;
}
@media only screen and (min-width: 1300px) and (max-width: 1500px) {
  .ptg-menu ul li a {
    font-size: 14px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .ptg-menu ul li a {
    font-size: 14px;
  }
}
.ptg-menu ul li.has-dropdown > a {
  position: relative;
}
.ptg-menu ul li.has-dropdown > a::after {
  color: var(--tp-common-white);
}
.ptg-menu ul li .submenu {
  padding: 15px 0;
  position: absolute;
  top: 120%;
  left: 0;
  min-width: 220px;
  background: var(--tp-common-black);
  z-index: 99;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  visibility: hidden;
  opacity: 0;
  -webkit-box-shadow: 0px 20px 30px rgba(3, 6, 31, 0.2);
  -moz-box-shadow: 0px 20px 30px rgba(3, 6, 31, 0.2);
  -ms-box-shadow: 0px 20px 30px rgba(3, 6, 31, 0.2);
  -o-box-shadow: 0px 20px 30px rgba(3, 6, 31, 0.2);
  box-shadow: 0px 20px 30px rgba(3, 6, 31, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.08);
  transform-origin: top;
  -webkit-transform: perspective(300px) rotateX(-18deg);
  -moz-transform: perspective(300px) rotateX(-18deg);
  -ms-transform: perspective(300px) rotateX(-18deg);
  -o-transform: perspective(300px) rotateX(-18deg);
  transform: perspective(300px) rotateX(-18deg);
}
.ptg-menu ul li .submenu li {
  display: block;
  width: 100%;
  margin: 0;
  padding: 0 0;
}
.ptg-menu ul li .submenu li.has-dropdown > a::after {
  position: absolute;
  top: 50%;
  right: 30px;
  -webkit-transform: translateY(-50%) rotate(-90deg);
  -moz-transform: translateY(-50%) rotate(-90deg);
  -ms-transform: translateY(-50%) rotate(-90deg);
  -o-transform: translateY(-50%) rotate(-90deg);
  transform: translateY(-50%) rotate(-90deg);
}
.ptg-menu ul li .submenu li a {
  position: relative;
  z-index: 1;
  width: 100%;
  display: block;
  font-size: 14px;
  color: var(--tp-common-white);
  padding: 5px 20px;
  font-weight: 400;
  text-transform: capitalize;
  letter-spacing: 0;
  text-align: left;
}
.ptg-menu ul li .submenu li .submenu {
  left: 120%;
  top: 0;
  visibility: hidden;
  opacity: 0;
}
.ptg-menu ul li .submenu li:hover > a {
  color: var(--tp-theme-redical);
  background-color: rgba(150, 144, 162, 0.06);
}
.ptg-menu ul li .submenu li:hover > a::after {
  color: var(--tp-theme-redical);
  -webkit-transform: translateY(-50%) rotate(45deg);
  -moz-transform: translateY(-50%) rotate(45deg);
  -ms-transform: translateY(-50%) rotate(45deg);
  -o-transform: translateY(-50%) rotate(45deg);
  transform: translateY(-50%) rotate(45deg);
}
.ptg-menu ul li .submenu li:hover > .submenu {
  left: 100%;
  visibility: visible;
  opacity: 1;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .ptg-menu ul li .mega-menu {
    margin-right: 0;
    margin-left: 150px;
  }
}
.ptg-menu ul li:hover > a {
  background-image: linear-gradient(90deg, #5a6ef8, #fe7a8f);
  color: var(--tp-grey-1);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.ptg-menu ul li:hover > a::after {
  color: var(--tp-theme-redical);
}
.ptg-menu ul li:hover.has-dropdown > a::after {
  content: "\f068";
}
.ptg-menu ul li:hover > .submenu {
  top: 100%;
  visibility: visible;
  opacity: 1;
  -webkit-transform: perspective(300px) rotateX(0deg);
  -moz-transform: perspective(300px) rotateX(0deg);
  -ms-transform: perspective(300px) rotateX(0deg);
  -o-transform: perspective(300px) rotateX(0deg);
  transform: perspective(300px) rotateX(0deg);
}
.ptg-menu::before {
  display: none;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .mp-menu ul {
    padding-left: 0;
  }
}
.mp-menu ul li {
  position: relative;
  list-style: none;
  display: inline-block;
  margin-right: 30px;
}
.mp-menu ul li:last-child {
  margin-left: 0;
}
@media only screen and (min-width: 1711px) and (max-width: 1850px) {
  .mp-menu ul li {
    margin-right: 35px;
  }
}
@media only screen and (min-width: 1550px) and (max-width: 1710px) {
  .mp-menu ul li {
    margin-right: 35px;
  }
}
@media only screen and (min-width: 1500px) and (max-width: 1550px) {
  .mp-menu ul li {
    margin-right: 30px;
  }
}
@media only screen and (min-width: 1300px) and (max-width: 1500px) {
  .mp-menu ul li {
    margin-right: 30px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 992px) and (max-width: 1199px) {
  .mp-menu ul li {
    margin-right: 22px;
  }
}
.mp-menu ul li a {
  color: #000;
  padding: 48px 0;
  text-transform: capitalize;
}
.mp-menu ul li a::before {
  position: absolute;
  top: 60%;
  content: "";
  width: 0;
  height: 2px;
  background-color: var(--tp-grey-4);
  display: block;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  opacity: 0;
}
@media only screen and (min-width: 1300px) and (max-width: 1500px) {
  .mp-menu ul li a {
    font-size: 14px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .mp-menu ul li a {
    font-size: 14px;
  }
}
.mp-menu ul li.has-dropdown > a {
  position: relative;
}
.mp-menu ul li.has-dropdown > a::after {
  color: #000;
}
.mp-menu ul li .submenu {
  padding: 15px 0;
  position: absolute;
  top: 120%;
  left: 0;
  min-width: 220px;
  color: #000;
  background: var(--tp-common-white);
  z-index: 99;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  visibility: hidden;
  opacity: 0;
  -webkit-box-shadow: 0px 20px 30px rgba(3, 6, 31, 0.2);
  -moz-box-shadow: 0px 20px 30px rgba(3, 6, 31, 0.2);
  -ms-box-shadow: 0px 20px 30px rgba(3, 6, 31, 0.2);
  -o-box-shadow: 0px 20px 30px rgba(3, 6, 31, 0.2);
  box-shadow: 0px 20px 30px rgba(3, 6, 31, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.08);
  transform-origin: top;
  -webkit-transform: perspective(300px) rotateX(-18deg);
  -moz-transform: perspective(300px) rotateX(-18deg);
  -ms-transform: perspective(300px) rotateX(-18deg);
  -o-transform: perspective(300px) rotateX(-18deg);
  transform: perspective(300px) rotateX(-18deg);
}
.mp-menu ul li .submenu li {
  display: block;
  width: 100%;
  margin: 0;
  padding: 0 0;
}
.mp-menu ul li .submenu li.has-dropdown > a::after {
  position: absolute;
  top: 50%;
  right: 30px;
  -webkit-transform: translateY(-50%) rotate(-90deg);
  -moz-transform: translateY(-50%) rotate(-90deg);
  -ms-transform: translateY(-50%) rotate(-90deg);
  -o-transform: translateY(-50%) rotate(-90deg);
  transform: translateY(-50%) rotate(-90deg);
}
.mp-menu ul li .submenu li a {
  position: relative;
  z-index: 1;
  width: 100%;
  display: block;
  font-size: 14px;
  color: #000;
  padding: 5px 20px;
  font-weight: 400;
  text-transform: capitalize;
  letter-spacing: 0;
  text-align: left;
}
.mp-menu ul li .submenu li .submenu {
  left: 120%;
  top: 0;
  visibility: hidden;
  opacity: 0;
}
.mp-menu ul li .submenu li:hover > a {
  color: var(--tp-theme-redical);
  background-color: rgba(150, 144, 162, 0.06);
}
.mp-menu ul li .submenu li:hover > a::before {
  display: none;
}
.mp-menu ul li .submenu li:hover > a::after {
  color: var(--tp-theme-redical);
  -webkit-transform: translateY(-50%) rotate(45deg);
  -moz-transform: translateY(-50%) rotate(45deg);
  -ms-transform: translateY(-50%) rotate(45deg);
  -o-transform: translateY(-50%) rotate(45deg);
  transform: translateY(-50%) rotate(45deg);
}
.mp-menu ul li .submenu li:hover > .submenu {
  left: 100%;
  visibility: visible;
  opacity: 1;
}
.mp-menu ul li .mega-menu {
  background-color: white;
}
.mp-menu ul li .mega-menu li a::before {
  display: none;
}
.mp-menu ul li .mega-menu li ul li a {
  color: var(--tp-common-black);
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .mp-menu ul li .mega-menu {
    margin-right: 0;
    margin-left: 150px;
  }
}
.mp-menu ul li:hover > a {
  color: #000;
}
.mp-menu ul li:hover > a::before {
  opacity: 1;
  width: 100%;
}
.mp-menu ul li:hover > a::after {
  color: #000;
}
.mp-menu ul li:hover.has-dropdown > a::after {
  content: "\f068";
}
.mp-menu ul li:hover > .submenu {
  top: 100%;
  visibility: visible;
  opacity: 1;
  -webkit-transform: perspective(300px) rotateX(0deg);
  -moz-transform: perspective(300px) rotateX(0deg);
  -ms-transform: perspective(300px) rotateX(0deg);
  -o-transform: perspective(300px) rotateX(0deg);
  transform: perspective(300px) rotateX(0deg);
}
.mp-menu::before {
  display: none;
}

.law-menu {
  position: relative;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .law-menu ul {
    padding-left: 0;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .law-menu ul {
    float: right;
  }
}
.law-menu ul li {
  position: relative;
  list-style: none;
  display: inline-block;
  margin-right: 48px;
}
@media only screen and (min-width: 1500px) and (max-width: 1700px) {
  .law-menu ul li {
    margin-right: 40px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1499px) {
  .law-menu ul li {
    margin-right: 30px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 992px) and (max-width: 1199px) {
  .law-menu ul li {
    margin-right: 15px;
  }
}
.law-menu ul li a {
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  padding: 35px 0;
  text-transform: uppercase;
}
.law-menu ul li.has-dropdown > a {
  position: relative;
}
.law-menu ul li.has-dropdown > a::after {
  content: "\f067";
  -webkit-transform: translateY(1px);
  -moz-transform: translateY(1px);
  -ms-transform: translateY(1px);
  -o-transform: translateY(1px);
  transform: translateY(1px);
  font-size: 16px;
  color: #fff;
  font-family: var(--tp-ff-fontawesome);
  font-weight: 400;
  margin-left: 5px;
  display: inline-block;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.law-menu ul li .submenu {
  padding: 15px 0;
  position: absolute;
  top: 120%;
  left: 0;
  min-width: 220px;
  background: var(--tp-common-white);
  z-index: 99;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  visibility: hidden;
  opacity: 0;
  -webkit-box-shadow: 0px 20px 30px rgba(3, 6, 31, 0.2);
  -moz-box-shadow: 0px 20px 30px rgba(3, 6, 31, 0.2);
  -ms-box-shadow: 0px 20px 30px rgba(3, 6, 31, 0.2);
  -o-box-shadow: 0px 20px 30px rgba(3, 6, 31, 0.2);
  box-shadow: 0px 20px 30px rgba(3, 6, 31, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.08);
  transform-origin: top;
  -webkit-transform: perspective(300px) rotateX(-18deg);
  -moz-transform: perspective(300px) rotateX(-18deg);
  -ms-transform: perspective(300px) rotateX(-18deg);
  -o-transform: perspective(300px) rotateX(-18deg);
  transform: perspective(300px) rotateX(-18deg);
}
.law-menu ul li .submenu li {
  display: block;
  width: 100%;
  margin: 0;
  padding: 0 0;
}
.law-menu ul li .submenu li.has-dropdown > a::after {
  position: absolute;
  top: 50%;
  right: 30px;
  -webkit-transform: translateY(-50%) rotate(-90deg);
  -moz-transform: translateY(-50%) rotate(-90deg);
  -ms-transform: translateY(-50%) rotate(-90deg);
  -o-transform: translateY(-50%) rotate(-90deg);
  transform: translateY(-50%) rotate(-90deg);
}
.law-menu ul li .submenu li a {
  position: relative;
  z-index: 1;
  width: 100%;
  display: block;
  font-size: 14px;
  color: var(--tp-text-body);
  padding: 5px 20px;
  font-weight: 400;
  text-transform: capitalize;
  letter-spacing: 0;
  text-align: left;
}
.law-menu ul li .submenu li .submenu {
  left: 120%;
  top: 0;
  visibility: hidden;
  opacity: 0;
}
.law-menu ul li .submenu li:hover > a {
  color: var(--tp-theme-redical);
  background-color: rgba(150, 144, 162, 0.06);
}
.law-menu ul li .submenu li:hover > a::after {
  color: var(--tp-theme-redical);
  -webkit-transform: translateY(-50%) rotate(45deg);
  -moz-transform: translateY(-50%) rotate(45deg);
  -ms-transform: translateY(-50%) rotate(45deg);
  -o-transform: translateY(-50%) rotate(45deg);
  transform: translateY(-50%) rotate(45deg);
}
.law-menu ul li .submenu li:hover > .submenu {
  left: 100%;
  visibility: visible;
  opacity: 1;
}
.law-menu ul li .mega-menu {
  background-color: white;
}
.law-menu ul li .mega-menu li ul li a {
  color: var(--tp-common-black);
}
@media only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 1400px) and (max-width: 1600px) {
  .law-menu ul li .mega-menu {
    margin-right: 0;
    margin-left: -200px;
  }
}
.law-menu ul li:hover > a {
  color: var(--tp-theme-red);
}
.law-menu ul li:hover > a::after {
  color: var(--tp-theme-red);
}
.law-menu ul li:hover.has-dropdown > a::after {
  content: "\f068";
}
.law-menu ul li:hover > .submenu {
  top: 100%;
  visibility: visible;
  opacity: 1;
  -webkit-transform: perspective(300px) rotateX(0deg);
  -moz-transform: perspective(300px) rotateX(0deg);
  -ms-transform: perspective(300px) rotateX(0deg);
  -o-transform: perspective(300px) rotateX(0deg);
  transform: perspective(300px) rotateX(0deg);
}

.ca-menu {
  position: relative;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .ca-menu ul {
    padding-left: 0;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ca-menu ul {
    float: right;
  }
}
.ca-menu ul li {
  position: relative;
  list-style: none;
  display: inline-block;
  margin-right: 48px;
}
@media only screen and (min-width: 1500px) and (max-width: 1700px) {
  .ca-menu ul li {
    margin-right: 40px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1499px) {
  .ca-menu ul li {
    margin-right: 30px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 992px) and (max-width: 1199px) {
  .ca-menu ul li {
    margin-right: 19px;
  }
}
.ca-menu ul li a {
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  padding: 45px 0;
  text-transform: uppercase;
}
.ca-menu ul li.has-dropdown > a {
  position: relative;
}
.ca-menu ul li.has-dropdown > a::after {
  content: "\f067";
  -webkit-transform: translateY(1px);
  -moz-transform: translateY(1px);
  -ms-transform: translateY(1px);
  -o-transform: translateY(1px);
  transform: translateY(1px);
  font-size: 16px;
  color: #fff;
  font-family: var(--tp-ff-fontawesome);
  font-weight: 400;
  margin-left: 5px;
  display: inline-block;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.ca-menu ul li .submenu {
  padding: 15px 0;
  position: absolute;
  top: 120%;
  left: 0;
  min-width: 220px;
  background: var(--tp-common-white);
  z-index: 99;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  visibility: hidden;
  opacity: 0;
  -webkit-box-shadow: 0px 20px 30px rgba(3, 6, 31, 0.2);
  -moz-box-shadow: 0px 20px 30px rgba(3, 6, 31, 0.2);
  -ms-box-shadow: 0px 20px 30px rgba(3, 6, 31, 0.2);
  -o-box-shadow: 0px 20px 30px rgba(3, 6, 31, 0.2);
  box-shadow: 0px 20px 30px rgba(3, 6, 31, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.08);
  transform-origin: top;
  -webkit-transform: perspective(300px) rotateX(-18deg);
  -moz-transform: perspective(300px) rotateX(-18deg);
  -ms-transform: perspective(300px) rotateX(-18deg);
  -o-transform: perspective(300px) rotateX(-18deg);
  transform: perspective(300px) rotateX(-18deg);
}
.ca-menu ul li .submenu li {
  display: block;
  width: 100%;
  margin: 0;
  padding: 0 0;
}
.ca-menu ul li .submenu li.has-dropdown > a::after {
  position: absolute;
  top: 50%;
  right: 30px;
  -webkit-transform: translateY(-50%) rotate(-90deg);
  -moz-transform: translateY(-50%) rotate(-90deg);
  -ms-transform: translateY(-50%) rotate(-90deg);
  -o-transform: translateY(-50%) rotate(-90deg);
  transform: translateY(-50%) rotate(-90deg);
}
.ca-menu ul li .submenu li a {
  position: relative;
  z-index: 1;
  width: 100%;
  display: block;
  font-size: 14px;
  color: var(--tp-text-body);
  padding: 5px 20px;
  font-weight: 400;
  text-transform: capitalize;
  letter-spacing: 0;
  text-align: left;
}
.ca-menu ul li .submenu li .submenu {
  left: 120%;
  top: 0;
  visibility: hidden;
  opacity: 0;
}
.ca-menu ul li .submenu li:hover > a {
  color: var(--tp-theme-redical);
  background-color: rgba(150, 144, 162, 0.06);
}
.ca-menu ul li .submenu li:hover > a::after {
  color: var(--tp-theme-redical);
  -webkit-transform: translateY(-50%) rotate(45deg);
  -moz-transform: translateY(-50%) rotate(45deg);
  -ms-transform: translateY(-50%) rotate(45deg);
  -o-transform: translateY(-50%) rotate(45deg);
  transform: translateY(-50%) rotate(45deg);
}
.ca-menu ul li .submenu li:hover > .submenu {
  left: 100%;
  visibility: visible;
  opacity: 1;
}
.ca-menu ul li .mega-menu {
  background-color: white;
}
.ca-menu ul li .mega-menu li ul li a {
  color: var(--tp-common-black);
}
@media only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 1400px) and (max-width: 1600px) {
  .ca-menu ul li .mega-menu {
    margin-right: 0;
    margin-left: -200px;
  }
}
.ca-menu ul li:hover > a {
  color: var(--tp-theme-red);
}
.ca-menu ul li:hover > a::after {
  color: var(--tp-theme-red);
}
.ca-menu ul li:hover.has-dropdown > a::after {
  content: "\f068";
}
.ca-menu ul li:hover > .submenu {
  top: 100%;
  visibility: visible;
  opacity: 1;
  -webkit-transform: perspective(300px) rotateX(0deg);
  -moz-transform: perspective(300px) rotateX(0deg);
  -ms-transform: perspective(300px) rotateX(0deg);
  -o-transform: perspective(300px) rotateX(0deg);
  transform: perspective(300px) rotateX(0deg);
}

.seo-menu {
  position: relative;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .seo-menu {
    margin-left: 80px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .seo-menu ul {
    padding-left: 0;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .seo-menu ul {
    float: right;
  }
}
.seo-menu ul li {
  position: relative;
  list-style: none;
  display: inline-block;
  margin-right: 48px;
}
@media only screen and (min-width: 1500px) and (max-width: 1700px) {
  .seo-menu ul li {
    margin-right: 40px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1499px) {
  .seo-menu ul li {
    margin-right: 30px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 992px) and (max-width: 1199px) {
  .seo-menu ul li {
    margin-right: 20px;
  }
}
.seo-menu ul li a {
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  color: var(--tp-theme-seagreen);
  padding: 45px 0;
  text-transform: uppercase;
}
.seo-menu ul li.has-dropdown > a {
  position: relative;
}
.seo-menu ul li.has-dropdown > a::after {
  content: "\f067";
  -webkit-transform: translateY(1px);
  -moz-transform: translateY(1px);
  -ms-transform: translateY(1px);
  -o-transform: translateY(1px);
  transform: translateY(1px);
  font-size: 16px;
  color: var(--tp-theme-seagreen);
  font-family: var(--tp-ff-fontawesome);
  font-weight: 400;
  margin-left: 5px;
  display: inline-block;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.seo-menu ul li .submenu {
  padding: 15px 0;
  position: absolute;
  top: 120%;
  left: 0;
  min-width: 220px;
  background: var(--tp-common-white);
  z-index: 99;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  visibility: hidden;
  opacity: 0;
  -webkit-box-shadow: 0px 20px 30px rgba(3, 6, 31, 0.2);
  -moz-box-shadow: 0px 20px 30px rgba(3, 6, 31, 0.2);
  -ms-box-shadow: 0px 20px 30px rgba(3, 6, 31, 0.2);
  -o-box-shadow: 0px 20px 30px rgba(3, 6, 31, 0.2);
  box-shadow: 0px 20px 30px rgba(3, 6, 31, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.08);
  transform-origin: top;
  -webkit-transform: perspective(300px) rotateX(-18deg);
  -moz-transform: perspective(300px) rotateX(-18deg);
  -ms-transform: perspective(300px) rotateX(-18deg);
  -o-transform: perspective(300px) rotateX(-18deg);
  transform: perspective(300px) rotateX(-18deg);
}
.seo-menu ul li .submenu li {
  display: block;
  width: 100%;
  margin: 0;
  padding: 0 0;
}
.seo-menu ul li .submenu li.has-dropdown > a::after {
  position: absolute;
  top: 50%;
  right: 30px;
  -webkit-transform: translateY(-50%) rotate(-90deg);
  -moz-transform: translateY(-50%) rotate(-90deg);
  -ms-transform: translateY(-50%) rotate(-90deg);
  -o-transform: translateY(-50%) rotate(-90deg);
  transform: translateY(-50%) rotate(-90deg);
}
.seo-menu ul li .submenu li a {
  position: relative;
  z-index: 1;
  width: 100%;
  display: block;
  font-size: 14px;
  color: var(--tp-text-body);
  padding: 5px 20px;
  font-weight: 400;
  text-transform: capitalize;
  letter-spacing: 0;
  text-align: left;
}
.seo-menu ul li .submenu li .submenu {
  left: 120%;
  top: 0;
  visibility: hidden;
  opacity: 0;
}
.seo-menu ul li .submenu li:hover > a {
  color: var(--tp-theme-redical);
  background-color: rgba(150, 144, 162, 0.06);
}
.seo-menu ul li .submenu li:hover > a::after {
  color: var(--tp-theme-redical);
  -webkit-transform: translateY(-50%) rotate(45deg);
  -moz-transform: translateY(-50%) rotate(45deg);
  -ms-transform: translateY(-50%) rotate(45deg);
  -o-transform: translateY(-50%) rotate(45deg);
  transform: translateY(-50%) rotate(45deg);
}
.seo-menu ul li .submenu li:hover > .submenu {
  left: 100%;
  visibility: visible;
  opacity: 1;
}
.seo-menu ul li .mega-menu {
  background-color: white;
}
.seo-menu ul li .mega-menu li ul li a {
  color: var(--tp-common-black);
}
@media only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 1400px) and (max-width: 1600px) {
  .seo-menu ul li .mega-menu {
    margin-right: 0;
    margin-left: -200px;
  }
}
.seo-menu ul li:hover > a {
  color: var(--tp-theme-lochmara);
}
.seo-menu ul li:hover > a::after {
  color: var(--tp-theme-lochmara);
}
.seo-menu ul li:hover.has-dropdown > a::after {
  content: "\f068";
}
.seo-menu ul li:hover > .submenu {
  top: 100%;
  visibility: visible;
  opacity: 1;
  -webkit-transform: perspective(300px) rotateX(0deg);
  -moz-transform: perspective(300px) rotateX(0deg);
  -ms-transform: perspective(300px) rotateX(0deg);
  -o-transform: perspective(300px) rotateX(0deg);
  transform: perspective(300px) rotateX(0deg);
}

.da-header-cta-btn p {
  color: var(--tp-grey-4);
  font-size: 16px;
}
.da-header-cta-btn p b {
  color: var(--tp-common-black);
}

@media only screen and (min-width: 1500px) and (max-width: 1700px) {
  .tp-da-header {
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1499px) {
  .tp-da-header {
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-da-header {
    padding-right: 50px;
    padding-left: 50px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-da-header {
    padding-right: 40px;
    padding-left: 40px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-da-header {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .tp-da-header {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
@media (max-width: 576px) {
  .tp-da-header {
    padding-left: 0;
    padding-right: 0;
  }
}

@media only screen and (min-width: 1500px) and (max-width: 1700px) {
  .tp-app-header {
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1499px) {
  .tp-app-header {
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-app-header {
    padding-right: 50px;
    padding-left: 50px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-app-header {
    padding-right: 40px;
    padding-left: 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-app-header {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .tp-app-header {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
@media (max-width: 576px) {
  .tp-app-header {
    padding-left: 0;
    padding-right: 0;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-mp-header {
    padding-right: 50px;
    padding-left: 50px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-mp-header {
    padding-right: 40px;
    padding-left: 40px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-mp-header {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .tp-mp-header {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
@media (max-width: 576px) {
  .tp-mp-header {
    padding-left: 0;
    padding-right: 0;
  }
}

@media only screen and (min-width: 1500px) and (max-width: 1700px) {
  .tp-app-header {
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1499px) {
  .tp-app-header {
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-app-header {
    padding-right: 50px;
    padding-left: 50px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-app-header {
    padding-right: 40px;
    padding-left: 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-app-header {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .tp-app-header {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
@media (max-width: 576px) {
  .tp-app-header {
    padding-left: 0;
    padding-right: 0;
  }
}

.tp-law-header__top {
  padding-top: 10px;
  padding-bottom: 10px;
}
.tp-law-header__top-left span {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 400;
  color: #fff;
}
.tp-law-header__top-left a {
  color: var(--tp-theme-bouquet);
  margin: 0 8px;
}
.tp-law-header__top-left a:hover {
  color: #fff;
}
.tp-law-header__top-right {
  display: flex;
  justify-content: flex-end;
}
.tp-law-header__top-right-menu ul li {
  display: inline-block;
  margin-right: 40px;
}
.tp-law-header__top-right-menu ul li a {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
}
.tp-law-header__top-right-menu ul li a:hover {
  opacity: 0.9;
}
.tp-law-header__top-right-lang {
  position: relative;
}
.tp-law-header__top-right-lang:hover ul {
  top: 110%;
  opacity: 1;
  visibility: visible;
}
.tp-law-header__top-right-lang:hover span i {
  transform: rotate(-180deg);
}
.tp-law-header__top-right-lang span {
  position: relative;
  margin-left: 40px;
  font-weight: 400;
  color: #fff;
  text-transform: uppercase;
}
.tp-law-header__top-right-lang span i {
  color: #fff;
}
.tp-law-header__top-right-lang span::before {
  content: "";
  width: 2px;
  height: 13px;
  background-color: #d0d1d1;
  display: inline-block;
  position: absolute;
  left: -40px;
}
.tp-law-header__top-right-lang ul {
  background-color: var(--tp-theme-wine);
  text-align: start;
  position: absolute;
  top: 100%;
  left: 40px;
  padding: 20px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  z-index: 99;
}
.tp-law-header__top-right-lang ul li a {
  color: var(--tp-common-white);
  padding: 3px 0;
  display: inline-block;
}
@media only screen and (min-width: 1300px) and (max-width: 1500px) {
  .tp-law-header__top {
    padding-right: 50px;
    padding-left: 50px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-law-header__top {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-law-header__top {
    padding-right: 20px;
    padding-left: 20px;
  }
}
@media only screen and (min-width: 1300px) and (max-width: 1570px) {
  .tp-law-header__main {
    padding-right: 50px;
    padding-left: 50px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-law-header__main {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-law-header__main {
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-law-header__main {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 30px;
    padding-left: 30px;
    border-radius: 0;
    margin-bottom: 0;
  }
}
@media (max-width: 767px) {
  .tp-law-header__main {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 10px;
    padding-left: 10px;
    border-radius: 0;
    margin-bottom: 0;
  }
}
@media (max-width: 576px) {
  .tp-law-header__main {
    padding-right: 0;
    padding-left: 0;
  }
}
.tp-law-header__main-cta span {
  font-size: 16px;
  display: block;
  text-transform: uppercase;
}
.tp-law-header__main-cta span i {
  color: var(--tp-theme-orange);
  margin-right: 3px;
}
.tp-law-header__main-cta b {
  font-size: 20px;
  display: block;
  text-transform: uppercase;
}

@media only screen and (min-width: 1300px) and (max-width: 1570px) {
  .tp-ca-header__main {
    padding-right: 50px;
    padding-left: 50px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-ca-header__main {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-ca-header__main {
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-ca-header__main {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 30px;
    padding-left: 30px;
    border-radius: 0;
    margin-bottom: 0;
  }
}
@media (max-width: 767px) {
  .tp-ca-header__main {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 10px;
    padding-left: 10px;
    border-radius: 0;
    margin-bottom: 0;
  }
}
@media (max-width: 576px) {
  .tp-ca-header__main {
    padding-right: 0;
    padding-left: 0;
  }
}
.tp-ca-header__main-cta span {
  font-size: 16px;
  display: block;
  text-transform: uppercase;
}
.tp-ca-header__main-cta span i {
  color: var(--tp-theme-orange);
  margin-right: 3px;
}
.tp-ca-header__main-cta b {
  font-size: 20px;
  display: block;
  text-transform: uppercase;
}

.tp-law-border-bottom {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.law-header-cta-btn .tp-white-btn {
  border-radius: 0;
  text-transform: capitalize;
}
.law-header-cta-btn .tp-white-btn:hover {
  background-color: black;
  color: white;
}

.tp-ha-header .tp-white-btn {
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .tp-ha-header__main {
    padding-left: 0;
    padding-right: 0;
  }
}

.tp-seo-header .tp-law-header__top-left span {
  text-transform: capitalize;
}
.tp-seo-header .tp-law-header__top-left span a {
  color: #9eb6c4;
}
.tp-seo-header .tp-law-header__top-right-menu ul li {
  margin: 0;
  margin-right: 10px;
}
.tp-seo-header .tp-law-header__top-right-menu ul li:last-child {
  margin-right: 0;
}
.tp-seo-header .tp-law-header__top-right-menu ul li a {
  text-transform: none;
}
.tp-seo-header .tp-law-header__top-right-menu ul li a:hover {
  color: var(--tp-theme-atlantis);
}

/*----------------------------------------*/
/*  03. MEAN MENU CSS
/*----------------------------------------*/
/* mean menu customize */
.mean-container a.meanmenu-reveal {
  display: none;
}

.mean-container .mean-nav {
  background: none;
  margin-top: 0;
}

.mean-container .mean-bar {
  padding: 0;
  min-height: auto;
  background: none;
}

.mean-container .mean-nav > ul {
  padding: 0;
  margin: 0;
  width: 100%;
  list-style-type: none;
  display: block !important;
}

.mean-container a.meanmenu-reveal {
  display: none !important;
}

.mean-container .mean-nav ul li a {
  width: 100%;
  padding: 10px 0;
  /* color: var(--tp-common-black); */
  border-top: 1px solid rgba(255, 255, 255, 0.08);
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
}
.mean-container .mean-nav ul li a:hover {
  opacity: 0.8;
}

/* .mean-container .mean-nav ul li a.mean-expand { */
.mean-container .mean-nav ul li span.mean-expand {
  margin-top: 5px;
  padding: 0 !important;
  line-height: 14px;
  border: 1px solid rgba(255, 255, 255, 0.08) !important;
  height: 30px;
  width: 30px;
  line-height: 30px;
  color: var(--tp-common-white);
  line-height: 30px;
  top: 0;
  font-weight: 400;
}
/* .mean-container .mean-nav ul li a.mean-expand:hover { */
.mean-container .mean-nav ul li span.mean-expand:hover {
  background: var(--tp-common-black);
  color: var(--tp-common-white);
  border-color: var(--tp-theme-1);
}

.mean-container .mean-nav ul li > a > i {
  display: none;
}

/* .mean-container .mean-nav ul li > a.mean-expand i { */
.mean-container .mean-nav ul li > span.mean-expand i {
  display: inline-block;
}

.mean-container .mean-nav > ul > li:first-child > a {
  border-top: 0;
}

/* .mean-container .mean-nav ul li a.mean-expand.mean-clicked { */
.mean-container .mean-nav ul li span.mean-expand.mean-clicked {
  color: var(--tp-common-white);
}

/* .mean-container .mean-nav ul li a.mean-expand.mean-clicked i { */
.mean-container .mean-nav ul li span.mean-expand.mean-clicked i {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  color: var(--tp-common-white);
}

/*----------------------------------------*/
/*  04. HERO CSS
/*----------------------------------------*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bs-hero-section {
    margin-right: 0;
    padding-top: 80px;
  }
}
@media (max-width: 767px) {
  .bs-hero-section {
    margin-right: 0;
    padding-top: 60px;
  }
}
.bs-hero-section__subtitle {
  font-size: 16px;
  font-weight: 400;
  padding: 6px 18px;
  border-radius: 18px;
  color: #000;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06);
}
.bs-hero-section__title {
  font-size: 80px;
  letter-spacing: -2px;
  line-height: 1.125;
  font-weight: 400;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .bs-hero-section__title {
    font-size: 70px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .bs-hero-section__title {
    font-size: 58px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bs-hero-section__title {
    font-size: 50px;
  }
}
@media (max-width: 767px) {
  .bs-hero-section__title {
    font-size: 45px;
  }
}
@media (max-width: 576px) {
  .bs-hero-section__title {
    font-size: 30px;
    letter-spacing: 0;
  }
}
.bs-hero-section p {
  font-size: 22px;
  font-weight: 400;
  line-height: 1.5;
  color: var(--tp-grey-3);
}
@media (max-width: 767px) {
  .bs-hero-section p {
    font-size: 18px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .da-hero-section {
    margin-right: 0;
    padding-top: 0;
  }
}
@media (max-width: 767px) {
  .da-hero-section {
    margin-right: 0;
    padding-top: 0;
  }
}
.da-hero-section__subtitle {
  font-size: 16px;
  border-radius: 18px;
  color: #000;
  color: var(--tp-grey-1);
}
.da-hero-section__subtitle b {
  color: #000;
}
.da-hero-section__title {
  font-size: 80px;
  letter-spacing: -3px;
  line-height: 1.125;
  font-weight: 400;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .da-hero-section__title {
    font-size: 70px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .da-hero-section__title {
    font-size: 58px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .da-hero-section__title {
    font-size: 50px;
  }
}
@media (max-width: 767px) {
  .da-hero-section__title {
    font-size: 45px;
  }
}
.da-hero-section p {
  font-size: 22px;
  font-weight: 400;
  line-height: 1.5;
  color: var(--tp-grey-3);
}
@media (max-width: 767px) {
  .da-hero-section p {
    font-size: 18px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bs-hero__img {
    padding-top: 40px;
  }
}
@media (max-width: 767px) {
  .bs-hero__img img {
    max-width: 100%;
  }
}
.bs-hero__subscribe-input input {
  font-size: 16px;
  width: 100%;
  height: 60px;
  padding-left: 50px;
  padding-right: 200px;
  border: 0;
  background-color: var(--tp-common-white);
  border-radius: 8px;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06);
}
@media (max-width: 576px) {
  .bs-hero__subscribe-input input {
    padding-right: 50px;
  }
}
.bs-hero__subscribe-input input::-webkit-input-placeholder {
  color: var(--tp-grey-3);
}
.bs-hero__subscribe-input input:-moz-placeholder {
  color: var(--tp-grey-3);
}
.bs-hero__subscribe-input input::-moz-placeholder {
  color: var(--tp-grey-3);
}
.bs-hero__subscribe-input input:-ms-input-placeholder {
  color: var(--tp-grey-3);
}
.bs-hero__subscribe-input i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 30px;
  color: var(--tp-theme-redical);
  display: inline-block;
}

@media (max-width: 767px) {
  .bs-hero-feature {
    padding-bottom: 50px;
  }
}
@media (max-width: 576px) {
  .bs-hero-feature span {
    display: block;
    margin-bottom: 10px;
  }
}
.bs-hero-feature__review {
  font-size: 14px;
  text-transform: uppercase;
  padding-right: 18px;
  color: var(--tp-grey-3);
}
.bs-hero-feature__review b {
  color: var(--tp-common-black);
}
.bs-hero-feature__review i {
  color: var(--tp-theme-cornblue);
  margin-right: 5px;
}
.bs-hero-feature__credit {
  font-size: 14px;
  text-transform: uppercase;
  padding-left: 18px;
  border-left: 2px solid var(--tp-border-1);
}
@media (max-width: 576px) {
  .bs-hero-feature__credit {
    padding-left: 0;
    border-left: 0;
  }
}
.bs-hero-feature__credit i {
  color: var(--tp-theme-redical);
  margin-right: 5px;
}

@media (max-width: 576px) {
  .bs-hero__subscribe-box .hero-submit-btn {
    position: static;
    margin-top: 50px;
    width: 100%;
  }
}

.app-hero__title {
  font-size: 80px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .app-hero__title {
    font-size: 70px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .app-hero__title {
    font-size: 58px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .app-hero__title {
    font-size: 50px;
  }
}
@media (max-width: 767px) {
  .app-hero__title {
    font-size: 40px;
  }
}
@media (max-width: 576px) {
  .app-hero__title {
    font-size: 35px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .app-hero__title br {
    display: none;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .app-hero-img-1 img {
    width: 100%;
  }
}

.tp-app-hero {
  box-shadow: 0px 14px 32px 0px rgba(0, 0, 0, 0.09);
}

@media (max-width: 767px) {
  .tp-app-hero-btns {
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .palystole-btn-wrapper {
    margin-right: 0;
  }
}

/* -------------------------------- 

Primary style

-------------------------------- */
.cd-title {
  position: relative;
}

.cd-words-wrapper {
  display: inline-block;
  position: relative;
}

.cd-words-wrapper b {
  display: inline-block;
  position: absolute;
  white-space: nowrap;
  left: 0;
  top: 0;
  color: var(--tp-theme-redical);
}

.da-hero-section__title .cd-words-wrapper b {
  display: inline-block;
  position: absolute;
  white-space: nowrap;
  left: 0;
  top: 0;
  background-image: linear-gradient(90deg, #5a6ef8, #fe7a8f);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bs-cta-section__title .cd-words-wrapper b {
  display: inline-block;
  position: absolute;
  white-space: nowrap;
  left: 0;
  top: 0;
  color: #c1cbd1;
}

.da-hero-section__title .cd-words-wrapper b {
  display: inline-block;
  position: absolute;
  white-space: nowrap;
  left: 0;
  top: 0;
  color: #c1cbd1;
}

.cd-words-wrapper b.is-visible {
  position: relative;
}

.no-js .cd-words-wrapper b {
  opacity: 0;
}

.no-js .cd-words-wrapper b.is-visible {
  opacity: 1;
}

/* -------------------------------- 

xloading-bar 

-------------------------------- */
.cd-headline.loading-bar .cd-words-wrapper {
  overflow: hidden;
  vertical-align: top;
}

.cd-headline.loading-bar .cd-words-wrapper::after {
  /* loading bar */
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 5px;
  width: 0;
  background: var(--tp-theme-redical);
  z-index: 2;
  -webkit-transition: width 0.3s -0.1s;
  -moz-transition: width 0.3s -0.1s;
  transition: width 0.3s -0.1s;
}

.bs-cta-section__title.loading-bar .cd-words-wrapper::after {
  /* loading bar */
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 5px;
  width: 0;
  background: #c1cbd1;
  z-index: 2;
  -webkit-transition: width 0.3s -0.1s;
  -moz-transition: width 0.3s -0.1s;
  transition: width 0.3s -0.1s;
}

.da-hero-section__title.cd-headline.loading-bar .cd-words-wrapper::after {
  /* loading bar */
  content: "";
  position: absolute;
  left: 0;
  bottom: 5px;
  height: 10px;
  width: 0;
  background-color: #e2e4ff;
  z-index: -2;
  -webkit-transition: width 0.3s -0.1s;
  -moz-transition: width 0.3s -0.1s;
  transition: width 0.3s -0.1s;
}

.cd-headline.loading-bar .cd-words-wrapper.is-loading::after {
  width: 100%;
  -webkit-transition: width 3s;
  -moz-transition: width 3s;
  transition: width 3s;
}

.cd-headline.loading-bar b {
  padding: 0px 1px;
  top: 0;
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;
  transition: opacity 0.3s;
  font-weight: 400;
}

.cd-headline.loading-bar b.is-visible {
  opacity: 1;
  top: 0;
  color: var(--tp-theme-redical);
}

.bs-cta-section__title.loading-bar b.is-visible {
  opacity: 1;
  top: 0;
  color: #c1cbd1;
}

.da-hero-section__title.loading-bar b.is-visible {
  opacity: 1;
  top: 0;
  color: var(--tp-common-black);
  display: inline-block;
}

.hero-business-chart {
  position: absolute;
  top: 31%;
  left: 10%;
  box-shadow: 0px 30px 40px 0px rgba(0, 0, 0, 0.06);
  animation: moving 5s ease-in-out infinite;
}

.hero-business-pie {
  position: absolute;
  top: 52%;
  right: -122px;
  box-shadow: 0px 30px 40px 0px rgba(0, 0, 0, 0.06);
  animation: moving 5s ease-in-out infinite;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .hero-business-pie {
    right: -50px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero-business-pie {
    right: -30px;
  }
}
@media (max-width: 767px),
  only screen and (min-width: 576px) and (max-width: 767px),
  only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-business-pie {
    right: 0;
  }
}
@media (max-width: 576px) {
  .hero-business-pie {
    display: none;
  }
}

@keyframes moving {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}
.tp-da-hero-img {
  position: relative;
}
.tp-da-hero-img::after {
  content: "";
  width: 30px;
  height: 305px;
  position: absolute;
  bottom: 0;
  right: 0;
  display: inline-block;
  background-color: black;
  border-radius: 0 8px 0 0;
}
@media (max-width: 576px) {
  .tp-da-hero-img::after {
    height: 105px;
  }
}

.da-hero-black-box {
  font-size: 18px;
  padding: 30px;
  border-radius: 0px 0 8px 8px;
  color: var(--tp-common-white);
  width: 520px;
}
@media (max-width: 576px) {
  .da-hero-black-box {
    width: 230px;
  }
}

@media (max-width: 767px),
  only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-da-hero-img img {
    max-width: 100%;
  }
}

@media (max-width: 767px),
  only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 1200px) and (max-width: 1399px) {
  .da-about-img img {
    max-width: 100%;
  }
}

.mp-hero-img img {
  max-width: 100%;
}

.mp-hero-left {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 20px 20px 20px 40px;
  border-radius: 0 70px 70px 0;
}
@media (max-width: 767px) {
  .mp-hero-left {
    padding: 10px 10px 10px 20px;
  }
}
.mp-hero-left__info {
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  text-transform: capitalize;
}
@media (max-width: 767px) {
  .mp-hero-left__info {
    font-size: 12px;
    margin-right: 20px;
  }
}
.mp-hero-left__icon {
  font-size: 45px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #fff;
  text-align: center;
  line-height: 100px;
}
@media (max-width: 767px) {
  .mp-hero-left__icon {
    font-size: 25px;
    width: 50px;
    height: 50px;
    line-height: 50px;
  }
}

.mp-hero-right {
  position: absolute;
  top: 30%;
  right: 15%;
}
@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px) {
  .mp-hero-right {
    right: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mp-hero-right {
    right: 0;
    top: 50%;
  }
}
@media (max-width: 767px) {
  .mp-hero-right {
    top: 60%;
    right: 0;
  }
}

.mb-hero-title {
  color: #000;
  font-size: 40px;
  font-weight: 400;
  padding: 3px 15px;
}
@media (max-width: 767px) {
  .mb-hero-title {
    font-size: 30px;
  }
}
.mb-hero-title.white-border {
  border: 2px solid #fff;
  display: inline-block;
  border-radius: 50%;
}

.law-hero-area {
  padding-top: 260px;
  padding-bottom: 175px;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.law-hero-area::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    90deg,
    rgb(0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  display: inline-block;
  z-index: 1;
  opacity: 60%;
}
.law-hero-area::after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 30%;
  background-image: linear-gradient(
    0deg,
    rgba(80, 34, 60, 0) 0%,
    rgb(80, 34, 60) 100%
  );
  display: inline-block;
  z-index: 1;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .law-hero-area {
    margin-top: 0;
    padding-top: 200px;
    padding-bottom: 100px;
  }
}
@media (max-width: 767px) {
  .law-hero-area {
    margin-top: 0;
    padding-top: 150px;
    padding-bottom: 100px;
  }
}
.law-hero__content {
  position: relative;
  z-index: 2;
}
.law-hero__content-subtitle {
  color: #fff;
  font-size: 24px;
  display: inline-block;
}
.law-hero__content-title {
  font-size: 100px;
  color: #fff;
  line-height: 1;
  letter-spacing: -3px;
  text-transform: uppercase;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .law-hero__content-title {
    font-size: 90px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .law-hero__content-title {
    font-size: 70px;
    line-height: 1.4;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .law-hero__content-title {
    font-size: 50px;
    line-height: 1.4;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .law-hero__content-title {
    font-size: 50px;
  }
}
@media (max-width: 767px) {
  .law-hero__content-title {
    font-size: 35px;
    letter-spacing: 1px;
    line-height: 1.4;
  }
  .law-hero__content-title br {
    display: none;
  }
}
.law-hero__content p {
  color: #fefefe;
  font-size: 20px;
}
@media (max-width: 767px) {
  .law-hero__content p {
    font-size: 18px;
  }
}
@media (max-width: 767px),
  only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 1200px) and (max-width: 1399px) {
  .law-hero__content p br {
    display: none;
  }
}

.law-hero__imgs {
  position: relative;
  z-index: 1;
}

.ca-hero-area {
  padding-top: 400px;
  padding-bottom: 300px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ca-hero-area {
    padding-top: 200px;
    padding-bottom: 150px;
  }
}
@media (max-width: 767px) {
  .ca-hero-area {
    padding-top: 200px;
    padding-bottom: 150px;
  }
}
.ca-hero-area::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.102);
  width: 100%;
  height: 100%;
  display: inline-block;
}
.ca-hero-area .law-hero__content-title {
  font-size: 120px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .ca-hero-area .law-hero__content-title {
    font-size: 90px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ca-hero-area .law-hero__content-title {
    font-size: 70px;
    line-height: 1.4;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ca-hero-area .law-hero__content-title {
    font-size: 60px;
    line-height: 1.4;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .ca-hero-area .law-hero__content-title {
    font-size: 60px;
  }
}
@media (max-width: 767px) {
  .ca-hero-area .law-hero__content-title {
    font-size: 45px;
    letter-spacing: 1px;
    line-height: 1.4;
  }
  .ca-hero-area .law-hero__content-title br {
    display: none;
  }
}
.ca-hero-area .tp-grd-btn {
  border-radius: 0;
  text-transform: capitalize;
}

.ha-hero-area {
  position: relative;
  padding-top: 525px;
  padding-bottom: 120px;
  margin: 10px;
  margin-bottom: 0;
  background-size: cover;
  background-position: center;
}
.ha-hero-area::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.502);
  display: inline-block;
  z-index: 1;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ha-hero-area {
    padding-top: 200px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ha-hero-area {
    padding-top: 150px;
  }
}
@media (max-width: 767px) {
  .ha-hero-area {
    margin: 0;
    padding-top: 100px;
  }
}
.ha-hero__content {
  position: relative;
  z-index: 2;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ha-hero__content {
    padding-top: 120px;
  }
}
@media (max-width: 767px) {
  .ha-hero__content {
    padding-top: 100px;
  }
}
.ha-hero__content-subtitle {
  color: #fff;
  font-size: 24px;
  display: inline-block;
}
@media (max-width: 767px) {
  .ha-hero__content-subtitle {
    font-size: 20px;
  }
}
.ha-hero__content-title {
  font-size: 80px;
  color: #fff;
  line-height: 1;
  letter-spacing: -3px;
  text-transform: uppercase;
  line-height: 1.1;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .ha-hero__content-title {
    font-size: 90px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ha-hero__content-title {
    font-size: 70px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ha-hero__content-title {
    font-size: 50px;
  }
}
@media (max-width: 767px) {
  .ha-hero__content-title {
    font-size: 35px;
    letter-spacing: 1px;
  }
  .ha-hero__content-title br {
    display: none;
  }
}
.ha-hero__content p {
  color: #fefefe;
  font-size: 20px;
}
@media (max-width: 767px) {
  .ha-hero__content p {
    font-size: 18px;
  }
}
@media (max-width: 767px),
  only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 1200px) and (max-width: 1399px) {
  .ha-hero__content p br {
    display: none;
  }
}

.seo-hero__content {
  position: relative;
  z-index: 2;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .seo-hero__content {
    padding-top: 120px;
  }
}
@media (max-width: 767px) {
  .seo-hero__content {
    padding-top: 100px;
  }
}
.seo-hero__content-subtitle {
  color: #fff;
  font-size: 24px;
  display: inline-block;
}
.seo-hero__content-title {
  font-size: 80px;
  color: var(--tp-theme-seagreen);
  line-height: 1;
  letter-spacing: -3px;
  text-transform: uppercase;
  line-height: 1.1;
}
.seo-hero__content-title span {
  color: var(--tp-theme-picton);
  position: relative;
}
.seo-hero__content-title span::before {
  position: absolute;
  bottom: 10px;
  content: "";
  background-color: #d2e9f6;
  width: 100%;
  height: 10px;
  display: inline-block;
  z-index: -1;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .seo-hero__content-title {
    font-size: 90px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .seo-hero__content-title {
    font-size: 70px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .seo-hero__content-title {
    font-size: 50px;
  }
}
@media (max-width: 767px) {
  .seo-hero__content-title {
    font-size: 35px;
    letter-spacing: 1px;
  }
  .seo-hero__content-title br {
    display: none;
  }
}
.seo-hero__content p {
  color: #fefefe;
  font-size: 20px;
}
@media (max-width: 767px) {
  .seo-hero__content p {
    font-size: 18px;
  }
}
@media (max-width: 767px),
  only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 1200px) and (max-width: 1399px) {
  .seo-hero__content p br {
    display: none;
  }
}

.seo-hero-img img {
  max-width: 100%;
}

@media (max-width: 767px),
  only screen and (min-width: 768px) and (max-width: 991px) {
  .seo-hero-area {
    padding-top: 50px;
    margin-top: 0;
  }
}

@media (max-width: 767px) {
  .seo-hero-btn-wapper {
    margin-right: 0;
    display: block;
    margin-bottom: 30px;
  }
}

.tp-inner-page-hero span {
  color: var(--tp-grey-1);
}
.tp-inner-page-hero span b {
  color: var(--tp-common-black);
  padding-right: 5px;
}

/* line animation css - start
================================================== */
.line_wrap {
  top: 0;
  left: 51%;
  bottom: 0px;
  width: 70%;
  z-index: -1;
  display: block;
  position: fixed;
  transform: translateX(-50%);
  border-left: 1px solid rgba(0, 0, 0, 0.07);
}

.line_wrap:before {
  width: 1px;
  left: -1px;
  content: "";
  height: 100px;
  position: absolute;
  animation: scroll1 15s ease-out infinite;
  background-image: linear-gradient(0deg, #000000, transparent);
}

.line_wrap .line_item {
  width: 32.33%;
  float: left;
  height: 100%;
  position: relative;
  display: inline-block;
  border-right: 1px solid rgba(0, 0, 0, 0.07);
}

.line_wrap .line_item:before {
  width: 1px;
  right: -1px;
  content: "";
  height: 100px;
  position: absolute;
}

.line_wrap .line_item:nth-child(odd):before {
  animation: scroll2 15s ease-out infinite;
  /*! background-image: linear-gradient(0deg, transparent, #000000); */
}

.line_wrap .line_item:nth-child(even):before {
  animation: scroll1 15s ease-out infinite;
  background-image: linear-gradient(0deg, #000000, transparent);
}

.line_wrap.line_white {
  border-left: 1px solid rgba(255, 255, 255, 0.07);
}

.line_wrap.line_white:before {
  background-image: linear-gradient(0deg, #ffffff, transparent);
}

.line_wrap.line_white .line_item {
  border-right: 1px solid rgba(255, 255, 255, 0.07);
}

.line_wrap.line_white .line_item:nth-child(odd):before {
  background-image: linear-gradient(0deg, transparent, #ffffff);
}

.line_wrap.line_white .line_item:nth-child(even):before {
  background-image: linear-gradient(0deg, #ffffff, transparent);
}

/*----------------------------------------*/
/*  05. SLIDER CSS
/*----------------------------------------*/
.it-slider-height {
  min-height: 880px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .it-slider-height {
    min-height: 767px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .it-slider-height {
    min-height: 600px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .it-slider-height {
    min-height: 500px;
  }
}
@media (max-width: 767px) {
  .it-slider-height {
    min-height: 480px;
  }
}

.tp-it-slider {
  position: relative;
  background-blend-mode: multiply;
  background-color: #1fc2ef;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .it-slider__content {
    padding-top: 120px;
  }
}
@media (max-width: 767px) {
  .it-slider__content {
    padding-top: 100px;
  }
}
.it-slider__content-title {
  font-size: 100px;
  color: var(--tp-common-white);
  line-height: 1;
  letter-spacing: -3px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .it-slider__content-title {
    font-size: 90px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .it-slider__content-title {
    font-size: 70px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .it-slider__content-title {
    font-size: 50px;
  }
}
@media (max-width: 767px) {
  .it-slider__content-title {
    font-size: 35px;
    letter-spacing: 1px;
  }
}
.it-slider__content p {
  color: #fefefe;
  font-size: 20px;
}
@media (max-width: 767px) {
  .it-slider__content p {
    font-size: 18px;
  }
}
@media (max-width: 767px),
  only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 1200px) and (max-width: 1399px) {
  .it-slider__content p br {
    display: none;
  }
}

.orange-chat-color span i {
  color: var(--tp-theme-orange);
}

.it-slider-img {
  padding-top: 117px;
  margin-left: -10px;
  margin-right: -15px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .it-slider-img {
    padding-top: 50px;
    margin-left: 0px;
    margin-right: 0px;
  }
}

.it-slder-quite {
  border-radius: 10px;
  padding-right: 30px;
  padding-bottom: 20px;
  padding-top: 20px;
  padding-left: 20px;
  background-color: white;
  position: absolute;
  bottom: 30%;
  bottom: 35%;
  right: -115px;
  animation: moving 5s ease-in-out infinite;
}
@media only screen and (min-width: 1300px) and (max-width: 1600px) {
  .it-slder-quite {
    right: 50px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 992px) and (max-width: 1199px) {
  .it-slder-quite {
    right: 50px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .it-slder-quite {
    right: 0px;
  }
}
.it-slder-quite::after {
  position: absolute;
  content: "\f10d";
  font-family: var(--tp-ff-fontawesome);
  bottom: -20px;
  right: -20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  line-height: 40px;
  text-align: center;
  background-color: var(--tp-theme-orange);
  color: var(--tp-common-white);
}
.it-slder-quite b {
  display: block;
  font-size: 18px;
  color: var(--tp-theme-vogue);
  margin-bottom: 10px;
}
.it-slder-quite span {
  color: var(--tp-theme-vogue);
}
.it-slder-quite span i {
  color: var(--tp-theme-orange);
  font-style: normal;
  font-weight: 400;
}

.ptg-slider-item {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.ptg-slider-item::after {
  content: "";
  position: absolute;
  background-color: rgba(0, 0, 0, 0.4);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  z-index: -1;
}

.ptg-slider-height {
  min-height: 800px;
  width: 100%;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ptg-slider-height {
    min-height: 600px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ptg-slider-height {
    min-height: 570px;
  }
}
@media (max-width: 767px) {
  .ptg-slider-height {
    min-height: 470px;
  }
}

.tp-ptg-slider-content {
  z-index: 11;
}

.tp-ptg-slider-title {
  font-size: 120px;
  color: #fff;
  text-transform: uppercase;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-ptg-slider-title {
    font-size: 70px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-ptg-slider-title {
    font-size: 55px;
  }
}
@media (max-width: 767px) {
  .tp-ptg-slider-title {
    font-size: 35px;
  }
}
.tp-ptg-slider-title span {
  position: relative;
}
.tp-ptg-slider-title span::before {
  content: "";
  background-image: -moz-linear-gradient(
    0deg,
    rgb(90, 110, 248) 0%,
    rgb(254, 122, 143) 100%
  );
  background-image: -webkit-linear-gradient(
    0deg,
    rgb(90, 110, 248) 0%,
    rgb(254, 122, 143) 100%
  );
  background-image: -ms-linear-gradient(
    0deg,
    rgb(90, 110, 248) 0%,
    rgb(254, 122, 143) 100%
  );
  position: absolute;
  bottom: 20px;
  left: 0px;
  width: 100%;
  height: 10px;
  z-index: -1;
}
@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .tp-ptg-slider-title span::before {
    bottom: 10px;
  }
}
@media (max-width: 767px) {
  .tp-ptg-slider-title span::before {
    bottom: 5px;
    width: 100%;
    height: 5px;
  }
}

.ptg-slider-social {
  font-size: 16px;
  font-weight: 400;
  position: absolute;
  top: 50%;
  left: 40px;
  color: white;
  text-transform: uppercase;
  writing-mode: vertical-lr;
  transform: rotate(180deg) translateY(50%);
}
@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .ptg-slider-social {
    font-size: 12px;
  }
}
@media (max-width: 767px) {
  .ptg-slider-social {
    display: none;
  }
}
.ptg-slider-social i {
  transform: rotate(90deg);
  padding: 10px 0;
}

.ptg-contact-slider-info {
  font-size: 16px;
  font-weight: 400;
  position: absolute;
  top: 50%;
  right: 40px;
  color: white;
  text-transform: uppercase;
  writing-mode: vertical-lr;
  transform: rotate(180deg) translateY(50%);
}
@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .ptg-contact-slider-info {
    font-size: 12px;
  }
}
@media (max-width: 767px) {
  .ptg-contact-slider-info {
    display: none;
  }
}

.ptg-spt {
  margin: 20px 0;
}

@media (max-width: 767px) {
  .mp-hero-area {
    padding-bottom: 60px;
  }
}

.ptg-sldider-dot {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  line-height: 0;
  text-align: center;
  z-index: 2;
}
.ptg-sldider-dot .swiper-pagination-bullet {
  height: 20px;
  width: 2px;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 0;
  opacity: 1;
  margin: 0 15px !important;
  position: relative;
  color: white;
}
.ptg-sldider-dot .swiper-pagination-bullet button {
  font-size: 16px;
  font-weight: 400;
  position: absolute;
  top: -15px;
  left: -10px;
  opacity: 0;
}
.ptg-sldider-dot .swiper-pagination-bullet-active {
  background-color: #fff;
  height: 40px;
}
.ptg-sldider-dot .swiper-pagination-bullet-active button {
  opacity: 1;
}

/* --------------------new-------------------- */
.ptg-slldider-dot {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  line-height: 0;
  text-align: center;
  z-index: 2;
}
.ptg-slldider-dot .swiper-pagination-bullet {
  height: 20px;
  width: 2px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 0;
  opacity: 1;
  margin: 0 15px !important;
  position: relative;
  color: black;
}
.ptg-slldider-dot .swiper-pagination-bullet button {
  font-size: 16px;
  font-weight: 400;
  position: absolute;
  top: -15px;
  left: -10px;
  opacity: 0;
}
.ptg-slldider-dot .swiper-pagination-bullet-active {
  background-color: black;
  height: 40px;
}
.ptg-slldider-dot .swiper-pagination-bullet-active button {
  opacity: 1;
}
/* ---------------------------new ----------------------------- */

@media (max-width: 767px) {
  .swiper--top {
    padding: 20px;
  }
}
.swiper--top.swiper-container {
  overflow: visible;
}

.mp-marque-slider {
  position: absolute;
  bottom: 0px;
  width: 100%;
}
@media (max-width: 767px) {
  .mp-marque-slider {
    bottom: -60px;
  }
}
.mp-marque-slider p {
  font-size: 200px;
  font-weight: 400;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .mp-marque-slider p {
    font-size: 150px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .mp-marque-slider p {
    font-size: 130px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mp-marque-slider p {
    font-size: 100px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mp-marque-slider p {
    font-size: 60px;
  }
}
@media (max-width: 767px) {
  .mp-marque-slider p {
    font-size: 30px;
  }
}
.mp-marque-slider p span {
  padding-left: 168px;
}
.mp-marque-slider .swiper-wrapper {
  -webkit-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
}

.tp-gl-silder,
.tp-gl-silder-2 {
  margin-bottom: 30px;
}
.tp-gl-silder .swiper-wrapper,
.tp-gl-silder-2 .swiper-wrapper {
  -webkit-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
}

.tp-mp-slider-item {
  width: 600px;
}

/*----------------------------------------*/
/*  06. ABOUT CSS
/*----------------------------------------*/
@media only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .tp-bs-about-info {
    margin-left: 0;
  }
}

.tp-bs-about img {
  border-radius: 8px;
}
@media (max-width: 767px) {
  .tp-bs-about {
    padding-top: 160px;
    padding-bottom: 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-bs-img {
    margin-left: 0;
    margin-bottom: 150px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-bs-img {
    margin-left: 50px;
  }
}
@media (max-width: 767px) {
  .tp-bs-img {
    margin-left: 0;
    margin-bottom: 120px;
  }
}
@media (max-width: 576px) {
  .tp-bs-img img {
    max-width: 100%;
  }
}

.tp-bs-sm-top {
  position: absolute;
  left: 0;
  top: -80px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-bs-sm-top {
    left: 120px;
    top: -75px;
  }
}
@media (max-width: 576px) {
  .tp-bs-sm-top {
    left: 20px;
  }
}

.tp-bs-sm-button {
  position: absolute;
  right: 35px;
  bottom: 0;
  transform: translateY(85px);
}
@media (max-width: 576px) {
  .tp-bs-sm-button {
    left: 10px;
  }
}

.tp-bs-bg-circle {
  position: absolute;
  top: -38px;
  right: 100px;
  z-index: -1;
  -webkit-animation: moving 5s ease-in-out infinite;
  animation: moving 5s ease-in-out infinite;
}

.tp-bs-about-fea {
  position: relative;
  border-bottom: 1px solid var(--tp-border-3);
}
.tp-bs-about-fea li {
  font-size: 18px;
  font-weight: 600;
  color: var(--tp-common-black);
  padding-left: 50px;
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .tp-bs-about-fea li {
    font-size: 16px;
  }
}
.tp-bs-about-fea li i {
  color: var(--tp-theme-cornblue);
  border-radius: 50%;
  background-color: rgba(108, 96, 254, 0.102);
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  font-size: 18px;
  margin-right: 20px;
  margin-bottom: 15px;
  position: absolute;
  left: 0;
}

.tp-da-about-fea {
  position: relative;
}
.tp-da-about-fea li {
  font-size: 18px;
  font-weight: 600;
  color: var(--tp-common-black);
  padding-left: 50px;
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .tp-da-about-fea li {
    font-size: 16px;
  }
}
.tp-da-about-fea li span {
  color: var(--tp-theme-cornblue);
  border-radius: 50%;
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  font-size: 18px;
  margin-right: 20px;
  margin-bottom: 15px;
  position: absolute;
  left: 0;
  background-color: var(--tp-common-white);
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.1);
}
.tp-da-about-fea li span i {
  font-size: 18px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.06);
  background-image: -webkit-linear-gradient(180deg, #fe7a8f, #5a6ef8);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.tp-app-about-fea {
  position: relative;
}
.tp-app-about-fea li {
  font-size: 18px;
  font-weight: 600;
  color: var(--tp-theme-mirage);
  padding-left: 30px;
  margin-bottom: 15px;
}
@media (max-width: 767px) {
  .tp-app-about-fea li {
    font-size: 16px;
  }
}
.tp-app-about-fea li span {
  color: var(--tp-theme-redical);
  position: absolute;
  left: 0;
}

.tp-bs-contact-btn span {
  color: var(--tp-grey-3);
  margin-right: 15px;
  font-weight: 400;
}
.tp-bs-contact-btn span i {
  border: 2px solid #f0f0f0;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 54px;
  color: var(--tp-theme-redical);
}
.tp-bs-contact-btn a {
  font-size: 20px;
  color: var(--tp-common-black);
}
.tp-bs-contact-btn a:hover {
  color: var(--tp-theme-redical);
}

.tp-it-author-info p {
  color: var(--tp-theme-vogue);
  font-size: 20px;
  margin-bottom: 25px;
}
@media (max-width: 767px) {
  .tp-it-author-info p {
    font-size: 18px;
  }
}

.it-about-name {
  padding-left: 20px;
}
.it-about-name span {
  display: block;
  color: var(--tp-grey-3);
  text-transform: capitalize;
}
.it-about-name b {
  font-size: 20px;
  color: var(--tp-theme-vogue);
}

.it-ab-cirlce-logo {
  position: absolute;
  left: -60px;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--tp-common-white);
  width: 120px;
  height: 120px;
  text-align: center;
  line-height: 120px;
  border-radius: 50%;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06);
  -webkit-animation: rotate 8s linear infinite;
  animation: rotate 8s linear infinite;
  z-index: 9;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .it-ab-cirlce-logo {
    left: -30px;
  }
}
@media (max-width: 767px) {
  .it-ab-cirlce-logo {
    display: none;
  }
}
.it-ab-cirlce-logo img {
  width: unset;
}

.tp-app-about-img {
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.08);
}
@media only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .tp-app-about-img {
    margin-bottom: 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .tp-app-about-img img {
    width: 100%;
  }
}

.app-chart-img {
  position: absolute;
  top: 40%;
  right: 80px;
  animation: moving 5s ease-in-out infinite;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
}
@media (max-width: 576px) {
  .app-chart-img {
    display: none;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .ptg-about-info {
    padding-top: 0;
  }
}

.ptg-about-img {
  margin-left: -130px;
  position: relative;
}
@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .ptg-about-img {
    margin-left: 0;
  }
}
.ptg-about-img::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  left: 0;
  top: 0;
  display: inline-block;
  background-image: -moz-linear-gradient(
    0deg,
    rgb(0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  background-image: -webkit-linear-gradient(
    0deg,
    rgb(0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  background-image: -ms-linear-gradient(
    0deg,
    rgb(0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  opacity: 0.502;
}
.ptg-about-img img {
  border-radius: 10px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .ptg-about-img img {
    max-width: 100%;
  }
}

.pta-about-circle {
  position: absolute;
  right: 0;
  bottom: 100px;
  animation: rotate 8s linear infinite;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pta-about-circle {
    right: -20px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px) {
  .pta-about-circle img {
    max-width: 100%;
  }
}
@media (max-width: 767px) {
  .pta-about-circle img {
    display: none;
  }
}

.ptg-about-content {
  padding-bottom: 30px;
  border-bottom: 1px solid var(--tp-border-10);
}
.ptg-about-content-2 {
  border-bottom: 1px solid var(--tp-border-1);
}
@media (max-width: 576px) {
  .ptg-about-content {
    flex-direction: column;
  }
}

@media (max-width: 767px) {
  .about-step-info {
    margin-right: 0;
  }
}
.about-step-info h3 {
  color: white;
  font-size: 20px;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.about-step-info p {
  font-size: 16px;
  color: var(--tp-grey-4);
  line-height: 1.7;
}
.about-step-info p a {
  color: #fffefe;
  text-decoration: underline;
}
.about-step-info p a:hover {
  opacity: 0.8;
}
.about-step-info-2 h3 {
  color: #000;
  font-size: 20px;
  text-transform: capitalize;
  margin-bottom: 20px;
}
.about-step-info-2 p {
  font-size: 16px;
  color: var(--tp-grey-4);
  line-height: 1.6;
}
.about-step-info-2 p a {
  color: black;
  text-decoration: underline;
}
.about-step-info-2 p a:hover {
  opacity: 0.8;
}

.law-about-left-title {
  display: flex;
  align-items: center;
  margin-right: 8%;
  padding: 20px 30px;
  border-radius: 6px;
}
.law-about-left-title span {
  display: inline-block;
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  margin-right: 15px;
}
@media (max-width: 767px) {
  .law-about-left-title span {
    margin-right: 5px;
  }
}
.law-about-left-title span i {
  font-size: 40px;
  line-height: 1;
  margin-right: 10px;
}
@media (max-width: 767px) {
  .law-about-left-title {
    margin: 0;
    margin-bottom: 30px;
  }
}

.law-icon {
  position: absolute;
  bottom: 60px;
  left: 0;
  right: 0;
  text-align: center;
}

@media (max-width: 767px) {
  .law-about-img-1 img {
    max-width: 100%;
    margin-bottom: 30px;
  }
}

.law-icon-info {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  text-align: center;
  color: white;
}
.law-icon-info span b {
  display: block;
  font-size: 100px;
  line-height: 1;
}

.law-about-img-2 {
  margin-left: -30px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .law-about-img-2 {
    margin-left: -53px;
    margin-top: 58px;
  }
}
@media (max-width: 767px) {
  .law-about-img-2 {
    display: none;
  }
}

.law-ab-author {
  padding: 20px;
  padding-right: 30px;
  border-radius: 6px;
}

.law-ab-author-head span {
  color: #b192a4;
}
.law-ab-author-head span.cornblue-text-color {
  color: var(--tp-theme-cornblue);
}

.law-ab-auther-name {
  font-size: 20px;
  color: #fff;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .law-ab-auther-name {
    font-size: 15px;
    text-transform: capitalize;
  }
}

.red-dot {
  border-radius: 3px;
  position: absolute;
  bottom: 70px;
  right: 25%;
  width: 10px;
  height: 10px;
  background-color: var(--tp-theme-red);
}
.red-dot.law-ab-dot {
  background-color: var(--tp-theme-redical);
  opacity: 90%;
}

.law-ab-fea-title {
  font-size: 18px;
  line-height: 1.6;
}

.low-ab-feature {
  padding: 20px 0;
  position: relative;
}
.low-ab-feature::before {
  position: absolute;
  bottom: 0;
  content: "";
  left: 0;
  width: 60%;
  height: 4px;
  display: inline-block;
  background-color: var(--tp-theme-red);
  z-index: 1;
}
.low-ab-feature::after {
  position: absolute;
  bottom: 0;
  content: "";
  left: 0;
  width: 100%;
  height: 4px;
  display: inline-block;
  background-color: #eeeeee;
}
@media (max-width: 576px) {
  .low-ab-feature {
    margin-bottom: 30px;
  }
}

.law-cta-contact-wrapper > span {
  color: var(--tp-theme-wine);
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
}
.law-cta-contact-wrapper h1 {
  color: var(--tp-theme-red);
  font-size: 50px;
}
@media (max-width: 767px) {
  .law-cta-contact-wrapper h1 {
    font-size: 26px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ca-about-info-wrapper {
    margin-top: 50px;
  }
}
@media (max-width: 767px) {
  .ca-about-info-wrapper {
    margin: 0;
  }
}

@media (max-width: 767px) {
  .ca-about-img {
    margin-bottom: 100px;
  }
}
@media (max-width: 767px) {
  .ca-about-img img {
    max-width: 100%;
  }
}

.ca-about-circle {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  animation: rotate 15s linear infinite;
}
@media (max-width: 767px) {
  .ca-about-circle {
    display: none;
  }
}

.ca-about-info__content-title {
  font-size: 18px;
  line-height: 1.6;
  font-weight: 400;
}
.ca-about-info__content span {
  color: var(--tp-grey-4);
}
@media (max-width: 767px) {
  .ca-about-info__thumb {
    margin-bottom: 30px;
  }
}

.ca-about-cta span {
  color: var(--tp-grey-4);
}
.ca-about-cta a {
  font-size: 20px;
  font-weight: 400;
  background-image: linear-gradient(90deg, #5a6ef8, #fe7a8f);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.tp-ha-about .tp-section__title {
  letter-spacing: -3px;
}
@media (max-width: 767px),
  only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-ha-about .tp-section__title {
    letter-spacing: 0;
  }
}

.ha-about-img {
  margin-left: -35px;
}
@media (max-width: 767px),
  only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 1200px) and (max-width: 1399px) {
  .ha-about-img img {
    max-width: 100%;
  }
}

.ha-about-circle {
  position: absolute;
  top: 20%;
  left: 0;
  right: 0;
  text-align: center;
}
.ha-about-circle img {
  animation: rotate 8s linear infinite;
}

.tp-ha-about-fea {
  position: relative;
}
.tp-ha-about-fea li {
  font-size: 18px;
  font-weight: 600;
  color: var(--tp-common-black);
  padding-left: 50px;
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .tp-ha-about-fea li {
    font-size: 16px;
  }
}
.tp-ha-about-fea li span {
  color: var(--tp-theme-cornblue);
  border-radius: 50%;
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  font-size: 18px;
  margin-right: 20px;
  margin-bottom: 15px;
  position: absolute;
  left: 0;
  background-color: var(--tp-common-white);
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.1);
}
.tp-ha-about-fea li span i {
  font-size: 18px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.06);
  color: var(--tp-theme-mandalay);
}

.seo-about-img {
  margin-left: -200px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 1400px) and (max-width: 1600px) {
  .seo-about-img {
    margin-left: -50px;
  }
}
@media (max-width: 767px),
  only screen and (min-width: 768px) and (max-width: 991px) {
  .seo-about-img {
    margin-left: 0;
  }
}
.seo-about-img img {
  max-width: 100%;
}

@media (max-width: 767px),
  only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-seo-about-info {
    margin-right: 0;
  }
}
.tp-seo-about-info .tp-ha-about-fea li {
  color: var(--tp-theme-seagreen);
}
.tp-seo-about-info .tp-ha-about-fea li span i {
  color: var(--tp-theme-lochmara);
}

.tp-seo-about-cta .call-icon {
  color: var(--tp-theme-lochmara);
  font-size: 18px;
  height: 60px;
  width: 60px;
  line-height: 60px;
  background-color: #fff;
  text-align: center;
  border-radius: 50%;
  margin-right: 15px;
}
.tp-seo-about-cta p span {
  color: var(--tp-grey-4);
}
.tp-seo-about-cta p b {
  color: var(--tp-theme-seagreen);
  font-size: 20px;
  font-weight: 400;
}

.tp-sv-about-cta .call-icon {
  color: #fff;
  font-size: 18px;
  height: 60px;
  width: 60px;
  line-height: 60px;
  background-color: var(--tp-theme-cornblue);
  text-align: center;
  border-radius: 50%;
  margin-right: 15px;
}
.tp-sv-about-cta p span {
  color: var(--tp-grey-4);
}
.tp-sv-about-cta p b {
  color: var(--tp-theme-cornblue);
  font-size: 20px;
  font-weight: 400;
}
.tp-sv-about-cta p b :hover {
  color: var(--tp-theme-redical);
}

.ab-fact-item {
  position: relative;
  z-index: 2;
  padding-right: 40px;
}
.ab-fact-item span {
  font-size: 60px;
  font-weight: 400;
  color: #fff;
}
.ab-fact-item span::after {
  content: "+";
}
.ab-fact-item__title {
  font-size: 24px;
  color: #fff;
}
.ab-fact-item__title a {
  background-image: linear-gradient(#fff, #fff), linear-gradient(#fff, #fff);
  display: inline;
  background-size: 0% 1px, 0 1px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.4s linear;
}
.ab-fact-item__title a:hover {
  background-size: 0% 1px, 100% 1px;
}
.ab-fact-item p {
  font-size: 16px;
  color: #fff;
}
.ab-fact-item-2 {
  text-align: center;
}
.ab-fact-item-2 span {
  color: #000;
}
.ab-fact-item-2 span::after {
  content: "+";
  color: var(--tp-theme-redical);
}
.ab-fact-item-2 p {
  font-size: 18px;
  color: var(--tp-grey-1);
}

.tp-ab-fact-area {
  background-size: cover;
  background-repeat: no-repeat;
}

.fact-overlay::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--tp-theme-cornblue);
  opacity: 0.941;
  z-index: 1;
}

.about-sv-img img {
  max-width: 100%;
}

.ab-title-section-bg {
  margin-bottom: -80px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 1200px) and (max-width: 1399px) {
  .about-page-about-img img {
    max-width: 100%;
  }
}

@media (max-width: 767px) {
  .about-page-ab p {
    padding-right: 0;
  }
}

.tp-about__me {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
@media (max-width: 767px) {
  .tp-about__me {
    padding-bottom: 120px;
  }
}

.about-me-1 {
  margin-bottom: -30px;
}
@media (max-width: 767px) {
  .about-me-1 {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px),
  only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 1200px) and (max-width: 1399px) {
  .about-me-1 img {
    max-width: 100%;
  }
}

.about-me-about-cta span {
  color: var(--tp-grey-3);
  font-size: 18px;
  display: block;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.about-me-about-cta span :hover {
  color: var(--tp-theme-redical);
}

.about-me-about-right .tp-section__subtitle i {
  background-color: #5a6ef8 !important;
}
.about-me-about-right .tp-section p {
  margin-bottom: 20px;
}

.about-me-cta-area .tp-section__title {
  text-transform: lowercase;
}

/*----------------------------------------*/
/* 07. TEAM CSS
/*----------------------------------------*/
.da-team-info {
  position: absolute;
  left: 30px;
  bottom: 0;
  right: 30px;
  border-radius: 8px;
  padding: 25px 30px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.da-team-info span {
  display: inline-block;
  margin-bottom: 10px;
  background-image: -webkit-linear-gradient(180deg, #fe7a8f, #5a6ef8);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media (max-width: 767px) {
  .da-team-info {
    left: 20px;
    right: 20px;
    padding: 25px 15px;
  }
}

.da-team-img {
  border-radius: 8px;
}
.da-team:hover .da-team-info {
  bottom: 30px;
  opacity: 1;
  visibility: visible;
}
.da-team:hover .da-team-img img {
  transform: scale(1.1);
}

.da-team-name {
  font-size: 24px;
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .da-team-name {
    font-size: 18px;
  }
}
.da-team-name:hover {
  background-image: -webkit-linear-gradient(180deg, #fe7a8f, #5a6ef8);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.da-team-name a {
  background-image: linear-gradient(#fe7a8f, #5a6ef8),
    linear-gradient(#fe7a8f, #5a6ef8);
  display: inline;
  background-size: 0% 1px, 0 1px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.4s linear;
}
.da-team-name a:hover {
  background-size: 0 1px, 100% 1px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ca-team-area {
    padding-bottom: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ca-team-item {
    margin-bottom: 60px;
  }
}
@media (max-width: 767px) {
  .ca-team-item {
    margin-bottom: 60px;
  }
}
.ca-team-item__img {
  position: relative;
}
.ca-team-item__img-social {
  position: absolute;
  bottom: 20px;
  left: 30px;
  color: #fff;
  opacity: 0;
  z-index: 2;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.ca-team-item__img-social a {
  margin-right: 20px;
}
.ca-team-item__img::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  background-image: -moz-linear-gradient(
    0deg,
    rgb(90, 110, 248) 0%,
    rgb(254, 122, 143) 100%
  );
  background-image: -webkit-linear-gradient(
    0deg,
    rgb(90, 110, 248) 0%,
    rgb(254, 122, 143) 100%
  );
  background-image: -ms-linear-gradient(
    0deg,
    rgb(90, 110, 248) 0%,
    rgb(254, 122, 143) 100%
  );
  opacity: 0;
  z-index: 1;
}
.ca-team-item__img:hover::after {
  opacity: 0.6;
}
.ca-team-item__img:hover .ca-team-item__img-social {
  opacity: 1;
}
.ca-team-item__content-title {
  font-size: 24px;
  line-height: 1;
}
.ca-team-item__content-title a {
  background-image: linear-gradient(#000, #000), linear-gradient(#000, #000);
  display: inline;
  background-size: 0% 1px, 0 1px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.4s linear;
}
.ca-team-item__content-title a:hover {
  background-size: 0% 1px, 100% 1px;
}
.ca-team-item__content span {
  color: var(--tp-grey-1);
}
.ca-team-item:hover .ca-team-item__img img {
  transform: scale(1.1);
}

.ca-team-item-after::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  background-color: var(--tp-theme-redical);
  opacity: 0;
  z-index: 1;
  background-image: inherit;
}
.ca-team-item-after:hover::after {
  opacity: 0.8;
}
.ca-team-item-after:hover .ca-team-item__img-social {
  opacity: 1;
}

.tp-team-details-cta p b {
  color: var(--tp-common-black);
}
@media (max-width: 576px) {
  .tp-team-details-cta p b {
    font-size: 16px;
  }
}
.tp-team-details-cta .call-icon {
  color: var(--tp-theme-cornblue);
  background-color: #f4f3ff;
  flex: 0 0 auto;
}

.team-dt-progress-title {
  font-size: 26px;
}
.team-dt-progress-sm-title {
  font-size: 18px;
  font-weight: 400;
}
.team-dt-progress-count {
  font-size: 18px;
  font-weight: 400;
  color: var(--tp-theme-redical);
}
.team-dt-progress-item .progress {
  position: relative;
  height: 10px;
  background-color: transparent;
}
.team-dt-progress-item .progress::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: rotateY(-50%);
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #ececec;
  z-index: -1;
}
.team-dt-progress-item .progress-bar {
  background-color: var(--tp-theme-redical);
}

@media (max-width: 767px) {
  .tp-team-dt-info-left {
    margin-left: 0;
  }
}

.trems-step span {
  width: 54px;
  height: 54px;
  line-height: 54px;
  border-radius: 8px;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  color: var(--tp-common-black);
  border: 2px solid #f4f4f4;
  display: inline-block;
}
@media (max-width: 767px) {
  .trems-step span {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

@media (max-width: 767px),
  only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (min-width: 992px) and (max-width: 1199px) {
  .team-dt-skill-img img {
    max-width: 100%;
  }
}

/*----------------------------------------*/
/* 08. SERVICES CSS
/*----------------------------------------*/
.tp-bs-sv-icon {
  position: relative;
}
.tp-bs-sv-icon img {
  padding: 15px 13px;
  background-color: var(--tp-common-white);
  box-shadow: 0px 30px 40px 0px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
}
.tp-bs-sv-icon::after {
  position: absolute;
  content: "";
  border: 2px dashed #f0f0f0;
  width: 56px;
  height: 56px;
  display: inline-block;
  top: -15%;
  left: 45%;
  transform: scale(0);
  z-index: -1;
  border-radius: 8px;
}

.tp-bs-sv-title {
  font-size: 20px;
  text-transform: capitalize;
}
.tp-bs-sv-title a {
  background-image: linear-gradient(#000, #000), linear-gradient(#000, #000);
  display: inline;
  background-size: 0% 1px, 0 1px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.4s linear;
}
.tp-bs-sv-title a:hover {
  color: var(--tp-common-black);
  background-size: 0 1px, 100% 1px;
}

.tp-bs-service-box {
  border: 1px solid var(--tp-border-4);
  border-radius: 10px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.tp-bs-service-box:hover {
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06);
  border-color: var(--tp-theme-redical);
}
.tp-bs-service-box:hover .tp-bs-sv-icon::after {
  transform: scale(1);
  -webkit-animation: rotate 5s linear infinite;
  animation: rotate 5s linear infinite;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-bs-sv-avata {
    margin-bottom: 30px;
    padding-right: 0;
  }
}
@media (max-width: 767px) {
  .tp-bs-sv-avata {
    margin-bottom: 30px;
    padding-right: 0;
  }
}

.tp-bs-service-review p {
  font-size: 16px;
}
.tp-bs-service-review a {
  color: var(--tp-theme-cornblue);
  text-decoration: underline;
  font-weight: 400;
  font-size: 16px;
}
.tp-bs-service-review a:hover {
  color: var(--tp-theme-redical);
}
.tp-bs-service-review a span {
  margin-left: 5px;
}

.it-sv-counter {
  counter-reset: count;
}

.it-service__item {
  position: relative;
  padding-top: 60px;
  padding-bottom: 50px;
  padding-left: 45px;
  padding-right: 45px;
  border-radius: 8px;
  border: 2px solid var(--tp-border-9);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.it-service__item::after {
  position: absolute;
  top: 40%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  content: "0" counter(count);
  counter-increment: count;
  font-size: 160px;
  font-weight: 900;
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.6) 0%,
    rgb(234, 234, 234) 100%
  );
  color: var(--tp-grey-1);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  z-index: -11;
}
@media (max-width: 576px) {
  .it-service__item {
    padding-right: 20px;
    padding-left: 20px;
  }
}
.it-service__item-title {
  font-size: 24px;
  letter-spacing: -1px;
  color: var(--tp-theme-vogue);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .it-service__item-title {
    font-size: 23px;
  }
}
.it-service__item-title a {
  background-image: linear-gradient(#000, #000), linear-gradient(#000, #000);
  display: inline;
  background-size: 0% 1px, 0 1px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.4s linear;
}
.it-service__item-title a:hover {
  background-size: 0 1px, 100% 1px;
}
.it-service__item p {
  font-size: 16px;
  color: var(--tp-grey-1);
}
.it-service__item:hover {
  border-color: transparent;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06);
}
.it-service__item:hover .it-servicce__item-img img {
  transform: translateY(-10px) translate3d(0, -10px, 0);
}

@media (max-width: 767px) {
  .da-service {
    padding-left: 0;
    padding-right: 0;
  }
}
.da-service__title {
  font-size: 30px;
}
@media (max-width: 767px) {
  .da-service__title {
    font-size: 20px;
  }
}
.da-service__counter {
  font-size: 18px;
  width: 44px;
  height: 44px;
  border-radius: 8px;
  text-align: center;
  line-height: 44px;
  font-weight: 400;
  color: var(--tp-common-white);
  background-image: linear-gradient(
    90deg,
    rgb(90, 110, 248) 0%,
    rgb(254, 122, 143) 100%
  );
}
.da-service__list-box {
  padding: 30px 40px;
  margin-bottom: 30px;
  border-radius: 8px;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.02);
}
@media (max-width: 576px) {
  .da-service__list-box {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.da-service__list-box p {
  color: var(--tp-grey-1);
  font-size: 16px;
}
.da-service__list-box ul li {
  position: relative;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 15px;
}
.da-service__list-box ul li:last-child {
  margin-bottom: 0;
}
.da-service__list-box ul li span {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.da-service__list-box ul li span i {
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.06);
  background-image: -webkit-linear-gradient(180deg, #fe7a8f, #5a6ef8);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.tp-da-service-area {
  background-image: linear-gradient(
    360deg,
    rgb(255, 255, 255) 0%,
    rgb(237, 243, 245) 100%
  );
}

.ptg-service__box {
  padding: 60px 35px;
  text-align: center;
  background-color: white;
  border-radius: 10px;
  margin-bottom: 30px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.ptg-service__box:hover {
  transform: translateY(-10px);
}
.ptg-service__box-icon {
  font-size: 80px;
  line-height: 1;
  background-image: linear-gradient(0deg, #5a6ef8, #fe7a8f);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 20px;
  position: relative;
  z-index: 2;
}
.ptg-service__box-title {
  color: var(--tp-common-dark);
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 0px;
  margin-bottom: 10px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .ptg-service__box-title {
    font-size: 17px;
  }
}
.ptg-service__box-title a {
  background-image: linear-gradient(#000, #000), linear-gradient(#000, #000);
  display: inline;
  background-size: 0% 1px, 0 1px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.4s linear;
}
.ptg-service__box-title a:hover {
  background-size: 0% 1px, 100% 1px;
}
.ptg-service__box p {
  color: var(--tp-grey-4);
  font-size: 16px;
}

.ptg-counter {
  font-size: 120px;
  position: absolute;
  font-weight: 400;
  top: 10%;
  left: 0;
  right: 0;
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.6) 0%,
    rgb(234, 234, 234) 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  z-index: 1;
  line-height: 1;
  display: inline-block;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-ptg-sv-avata {
    margin-bottom: 30px;
    padding-right: 0;
  }
}
@media (max-width: 767px) {
  .tp-ptg-sv-avata {
    margin-bottom: 30px;
    padding-right: 0;
  }
}

.tp-ptg-service-review p {
  font-size: 16px;
  color: white;
}
.tp-ptg-service-review a {
  color: var(--tp-common-white);
  text-decoration: underline;
  font-weight: 400;
  font-size: 16px;
}
.tp-ptg-service-review a:hover {
  background-image: linear-gradient(90deg, #5a6ef8, #fe7a8f);
  color: var(--tp-grey-1);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.tp-ptg-service-review a span {
  margin-left: 5px;
}

.law-sv-item {
  padding: 40px;
  border: 1px solid var(--tp-border-11);
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.law-sv-item-icon {
  flex: 0 0 auto;
}
.law-sv-item-title {
  font-size: 20px;
  color: var(--tp-theme-wine);
  line-height: 1.5;
}
.law-sv-item-list ul li {
  color: var(--tp-grey-1);
  position: relative;
  font-size: 16px;
  margin-bottom: 15px;
}
.law-sv-item-list ul li:last-child {
  margin-bottom: 0;
}
.law-sv-item-list ul li span {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.law-sv-item-list ul li span i {
  color: var(--tp-theme-red);
}
.law-sv-item:hover .law-sv-item-icon img {
  transform: rotateY(-360deg);
}
@media (max-width: 767px) {
  .law-sv-item {
    padding: 20px;
  }
}

.law-sv-button-prev,
.law-sv-button-next {
  font-size: 20px;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  background-color: var(--tp-common-white);
  color: var(--tp-theme-wine);
  border: 1px solid #ebebeb;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  border-radius: 8px;
}
.law-sv-button-prev:hover,
.law-sv-button-next:hover {
  color: var(--tp-common-white);
  background-color: var(--tp-theme-red);
  border-color: transparent;
}

.law-sv-button-prev {
  margin-right: 30px;
}

.law-service__item {
  background-color: white;
  padding: 40px 60px;
}
.law-service__item-title {
  font-size: 24px;
}
.law-service__item p {
  color: var(--tp-grey-1);
  font-size: 16px;
}

.ca-service__item {
  padding: 40px 60px;
  border: 1px solid transparent;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .ca-service__item {
    padding: 40px 20px;
  }
}
.ca-service__item-title {
  font-size: 20px;
}
.ca-service__item-title a {
  background-image: linear-gradient(#000, #000), linear-gradient(#000, #000);
  display: inline;
  background-size: 0% 1px, 0 1px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.4s linear;
}
.ca-service__item-title a:hover {
  background-size: 0% 1px, 100% 1px;
}
.ca-service__item p {
  color: var(--tp-grey-1);
  font-size: 16px;
}

.ca-service-wrapper {
  background-color: white;
  padding: 20px;
}
.ca-service-wrapper:hover .ca-service__item {
  border: 1px solid var(--tp-theme-redical);
}

.ca-service-area {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.read-btn {
  font-size: 16px;
  font-weight: 400;
  color: #000;
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}
.read-btn i {
  margin-left: 5px;
}
.read-btn:hover {
  background-image: linear-gradient(90deg, #5a6ef8, #fe7a8f);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.ha-service {
  margin: 0 10px;
}
.ha-service-item {
  border: 1px solid #ebebeb;
  padding: 25px 40px;
}
@media (max-width: 767px) {
  .ha-service-item {
    padding: 25px 20px;
  }
}
.ha-service__title {
  font-size: 18px;
}
.ha-service__icon {
  font-size: 18px;
}

.seo-service {
  border: 1px solid #eaeef2;
  border-radius: 12px;
}
.seo-service-item {
  padding: 50px 30px 0 30px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  border-radius: 12px;
}
@media (max-width: 767px),
  only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (min-width: 992px) and (max-width: 1199px) {
  .seo-service-item {
    margin-bottom: 30px;
  }
}
.seo-service-item__thumb {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.seo-service-item__title {
  font-size: 18px;
  font-weight: 600;
  color: var(--tp-theme-seagreen);
}
.seo-service-item__title a {
  background-image: linear-gradient(#000, #000), linear-gradient(#000, #000);
  display: inline;
  background-size: 0% 1px, 0 1px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.4s linear;
}
.seo-service-item__title a:hover {
  background-size: 0% 1px, 100% 1px;
}
.seo-service-item p {
  color: #757c80;
}
.seo-service-item .seo-service-btn {
  font-size: 14px;
  font-weight: 400;
  color: var(--tp-theme-seagreen);
  background-color: #f8f8f8;
  padding: 19px 45px;
  display: inline-block;
  border-radius: 12px 12px 0 0;
  text-transform: uppercase;
}
.seo-service-item .seo-service-btn i {
  margin-left: 5px;
}
.seo-service-item .seo-service-btn:hover {
  color: #fff;
  background-color: var(--tp-theme-lochmara);
  box-shadow: 0px -16px 32px 0px rgba(6, 134, 216, 0.2);
}
.seo-service-item:hover {
  box-shadow: 0px -16px 32px 0px rgba(0, 0, 0, 0.06);
  border-radius: 0;
}
.seo-service-item:hover .seo-service-item__thumb {
  transform: translateY(-15px);
}

.tp-ab-sv-tabs .nav-pills .nav-link {
  color: var(--tp-common-black);
  font-size: 16px;
  font-weight: 400;
  margin-right: 20px;
  padding: 8px 30px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
}
@media (max-width: 767px) {
  .tp-ab-sv-tabs .nav-pills .nav-link {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .tp-ab-sv-tabs .nav-pills .nav-link {
    margin-bottom: 30px;
  }
}
.tp-ab-sv-tabs .nav-pills .nav-link.active {
  background-color: var(--tp-theme-cornblue);
  color: #fff;
}
.tp-ab-sv-tabs-title {
  margin-bottom: 15px;
}
.tp-ab-sv-tabs-title span {
  color: var(--tp-theme-redical);
}
.tp-ab-sv-tabs-content p {
  font-size: 16px;
  padding-right: 40px;
}
@media (max-width: 767px) {
  .tp-ab-sv-tabs-content p {
    padding-right: 0;
  }
}

.serive-page-item-title .it-service__item-title {
  color: var(--tp-theme-black);
}

.sv-cicle-logo-wraper img {
  animation: rotate 15s linear infinite;
}

.sv-page-vido-icon {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
}
.sv-page-vido-icon a {
  font-size: 20px;
  display: inline-block;
  width: 120px;
  height: 120px;
  background-color: white;
  line-height: 120px;
  border-radius: 50%;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06);
  animation: pulse 2s infinite;
}
@media (max-width: 767px) {
  .sv-page-vido-icon a {
    font-size: 13px;
    width: 60px;
    height: 60px;
    line-height: 60px;
  }
}

.bg {
  border-radius: 8px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
  position: absolute;
  left: 315px;
  top: 599px;
  width: 410px;
  height: 340px;
  z-index: 106;
}

.sv-page-item {
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.sv-page-item__img {
  border-radius: 12px 12px;
}
.sv-page-item__icon {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  margin: 0 auto;
  width: 120px;
  height: 120px;
  background-color: #fff;
  font-size: 50px;
  line-height: 120px;
  border-radius: 50%;
  text-align: center;
  color: var(--tp-theme-redical);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
@media (max-width: 767px) {
  .sv-page-item__icon {
    width: 60px;
    height: 60px;
    line-height: 60px;
    font-size: 25px;
  }
}
.sv-page-item__title {
  font-size: 20px;
}
.sv-page-item__title a {
  background-image: linear-gradient(#000, #000), linear-gradient(#000, #000);
  display: inline;
  background-size: 0% 1px, 0 1px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.4s linear;
}
.sv-page-item__title a:hover {
  background-size: 0% 1px, 100% 1px;
}
.sv-page-item:hover {
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.08);
}
.sv-page-item:hover .sv-page-item__icon {
  top: 40%;
}

.bg {
  border-style: solid;
  border-width: 2px;
  border-color: rgb(244, 244, 244);
  border-radius: 8px;
  background-color: rgb(255, 255, 255);
  position: absolute;
  left: 311px;
  top: 595px;
  width: 294px;
  height: 185px;
  z-index: 151;
}

.sv-detials-sv-item {
  border-style: solid;
  border-width: 2px;
  border-color: rgb(244, 244, 244);
  border-radius: 8px;
  background-color: rgb(255, 255, 255);
  padding: 40px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  position: relative;
}
.sv-detials-sv-item__icon {
  margin-bottom: 10px;
  font-size: 50px;
  color: var(--tp-theme-redical);
  background-color: white;
  display: inline-block;
}
.sv-detials-sv-item__title {
  font-size: 20px;
}
.sv-detials-sv-item__title:hover {
  color: var(--tp-theme-redical);
}
.sv-detials-sv-item:hover {
  border-color: var(--tp-theme-redical);
}
.sv-detials-sv-item .counter-number {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #d9d9d9;
  font-size: 20px;
}

.tp-sv-border {
  border: 1px solid var(--tp-border-9);
  border-left: none;
}

/*----------------------------------------*/
/* 09. TESTIMONIAL CSS
/*----------------------------------------*/
.tp-bs-testi-info {
  margin-right: -80px;
  box-shadow: 0px 30px 40px 0px rgba(0, 0, 0, 0.04);
  margin-bottom: -60px;
  padding: 100px;
}
@media only screen and (min-width: 1400px) and (max-width: 1499px) {
  .tp-bs-testi-info {
    margin-right: -40px;
  }
}
@media (max-width: 767px),
  only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-bs-testi-info {
    margin-right: 0px;
  }
}
@media (max-width: 767px) {
  .tp-bs-testi-info {
    margin-bottom: 0;
  }
}
@media (max-width: 767px) {
  .tp-bs-testi-info {
    padding: 20px 15px;
    display: block;
  }
}

.tp-bs-testi-img {
  z-index: 2;
  position: relative;
}
@media (max-width: 767px) {
  .tp-bs-testi-img img {
    max-width: 100%;
  }
}

.testi-circle-bg {
  position: absolute;
  top: 20%;
  left: 0;
  z-index: 1;
  animation: zoom 3s infinite;
}
@media (max-width: 767px) {
  .testi-circle-bg img {
    max-width: 100%;
  }
}

.tp-bs-testimonial {
  position: relative;
  z-index: 2;
}
.tp-bs-testimonial p {
  color: var(--tp-common-black);
  font-size: 26px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-bs-testimonial p {
    font-size: 22px;
  }
}
@media (max-width: 767px) {
  .tp-bs-testimonial p {
    font-size: 20px;
  }
}
@media (max-width: 576px) {
  .tp-bs-testimonial p {
    font-size: 18px;
  }
}

.tp-bs-testi-icon {
  position: absolute;
  top: 50%;
  right: 0;
  z-index: -1;
  opacity: 0.03;
}

.tesi-reviewer-name h4 {
  font-size: 18px;
  font-weight: 400;
}
.tesi-reviewer-name span {
  color: var(--tp-grey-4);
}

.tp-testi-ratting {
  color: var(--tp-theme-cornblue);
}

.tesi-bs-pagination {
  position: absolute;
  bottom: -80px;
  left: -200px;
}
@media (max-width: 767px),
  only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (min-width: 992px) and (max-width: 1199px) {
  .tesi-bs-pagination {
    margin-top: 50px;
    position: static;
  }
}
.tesi-bs-pagination span {
  margin: 0 8px;
}

.tesi-bs-pagination .swiper-pagination-bullet {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: transparent;
  border: 1px solid #cecece;
  opacity: 1;
  position: relative;
}
.tesi-bs-pagination .swiper-pagination-bullet::after {
  position: absolute;
  content: "";
  left: -8px;
  top: -8px;
  width: calc(100% + 16px);
  height: calc(100% + 16px);
  border: 1px solid black;
  transform: scale(0);
  border-radius: 50%;
}
.tesi-bs-pagination .swiper-pagination-bullet:hover {
  cursor: pointer;
}
.tesi-bs-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #000;
}
.tesi-bs-pagination
  .swiper-pagination-bullet.swiper-pagination-bullet-active::after {
  transform: scale(1);
}

.it-testi-wraper {
  margin-bottom: -125px;
}

.it-testimonial-box {
  padding: 50px;
  margin-bottom: 40px;
  z-index: 2;
  background-color: var(--tp-common-white);
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
}
@media (max-width: 767px) {
  .it-testimonial-box {
    padding: 30px;
  }
}
@media (max-width: 576px) {
  .it-testimonial-box {
    padding: 20px;
  }
}
.it-testimonial-box__ratting {
  margin-bottom: 10px;
}
.it-testimonial-box__ratting i {
  color: var(--tp-theme-orange);
}
.it-testimonial-box__review {
  color: var(--tp-theme-vogue);
  font-size: 26px;
}
@media (max-width: 576px) {
  .it-testimonial-box__review {
    font-size: 20px;
  }
}
.it-testimonial-box::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 90px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 27px 40px 0 0;
  border-color: var(--tp-common-white) transparent transparent transparent;
}

.it-testimonial-bg {
  position: absolute;
  top: 30px;
  right: 40px;
  z-index: 1;
  opacity: 0.03;
}
.it-testimonial-bg img {
  width: 120px;
}

.it-tesi-reviewer-name h4 {
  font-size: 18px;
}
.it-tesi-reviewer-name span {
  color: var(--tp-theme-orange);
}

.tp-it-testi-active {
  margin-right: -620px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-it-testi-active {
    margin-right: -400px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .tp-it-testi-active {
    margin-right: 0;
  }
}

@media (max-width: 576px) {
  .it-testi-navigation {
    display: none;
  }
}

.it-testi-button-prev,
.it-testi-button-next {
  font-size: 20px;
  position: absolute;
  right: 0;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  background-color: var(--tp-common-white);
  color: var(--tp-theme-vogue);
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  border-radius: 8px;
}
.it-testi-button-prev:hover,
.it-testi-button-next:hover {
  color: var(--tp-common-white);
  background-color: var(--tp-theme-orange);
}

.it-testi-button-prev {
  margin-right: 80px;
}

.da-testi-button-prev,
.da-testi-button-next {
  font-size: 20px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  border-radius: 50%;
  color: var(--tp-common-white);
  border: 2px solid #262626;
  background-color: rgba(0, 0, 0, 0);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  z-index: 12;
}
.da-testi-button-prev::after,
.da-testi-button-next::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-image: linear-gradient(
    90deg,
    rgb(90, 110, 248) 0%,
    rgb(254, 122, 143) 100%
  );
  z-index: -1;
  opacity: 0;
}
.da-testi-button-prev:hover,
.da-testi-button-next:hover {
  color: var(--tp-common-white);
  border-color: transparent;
}
.da-testi-button-prev:hover::after,
.da-testi-button-next:hover::after {
  opacity: 1;
}

.da-testi-button-prev {
  left: 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (min-width: 992px) and (max-width: 1199px) {
  .da-testi-avata img {
    max-width: 100%;
  }
}

.da-testimonial {
  background-repeat: no-repeat;
  background-position: center;
}

@media (max-width: 767px) {
  .da-testi {
    padding-top: 30px;
    margin-left: 0;
  }
}
@media (max-width: 576px) {
  .da-testi {
    padding-top: 30px;
    margin-left: 0;
  }
}
.da-testi__ratting {
  font-size: 18px;
  background-image: -webkit-linear-gradient(180deg, #fe7a8f, #5a6ef8);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.da-testi__review {
  font-size: 26px;
  color: var(--tp-grey-6);
  line-height: 1.5;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (min-width: 992px) and (max-width: 1199px) {
  .da-testi__review {
    font-size: 20px;
  }
}
@media (max-width: 576px) {
  .da-testi__review {
    font-size: 20px;
  }
}
.da-testi__meta span {
  color: var(--tp-grey-7);
}
.da-testi__reviewer-name {
  font-size: 18px;
  color: #fff;
}
.da-testi-icon {
  position: absolute;
  font-size: 24px;
  top: 0;
  left: 0;
  width: 80px;
  height: 80px;
  color: white;
  text-align: center;
  line-height: 80px;
  border-radius: 50%;
  background-image: linear-gradient(-90deg, #fe7a8f, #5a6ef8);
}
@media only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (min-width: 992px) and (max-width: 1199px) {
  .da-testi-icon {
    width: 50px;
    height: 50px;
    line-height: 50px;
  }
}

.app-testimonial {
  background-size: cover;
}
@media (max-width: 767px) {
  .app-testimonial {
    text-align: center;
  }
}

@media (max-width: 767px) {
  .app-testi {
    margin-left: 0;
  }
}
.app-testi__ratting {
  font-size: 18px;
  background-image: -webkit-linear-gradient(180deg, #fe7a8f, #5a6ef8);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: var(--tp-theme-redical);
}
@media (max-width: 767px) {
  .app-testi__ratting {
    margin-top: 20px;
  }
}
.app-testi__review {
  font-size: 26px;
  color: var(--tp-theme-mirage);
  line-height: 1.5;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (min-width: 992px) and (max-width: 1199px),
  (max-width: 767px) {
  .app-testi__review {
    font-size: 20px;
  }
}
@media (max-width: 576px) {
  .app-testi__review {
    font-size: 18px;
    padding-left: 15px;
    padding-right: 15px;
  }
}
.app-testi__meta span {
  color: var(--tp-grey-1);
}
.app-testi__meta span i {
  color: var(--tp-theme-cornblue);
  font-style: normal;
}
.app-testi__reviewer-name {
  font-size: 18px;
  color: var(--tp-theme-mirage);
}
.app-testi-icon {
  position: absolute;
  font-size: 24px;
  top: 0;
  left: 0;
  width: 80px;
  height: 80px;
  color: white;
  text-align: center;
  line-height: 80px;
  border-radius: 50%;
  background-color: var(--tp-theme-redical);
}
@media only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (min-width: 992px) and (max-width: 1199px) {
  .app-testi-icon {
    width: 50px;
    height: 50px;
    line-height: 50px;
  }
}
@media (max-width: 767px) {
  .app-testi-icon {
    display: none;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (min-width: 992px) and (max-width: 1199px),
  (max-width: 767px) {
  .app-testi-avata img {
    max-width: 100%;
    margin-left: 30px;
  }
}

.app-testi-button-prev,
.app-testi-button-next {
  font-size: 20px;
  right: 0;
  width: 60px;
  height: 60px;
  line-height: 56px;
  text-align: center;
  border-radius: 50%;
  color: var(--tp-common-black);
  border: 2px solid rgba(0, 0, 0, 0.102);
  background-color: var(--tp-common-white);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  z-index: 99;
}
.app-testi-button-prev:hover,
.app-testi-button-next:hover {
  color: var(--tp-common-white);
  background-color: var(--tp-theme-redical);
  border-color: transparent;
}

.app-testi-active .swiper-slide {
  opacity: 0.5;
}
.app-testi-active .swiper-slide-active {
  opacity: 1;
}

.tp-ptg-brand-item {
  display: inline-block;
  text-align: center;
  filter: grayscale(1);
  opacity: 0.5;
}
.tp-ptg-brand-item:hover {
  filter: grayscale(0);
  opacity: 1;
}
.tp-ptg-brand-item.swiper-slide-active {
  filter: grayscale(0);
  opacity: 1;
}

.ptg-testimonial-box {
  position: relative;
  padding: 54px;
  margin-bottom: 40px;
  z-index: 2;
  background-image: linear-gradient(-90deg, #fe7a8f, #5a6ef8);
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
}
.ptg-testimonial-box::after {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  bottom: 2px;
  right: 2px;
  background-color: var(--tp-common-black);
  display: inline-block;
  z-index: -1;
  border-radius: 10px;
}
.ptg-testimonial-box::before {
  content: "";
  position: absolute;
  bottom: -14px;
  left: 90px;
  width: 46px;
  height: 45px;
  border: 2px solid #7a70e3;
  background-color: #000;
  z-index: -1;
  transform: rotate(53deg);
}
@media (max-width: 767px) {
  .ptg-testimonial-box {
    padding: 30px;
  }
}
@media (max-width: 576px) {
  .ptg-testimonial-box {
    padding: 20px;
  }
}
.ptg-testimonial-box__ratting {
  font-size: 18px;
  margin-bottom: 10px;
  background-image: linear-gradient(0deg, #5a6ef8, #fe7a8f);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 20px;
}
.ptg-testimonial-box__review {
  color: #fff;
  font-size: 26px;
}
@media (max-width: 576px) {
  .ptg-testimonial-box__review {
    font-size: 20px;
  }
}

.ptg-testimonial-bg {
  position: absolute;
  top: 30px;
  right: 40px;
  z-index: 1;
  opacity: 0.03;
  color: #151515;
  font-size: 100px;
  opacity: 0.6;
}

.ptg-tesi-reviewer-name h4 {
  font-size: 18px;
  color: #fff;
}
.ptg-tesi-reviewer-name span {
  color: var(--tp-grey-4);
}

@media (max-width: 576px) {
  .ptg-testi-navigation {
    display: none;
  }
}

.ptg-testi-button-prev,
.ptg-testi-button-next {
  position: relative;
  font-size: 20px;
  position: absolute;
  right: 0;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  background-color: var(--tp-common-white);
  color: var(--tp-common-black);
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  z-index: 1;
}
.ptg-testi-button-prev::after,
.ptg-testi-button-next::after {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-image: linear-gradient(
    90deg,
    rgb(90, 110, 248) 0%,
    rgb(254, 122, 143) 100%
  );
  z-index: -1;
  opacity: 0;
}
.ptg-testi-button-prev:hover,
.ptg-testi-button-next:hover {
  color: var(--tp-common-white);
}
.ptg-testi-button-prev:hover::after,
.ptg-testi-button-next:hover::after {
  opacity: 1;
}

.ptg-testi-button-prev {
  margin-right: 80px;
}

.ca-tasti-button-prev,
.ca-tasti-button-next {
  font-size: 20px;
  position: absolute;
  right: 0;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  background-color: var(--tp-common-white);
  color: var(--tp-common-black);
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  border-radius: 8px;
  z-index: 2;
}
.ca-tasti-button-prev::after,
.ca-tasti-button-next::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    90deg,
    rgb(90, 110, 248) 0%,
    rgb(254, 122, 143) 100%
  );
  z-index: -1;
  border-radius: 8px;
  transform: scale(0);
}
.ca-tasti-button-prev:hover,
.ca-tasti-button-next:hover {
  color: var(--tp-common-white);
}
.ca-tasti-button-prev:hover::after,
.ca-tasti-button-next:hover::after {
  transform: scale(1);
}

.ca-tasti-button-prev {
  margin-right: 80px;
}

.ca-testi-active .it-testimonial-box {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.ca-testi-active .it-testimonial-box:hover {
  background-color: black;
}
.ca-testi-active .it-testimonial-box:hover .it-testimonial-box__review {
  color: #fff;
}
.ca-testi-active .it-testimonial-box:hover::after {
  border-color: #000 transparent transparent transparent;
}
.ca-testi-active .it-testimonial-box__review {
  color: #000;
}
.ca-testi-active .it-testimonial-box__ratting i {
  position: relative;
  background-image: linear-gradient(90deg, #5a6ef8, #fe7a8f);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.ca-testi-active .it-tesi-reviewer-name h4 {
  color: #000;
}
.ca-testi-active .it-tesi-reviewer-name span {
  color: var(--tp-grey-1);
}

/*----------------------------------------*/
/* 10. BRAND CSS
/*----------------------------------------*/
@media (max-width: 576px) {
  .tp-bs-brand {
    padding-top: 0;
  }
}
.tp-bs-brand-item img {
  opacity: 0.4;
}
.tp-bs-brand-item.swiper-slide-active img {
  opacity: 1;
}
.tp-bs-brand-item:hover img {
  opacity: 1;
}
.tp-bs-brand__title {
  font-size: 20px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-bs-brand__title {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .tp-bs-brand__title {
    padding-top: 60px;
    margin-bottom: 30px;
  }
}
.tp-bs-brand__title span {
  color: var(--tp-theme-cornblue);
  text-decoration: underline;
}

@media (max-width: 767px) {
  .tp-bs-brand-item,
  .tp-it-brand-item,
  .tp-app-brand-item {
    text-align: center;
  }
}
.tp-bs-brand-item img,
.tp-it-brand-item img,
.tp-app-brand-item img {
  max-width: 100%;
}

.it-breand-title {
  font-size: 18px;
  color: #fff;
}

.app-breand-title {
  font-size: 20px;
  color: #000;
  font-weight: 400;
  color: var(--tp-theme-mirage);
}

.tp-it-brand-item {
  opacity: 0.5;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.tp-it-brand-item:hover {
  opacity: 1;
}
@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .tp-it-brand-item {
    text-align: center;
  }
}

.tp-it-brand {
  background-size: cover;
  background-repeat: no-repeat;
}

.tp-ca-brand-slider .tp-ptg-brand-item {
  text-align: start;
}
@media (max-width: 767px) {
  .tp-ca-brand-slider .tp-ptg-brand-item {
    text-align: center;
  }
}

.ha-brand-slider {
  box-shadow: -16px 0px 32px 0px rgba(0, 0, 0, 0.04);
  padding-top: 50px;
  padding-bottom: 50px;
  border-radius: 6px;
}
.ha-brand-slider .tp-it-brand-item {
  text-align: center;
}

.ha-brand-counter span {
  font-size: 18px;
  color: var(--tp-grey-1);
}
.ha-brand-counter span b {
  color: var(--tp-theme-mandalay);
}

.tp-seo-brand {
  background-size: cover;
  background-repeat: no-repeat;
}
.tp-seo-brand::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--tp-theme-lochmara);
  display: inline-block;
  z-index: 1;
  opacity: 0.961;
}
.tp-seo-brand .tp-bs-brand__title {
  color: #fff;
}
.tp-seo-brand .tp-bs-brand__title span {
  color: #fff;
}

.tp-seo-brand-wrapper {
  position: relative;
  z-index: 3;
}

.tp-seo-brand-item img {
  max-width: 100%;
  opacity: 0.5;
}
.tp-seo-brand-item img:hover {
  opacity: 1;
}
@media (max-width: 767px) {
  .tp-seo-brand-item {
    text-align: center;
  }
}

.ab-brand-overlay .tp-app-brand-item {
  opacity: 0.4;
}
.ab-brand-overlay .tp-app-brand-item :hover {
  opacity: 1;
}
.ab-brand-overlay .swiper-slide-active {
  opacity: 1;
}

/*----------------------------------------*/
/* 11. PROJECT CSS
/*----------------------------------------*/
@media (max-width: 767px) {
  .tp-bs-project {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
.tp-bs-project__img {
  border-radius: 8px;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.1);
}
.tp-bs-project__img:hover img {
  transform: scale(1.1);
}
.tp-bs-project__title {
  font-size: 24px;
}
.tp-bs-project__title a {
  background-image: linear-gradient(#000, #000), linear-gradient(#000, #000);
  display: inline;
  background-size: 0% 1px, 0 1px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.4s linear;
}
.tp-bs-project__title a:hover {
  color: #000;
  background-size: 0 1px, 100% 1px;
}
@media (max-width: 576px) {
  .tp-bs-project__title {
    font-size: 20px;
  }
}
.tp-bs-project__details span {
  font-size: 16px;
  color: var(--tp-theme-cornblue);
}
@media (max-width: 576px) {
  .tp-bs-project__details span {
    font-size: 14px;
  }
}
.tp-bs-project__details span:hover {
  color: var(--tp-theme-redical);
}

.da-project-button-prev,
.da-project-button-next {
  font-size: 20px;
  position: absolute;
  right: 0;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  background-color: var(--tp-common-white);
  color: var(--tp-common-black);
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  z-index: 3;
}
.da-project-button-prev::after,
.da-project-button-next::after {
  content: "";
  background-image: linear-gradient(
    90deg,
    rgb(90, 110, 248) 0%,
    rgb(254, 122, 143) 100%
  );
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  border-radius: 8px;
  opacity: 0;
}
.da-project-button-prev:hover,
.da-project-button-next:hover {
  color: var(--tp-common-white);
}
.da-project-button-prev:hover::after,
.da-project-button-next:hover::after {
  opacity: 1;
}

.da-project-button-prev {
  margin-right: 80px;
}

.da-project-active {
  margin-right: -275px;
}
.da-project-active .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.da-project-active .da-project-item {
  height: 450px;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
}
.da-project-active .da-project-item::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: -moz-linear-gradient(
    90deg,
    rgb(0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  background-image: -webkit-linear-gradient(
    90deg,
    rgb(0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  background-image: -ms-linear-gradient(
    90deg,
    rgb(0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  opacity: 0;
  border-radius: 8px;
  display: inline-block;
}
.da-project-active .da-project-item__info {
  position: absolute;
  bottom: 0;
  padding: 0 40px;
  opacity: 0;
  z-index: 5;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.da-project-active .da-project-item__info span {
  color: #fff;
  margin-bottom: 10px;
  display: inline-block;
}
@media (max-width: 767px) {
  .da-project-active .da-project-item__info {
    padding: 0 20px;
  }
}
.da-project-active .da-project-item__title {
  color: #fff;
  font-size: 24px;
}
.da-project-active .da-project-item__title a {
  background-image: linear-gradient(#fe7a8f, #5a6ef8),
    linear-gradient(#fe7a8f, #5a6ef8);
  display: inline;
  background-size: 0% 1px, 0 1px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.4s linear;
}
.da-project-active .da-project-item__title a:hover {
  background-size: 0 1px, 100% 1px;
}
.da-project-active .da-project-item:hover::after {
  opacity: 0.6;
}
.da-project-active .da-project-item:hover img {
  transform: scale(1.1);
}
.da-project-active .da-project-item:hover .da-project-item__info {
  bottom: 40px;
  opacity: 1;
}
.da-project-active .swiper-slide {
  width: 25%;
}
.da-project-active .swiper-slide:nth-child(2n) {
  width: 50%;
}
.da-project-active .swiper-slide:nth-child(3n) {
  width: 25%;
}

/*----------------------------------------*/
/* 12. PORTFOLIO CSS 
/*----------------------------------------*/
.tp-porffolio-wrapper {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.tp-porffolio-wrapper:hover {
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06);
}
.tp-porffolio-wrapper:hover .it-portfolio-item__thumb img {
  transform: scale(1.1);
}
.tp-porffolio-wrapper:hover .it-portfolio-item__thumb-logo img {
  transform: scale(1);
}

.it-portfolio-item__content {
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 35px;
}
@media (max-width: 767px) {
  .it-portfolio-item__content {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.it-portfolio-item__thumb {
  border-radius: 8px 8px 0 0;
  overflow: hidden;
}
.it-portfolio-item__thumb::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--tp-theme-vogue);
  opacity: 0.6;
  border-radius: 8px 8px 0 0;
}
.it-portfolio-item__thumb.overlay-none::after {
  opacity: 0;
}
.it-portfolio-item__thumb-logo {
  position: absolute;
  bottom: 40px;
  left: 40px;
  z-index: 1;
}
.it-portfolio-item__meta a {
  color: var(--tp-theme-orange);
  margin-right: 5px;
  padding-bottom: 10px;
  display: inline-block;
}
.it-portfolio-item__meta.red-text a {
  color: var(--tp-theme-red);
}
.it-portfolio-item__title {
  font-size: 24px;
  margin-bottom: 35px;
  color: var(--tp-theme-vogue);
  letter-spacing: -0.5px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .it-portfolio-item__title {
    font-size: 20px;
    letter-spacing: -0.3px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .it-portfolio-item__title {
    font-size: 20px;
    letter-spacing: -0.3px;
  }
}
@media (max-width: 576px) {
  .it-portfolio-item__title {
    font-size: 18px;
  }
}
.it-portfolio-item__title a {
  background-image: linear-gradient(#000, #000), linear-gradient(#000, #000);
  display: inline;
  background-size: 0% 1px, 0 1px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.4s linear;
}
.it-portfolio-item__title a:hover {
  color: var(--tp-theme-vogue);
  background-size: 0 1px, 100% 1px;
}
.it-portfolio-item__title.wine-text-color {
  letter-spacing: -1px;
}
.it-portfolio-item__title.wine-text-color a {
  color: var(--tp-theme-wine);
  text-transform: uppercase;
}
.it-portfolio-item__btn {
  width: 100%;
  color: var(--tp-theme-vogue);
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  border: 1px solid var(--tp-border-9);
  border-radius: 0 0 8px 8px;
  display: block;
  border-top: 0;
  padding: 20px 40px;
}
@media (max-width: 576px) {
  .it-portfolio-item__btn {
    font-size: 12px;
  }
}
.it-portfolio-item__btn:hover {
  color: var(--tp-common-white);
  background-color: var(--tp-theme-orange);
  border-color: transparent;
}
.it-portfolio-item__btn:hover span svg:first-child,
.it-portfolio-item__btn:hover span i:first-child {
  opacity: 1;
  transform: translateZ(0);
  color: var(--tp-common-white);
}
.it-portfolio-item__btn:hover span svg:last-child,
.it-portfolio-item__btn:hover span i:last-child {
  opacity: 0;
  transform: translateX(30px) translateY(0);
  color: var(--tp-common-white);
}
.it-portfolio-item__btn span {
  position: relative;
  float: right;
  color: var(--tp-theme-orange);
}
.it-portfolio-item__btn span svg,
.it-portfolio-item__btn span i {
  transition: transform 0.6s cubic-bezier(0.77, 0, 0.175, 1),
    opacity 0.6s cubic-bezier(0.77, 0, 0.175, 1);
}
.it-portfolio-item__btn span svg:first-child,
.it-portfolio-item__btn span i:first-child {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transform: translateX(-20px) translateY(0);
}
.it-portfolio-item__btn span svg:last-child,
.it-portfolio-item__btn span i:last-child {
  opacity: 1;
  display: block;
  transform: translateY(0) translateY(0);
}
.it-portfolio-item__btn.wine-text-color {
  color: var(--tp-theme-wine);
}
.it-portfolio-item__btn.wine-text-color span {
  color: var(--tp-theme-red);
}
.it-portfolio-item__btn.wine-text-color:hover {
  color: white;
  background-color: var(--tp-theme-red);
}

.it-pt-border {
  border: 1px solid var(--tp-border-9);
}

@media only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .ptg-portfolio-item {
    margin-bottom: 30px;
    text-align: center;
  }
}
.ptg-portfolio-item-img img {
  border-radius: 10px;
}
@media (max-width: 767px) {
  .ptg-portfolio-item-img img {
    max-width: 100%;
  }
}
.ptg-portfolio-item-info {
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: #fff;
  display: inline-block;
  padding: 25px 50px 25px 25px;
  border-radius: 8px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.ptg-portfolio-item-subtitle {
  margin-bottom: 8px;
  display: inline-block;
  background-image: linear-gradient(90deg, #5a6ef8, #fe7a8f);
  color: var(--tp-grey-1);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.ptg-portfolio-item-title {
  font-size: 24px;
  text-transform: uppercase;
  letter-spacing: -1px;
}
@media (max-width: 576px) {
  .ptg-portfolio-item-title {
    font-size: 20px;
  }
}
.ptg-portfolio-item-title a {
  background-image: linear-gradient(#000, #000), linear-gradient(#000, #000);
  display: inline;
  background-size: 0% 1px, 0 1px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.4s linear;
}
.ptg-portfolio-item-title a:hover {
  background-size: 0% 1px, 100% 1px;
}
.ptg-portfolio-item:hover .ptg-portfolio-item-info {
  bottom: 50px;
  opacity: 1;
  visibility: visible;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px),
  (max-width: 576px) {
  .pta-pt-img-large img {
    max-width: 100%;
  }
}

.mp-circle-img img {
  animation: rotate 8s linear infinite;
}

.mp-portfolio-area-title {
  font-weight: 400;
  color: var(--tp-grey-4);
  border-bottom: 1px solid #ececec;
  display: block;
  padding-top: 20px;
  padding-bottom: 20px;
}

.mp-portfolio-title {
  font-size: 60px;
  font-weight: 400;
  animation: none;
  letter-spacing: -2px;
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mp-portfolio-title {
    font-size: 30px;
  }
}
@media (max-width: 767px) {
  .mp-portfolio-title {
    font-size: 24px;
    letter-spacing: inherit;
  }
}

.mp-portfoio-img {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: 40%;
  top: 80%;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  z-index: 12;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.08);
}
@media (max-width: 767px) {
  .mp-portfoio-img img {
    max-width: 100%;
  }
}

.mp-pt-btn {
  position: absolute;
  left: 30px;
  bottom: 30px;
  border-radius: 50%;
  background-color: rgb(0, 0, 0);
  position: absolute;
  width: 60px;
  height: 60px;
  color: white;
  text-align: center;
  line-height: 60px;
}
@media (max-width: 767px) {
  .mp-pt-btn {
    width: 30px;
    height: 30px;
    line-height: 30;
  }
}
.mp-pt-btn:hover {
  opacity: 0.9;
  color: #fff;
}

.mp-portfolio-item {
  border-bottom: 1px solid #ececec;
  padding-top: 35px;
  padding-bottom: 35px;
}
.mp-portfolio-item:hover .mp-portfoio-img {
  opacity: 1;
  visibility: visible;
  top: 50%;
}

.mp-portfolio-tag {
  color: var(--tp-grey-1);
  font-size: 18px;
}

.mp-gt-row {
  margin-left: 30px;
  margin-right: -30px;
}

.law-porffolio-active {
  margin-right: -430px;
}
@media (max-width: 767px) {
  .law-porffolio-active {
    margin-right: 0;
  }
}

.law-pf-button-prev,
.law-pf-button-next {
  font-size: 20px;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  background-color: var(--tp-common-white);
  color: var(--tp-theme-wine);
  border: 1px solid #ebebeb;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  border-radius: 8px;
}
.law-pf-button-prev:hover,
.law-pf-button-next:hover {
  color: var(--tp-common-white);
  background-color: var(--tp-theme-red);
  border-color: transparent;
}
@media (max-width: 767px) {
  .law-pf-button-prev,
  .law-pf-button-next {
    left: 0;
  }
}

.law-pf-button-prev {
  margin-right: 20px;
}

.ca-portfolio-img {
  position: relative;
  overflow: hidden;
}
.ca-portfolio-img::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: -moz-linear-gradient(
    90deg,
    rgb(0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  background-image: -webkit-linear-gradient(
    90deg,
    rgb(0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  background-image: -ms-linear-gradient(
    90deg,
    rgb(0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  opacity: 0;
  display: inline-block;
}
.ca-portfolio-img:hover::after {
  opacity: 0.6;
}
.ca-portfolio-img:hover .ca-project-item__info {
  opacity: 1;
  bottom: 20px;
}
.ca-portfolio-img:hover img {
  transform: scale(1.1);
}

.ca-project-item__info {
  position: absolute;
  bottom: 0;
  left: 40px;
  opacity: 0;
  z-index: 5;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.ca-project-item__info span {
  color: #fff;
  margin-bottom: 10px;
  display: inline-block;
}
.ca-project-item__title {
  color: white;
  font-size: 24px;
}
.ca-project-item__title a {
  background-image: linear-gradient(#fe7a8f, #5a6ef8),
    linear-gradient(#fe7a8f, #5a6ef8);
  display: inline;
  background-size: 0% 1px, 0 1px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.4s linear;
}
.ca-project-item__title a:hover {
  background-size: 0% 1px, 100% 1px;
}

.ha-portfolio__item {
  display: inline-block;
}
.ha-portfolio__item-img {
  display: inline-block;
}
.ha-portfolio__item-img::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-image: -moz-linear-gradient(
    90deg,
    rgb(0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  background-image: -webkit-linear-gradient(
    90deg,
    rgb(0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  background-image: -ms-linear-gradient(
    90deg,
    rgb(0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  opacity: 0;
}
.ha-portfolio__item-content {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 50px;
  opacity: 0;
  visibility: hidden;
  z-index: 12;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
@media (max-width: 767px) {
  .ha-portfolio__item-content {
    padding: 0 20px;
  }
}
.ha-portfolio__item-content span {
  color: white;
  margin-bottom: 10px;
  display: inline-block;
}
.ha-portfolio__item-content-title {
  font-size: 24px;
  color: white;
}
@media (max-width: 767px) {
  .ha-portfolio__item-content-title {
    font-size: 19px;
  }
}
.ha-portfolio__item-content-title a {
  background-image: linear-gradient(#fff, #fff), linear-gradient(#fff, #fff);
  display: inline;
  background-size: 0% 1px, 0 1px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.4s linear;
}
.ha-portfolio__item-content-title a:hover {
  background-size: 0% 1px, 100% 1px;
}
.ha-portfolio__item:hover .ha-portfolio__item-content {
  bottom: 40px;
  opacity: 1;
  visibility: visible;
}
.ha-portfolio__item:hover .ha-portfolio__item-img::after {
  opacity: 0.6;
}

.ha-portfolio .mp-portfolio-item {
  border-top: 1px solid;
  border-color: #1a1a1a;
}
.ha-portfolio .mp-portfolio-item:last-child .mp-portfoio-img {
  bottom: 0;
  top: inherit;
}
.ha-portfolio .mp-portfolio-title {
  color: white;
}

@media (max-width: 767px) {
  .law-book {
    margin-bottom: 30px;
  }
}
.law-book img {
  max-width: 100%;
}

.tp-pf-btn-group button {
  padding: 15px 30px;
  color: #000;
  font-size: 16px;
  margin-right: 10px;
  font-weight: 400;
  border-radius: 8px;
  border: 1px solid var(--tp-border-12);
  margin-bottom: 30px;
}
.tp-pf-btn-group button.active {
  background-color: var(--tp-theme-redical);
  border-color: transparent;
  color: #fff;
}

.pf-single-item::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  opacity: 0;
  border-radius: 8px;
  background-image: -webkit-linear-gradient(
    90deg,
    rgb(0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 100%
  );
}
.pf-single-item img {
  border-radius: 8px;
}
.pf-single-item__info {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 30px;
  opacity: 0;
  visibility: hidden;
  color: var(--tp-common-white);
  z-index: 2;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
@media (max-width: 767px) {
  .pf-single-item__info {
    padding: 0 15px;
  }
}
.pf-single-item__info span {
  margin-bottom: 10px;
  display: inline-block;
}
.pf-single-item__title {
  color: var(--tp-common-white);
}
@media (max-width: 767px) {
  .pf-single-item__title {
    font-size: 22px;
  }
}
.pf-single-item__title a {
  background-image: linear-gradient(#fff, #fff), linear-gradient(#fff, #fff);
  display: inline;
  background-size: 0% 1px, 0 1px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.4s linear;
}
.pf-single-item__title a:hover {
  background-size: 0% 1px, 100% 1px;
}

/* .pf-item-wrapper:hover .pf-single-item__info {
  bottom: 30px;
  opacity: 1;
  visibility: visible;
}
.pf-item-wrapper:hover .pf-single-item::after {
  opacity: 0.4;
} */

.pf-details-banner__info {
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  margin: 0 50px;
  padding: 30px 40px;
  background-color: white;
  border-radius: 8px;
}
@media (max-width: 767px) {
  .pf-details-banner__info {
    position: static;
    padding: 20px 0;
    margin: 0;
  }
}
.pf-details-banner__info-left span {
  margin-right: 40px;
  font-size: 16px;
  color: var(--tp-grey-1);
}
@media (max-width: 767px),
  only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (min-width: 992px) and (max-width: 1199px) {
  .pf-details-banner__info-left span {
    display: block;
    margin-bottom: 10px;
  }
}
.pf-details-banner__info-left span b {
  color: var(--tp-common-black);
}
.pf-details-banner__info-right a {
  margin-right: 40px;
  font-size: 16px;
  font-weight: 400;
  color: var(--tp-theme-redical);
}
.pf-details-banner__info-right a span i {
  margin-left: 10px;
}
.pf-details-banner__info-right a:hover {
  color: var(--tp-theme-cornblue);
}

.tp-pf-detials-main p {
  font-size: 18px;
  color: var(--tp-grey-1);
  margin-bottom: 40px;
}

/*----------------------------------------*/
/* 13. GALLERY CSS
/*----------------------------------------*/
.tp-gl-item img {
  width: 100%;
}

@media (max-width: 767px) {
  .tp-mp-galley {
    padding-left: 0;
    padding-right: 0;
  }
}

/*----------------------------------------*/
/* 14. CTA CSS
/*----------------------------------------*/
.bs-cta-section__subtitle {
  font-size: 18px;
  padding: 9px 18px;
  border-radius: 18px;
  color: var(--tp-grey-1);
}
.bs-cta-section__title {
  font-size: 60px;
  letter-spacing: -2px;
  line-height: 1.225;
  font-weight: 400;
}
@media (max-width: 767px),
  only screen and (min-width: 768px) and (max-width: 991px) {
  .bs-cta-section__title br {
    display: none;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .bs-cta-section__title {
    font-size: 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bs-cta-section__title {
    font-size: 40px;
  }
}
@media (max-width: 767px) {
  .bs-cta-section__title {
    font-size: 30px;
    line-height: -1px;
  }
}

.cronblue-g-color span {
  color: var(--tp-theme-cornblue);
  margin-left: 0;
  margin-right: 10px;
}

.bs-cta-section {
  position: relative;
  z-index: 2;
}

.red-nots {
  position: absolute;
  top: 15px;
  left: 20px;
  z-index: 1;
  -webkit-animation: moving 5s ease-in-out infinite;
  animation: moving 5s ease-in-out infinite;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .red-nots img {
    width: 50%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .red-nots img {
    width: 30%;
  }
}
@media (max-width: 767px) {
  .red-nots img {
    width: 30%;
  }
}

.like-thumb {
  position: absolute;
  bottom: 100px;
  right: 240px;
}
.like-thumb img {
  border-radius: 50%;
  box-shadow: 0px 30px 40px 0px rgba(0, 0, 0, 0.08);
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .like-thumb {
    bottom: 150px;
    right: 100px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .like-thumb {
    right: 84px;
  }
}

@media (max-width: 767px) {
  .bs-cta-btns .tp-btn {
    margin-right: 0;
    margin-bottom: 30px;
  }
}

.like-thumb-border {
  bottom: 100px;
  position: absolute;
  width: 130px;
  height: 130px;
  -webkit-animation: rotate 15s linear infinite;
  animation: rotate 15s linear infinite;
  border: 2px dashed #d6e5e5;
  border-radius: 50%;
  position: absolute;
  bottom: 75px;
  right: 215px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .like-thumb-border {
    bottom: 122px;
    right: 75px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .like-thumb-border {
    right: 60px;
  }
}

@-webkit-keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.it-cta__info span {
  display: block;
  padding-bottom: 10px;
  margin-bottom: 10px;
  font-size: 18px;
  color: var(--tp-theme-vogue);
  position: relative;
}
@media (max-width: 576px) {
  .it-cta__info span {
    font-size: 13px;
  }
}
.it-cta__info span::after {
  content: "";
  left: 0;
  bottom: 0;
  position: absolute;
  background-color: #ececec;
  width: 313px;
  height: 1px;
  display: inline-block;
  margin-left: 38px;
}
@media (max-width: 576px) {
  .it-cta__info span::after {
    width: 200px;
  }
}
.it-cta__info span i {
  margin-right: 18px;
  color: var(--tp-theme-orange);
}
.it-cta__info span:last-child::after {
  display: none;
}

@media (max-width: 767px),
  only screen and (min-width: 768px) and (max-width: 991px) {
  .it-cta-form {
    margin-top: 50px;
  }
}
.it-cta-form .input-item {
  position: relative;
  font-size: 16px;
  margin-bottom: 20px;
}
.it-cta-form .input-item input,
.it-cta-form .input-item select,
.it-cta-form .input-item .nice-select {
  width: 100%;
  height: 60px;
  padding-left: 45px;
  padding-right: 10px;
  border-radius: 8px;
  border: 2px solid var(--tp-border-8);
  background-color: transparent;
}
.it-cta-form .input-item input::-webkit-input-placeholder,
.it-cta-form .input-item select::-webkit-input-placeholder,
.it-cta-form .input-item .nice-select::-webkit-input-placeholder {
  color: var(--tp-grey-5);
}
.it-cta-form .input-item input:-moz-placeholder,
.it-cta-form .input-item select:-moz-placeholder,
.it-cta-form .input-item .nice-select:-moz-placeholder {
  color: var(--tp-grey-5);
}
.it-cta-form .input-item input::-moz-placeholder,
.it-cta-form .input-item select::-moz-placeholder,
.it-cta-form .input-item .nice-select::-moz-placeholder {
  color: var(--tp-grey-5);
}
.it-cta-form .input-item input:-ms-input-placeholder,
.it-cta-form .input-item select:-ms-input-placeholder,
.it-cta-form .input-item .nice-select:-ms-input-placeholder {
  color: var(--tp-grey-5);
}
.it-cta-form .input-item input:focus,
.it-cta-form .input-item select:focus,
.it-cta-form .input-item .nice-select:focus {
  border-color: var(--tp-theme-vogue);
}
.it-cta-form .input-item span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 25px;
  color: var(--tp-theme-vogue);
}
.it-cta-form .input-item span.current {
  padding-left: 17px;
}

.input-item-textarea {
  position: relative;
  font-size: 16px;
  margin-bottom: 20px;
}
.input-item-textarea textarea {
  padding: 20px 45px;
  width: 100%;
  height: 200px;
  resize: none;
  border: 2px solid var(--tp-border-8);
  border-radius: 8px;
  background-color: transparent;
}
.input-item-textarea textarea::-webkit-input-placeholder {
  color: var(--tp-grey-5);
}
.input-item-textarea textarea:-moz-placeholder {
  color: var(--tp-grey-5);
}
.input-item-textarea textarea::-moz-placeholder {
  color: var(--tp-grey-5);
}
.input-item-textarea textarea:-ms-input-placeholder {
  color: var(--tp-grey-5);
}
.input-item-textarea textarea:focus {
  outline: 0;
  border-color: var(--tp-theme-vogue);
}
.input-item-textarea span {
  position: absolute;
  top: 34px;
  transform: translateY(-50%);
  left: 25px;
  color: var(--tp-theme-vogue);
}

.it-cta-form-submit {
  width: 100%;
  height: 60px;
  text-align: center;
  background-color: var(--tp-theme-orange);
  border: 2px solid var(--tp-border-8);
  border-radius: 8px;
  font-size: 16px;
  color: #ffffff;
  font-weight: 400;
  text-transform: uppercase;
}
.it-cta-form-submit:hover {
  background-color: var(--tp-theme-vogue);
}

.da-cta-area {
  background-size: contain;
  background-repeat: no-repeat;
  box-shadow: 0px 16px 20px 0px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .da-cta-info h4 {
    font-size: 21px;
  }
}
@media (max-width: 767px),
  only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 1200px) and (max-width: 1399px) {
  .da-cta-info h4 br {
    display: none;
  }
}

.mp-cta-right h3 {
  font-size: 40px;
  font-weight: 400;
  letter-spacing: -2px;
  color: #000;
  margin-bottom: 30px;
  line-height: 1.3;
}
@media (max-width: 767px) {
  .mp-cta-right h3 {
    font-size: 24px;
    letter-spacing: inherit;
  }
}

.mp-cta-left span {
  color: var(--tp-grey-4);
  display: inline-block;
  margin-bottom: 30px;
}
.mp-cta-left p {
  font-size: 18px;
  color: #000;
  line-height: 1.6;
}

.law-cta-contact-wrapper h1 {
  margin-bottom: 25px;
}

.ring-1 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 7%;
  opacity: 0.6;
}
@media (max-width: 767px) {
  .ring-1 {
    display: none;
  }
}
.ring-1 .ring-2 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  opacity: 0.6;
  text-align: center;
  -webkit-animation: tprotate 3s ease-in-out 1s forwards infinite alternate;
  animation: tprotate 3s ease-in-out 1s forwards infinite alternate;
}

.law-cta-area .tp-section__title {
  color: var(--tp-theme-wine);
  text-transform: uppercase;
}
.law-cta-area .it-cta__info span {
  color: var(--tp-theme-wine);
  text-transform: uppercase;
}
.law-cta-area .it-cta__info span i {
  color: var(--tp-theme-red);
}
.law-cta-area .it-cta__info .it-cta-form .input-item span {
  color: var(--tp-theme-red);
}

.price-page-cta-form .tp-section__title {
  color: var(--tp-common-black);
  text-transform: uppercase;
}
.price-page-cta-form .it-cta__info span {
  color: var(--tp-common-black);
  text-transform: uppercase;
}
.price-page-cta-form .it-cta__info span i {
  color: var(--tp-theme-redical);
}
.price-page-cta-form .it-cta__info .it-cta-form .input-item span {
  color: var(--tp-theme-redical);
}

.law-cta-form .input-item input:focus {
  border-color: var(--tp-theme-redical);
}
.law-cta-form .input-item .nice-select:focus {
  border-color: var(--tp-theme-redical);
}
.law-cta-form .input-item span {
  color: var(--tp-theme-wine);
}
.law-cta-form .input-item-textarea span {
  color: var(--tp-theme-wine);
}
.law-cta-form .input-item-textarea textarea:focus {
  border-color: var(--tp-theme-redical);
}
.law-cta-form .it-cta-form-submit {
  background-color: var(--tp-theme-red);
}
.law-cta-form .it-cta-form-submit:hover {
  background-color: var(--tp-theme-wine);
}

.price-cta-form .input-item span {
  color: var(--tp-theme-black);
}
.price-cta-form .input-item-textarea span {
  color: var(--tp-theme-black);
}
.price-cta-form .it-cta-form-submit {
  background-color: var(--tp-theme-cornblue);
}
.price-cta-form .it-cta-form-submit:hover {
  background-color: var(--tp-theme-redical);
}

.about-cta-form {
  background-size: cover;
  background-repeat: no-repeat;
}
.about-cta-form .input-item input {
  background-color: white;
}
.about-cta-form .input-item span {
  color: var(--tp-theme-black);
}
.about-cta-form .input-item-textarea textarea {
  background-color: white;
}
.about-cta-form .input-item-textarea span {
  color: var(--tp-theme-black);
}
.about-cta-form .it-cta-form-submit {
  background-color: var(--tp-theme-cornblue);
}
.about-cta-form .it-cta-form-submit:hover {
  background-color: var(--tp-theme-redical);
}

.ca-cta-title span {
  color: white;
}
.ca-cta-title b.is-hidden,
.ca-cta-title b.is-visable {
  color: white;
}

.ca-cta-section-title.loading-bar b.is-visible {
  color: white;
}

.ca-cta-section-subtitle {
  position: relative;
  background-image: linear-gradient(90deg, #5a6ef8, #fe7a8f);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.ca-cta-avata {
  position: absolute;
  top: 60%;
  left: 10%;
  transform: translateY(-50%);
  animation: moving 5s ease-in-out infinite;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ca-cta-avata {
    top: 43%;
  }
}
@media (max-width: 767px) {
  .ca-cta-avata {
    display: none;
  }
}

.ca-cta-avata-2 {
  position: absolute;
  top: 60%;
  right: 10%;
  transform: translateY(-50%);
  animation: moving 5s ease-in-out infinite;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ca-cta-avata-2 {
    top: 45%;
  }
}
@media (max-width: 767px) {
  .ca-cta-avata-2 {
    display: none;
  }
}
.ca-cta-avata-2::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    100.16deg,
    #5a6ef8 9.94%,
    #a874c6 54.82%,
    #fe7a8f 95.56%
  );
  border-radius: 50%;
  top: 10px;
  left: 10px;
  animation: moving 5s ease-in-out infinite;
  z-index: -1;
}

@media (max-width: 767px) {
  .tp-grd-btn {
    margin-right: 0;
    margin-bottom: 30px;
  }
}

.tp-ab-cta-overlay {
  position: relative;
}
.tp-ab-cta-overlay::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.9;
}

.bs-cta-btns {
  z-index: 2;
}

.tp-inner-pg-form [type="file"] {
  height: 0;
  overflow: hidden;
  width: 0;
}
.tp-inner-pg-form [type="file"] + label {
  border: none;
  border-radius: 8px;
  color: var(--tp-theme-black);
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  display: inline-block;
  outline: none;
  transition: all 0.3s;
  background-color: #f6f6f6;
  width: 100%;
  height: 60px;
  line-height: 60px;
  margin-bottom: 30px;
  transform: translateY(-8px);
}
.tp-inner-pg-form .it-cta-form-submit {
  background-color: var(--tp-theme-cornblue);
}
.tp-inner-pg-form .it-cta-form-submit:hover {
  background-color: var(--tp-theme-redical);
}
.tp-inner-pg-form .it-cta-form .input-item span,
.tp-inner-pg-form .input-item-textarea span {
  color: var(--tp-theme-black);
}

.contact-cta-item b {
  display: inline-block;
  margin-bottom: 20px;
}
.contact-cta-item a {
  font-size: 20px;
  color: var(--tp-grey-1);
}

.tp-contact-map iframe {
  width: 100%;
  height: 100vh;
}

.contact-map-img {
  position: absolute;
  top: 50%;
  transform: translateZ(-50%);
  left: 0;
  right: 0;
  text-align: center;
  display: inline-block;
}
.contact-map-img img {
  animation: rotate 15s linear infinite;
}

.say-hello {
  color: var(--tp-theme-redical);
  font-size: 18px;
  font-weight: 400;
}
@media (max-width: 767px),
  only screen and (min-width: 768px) and (max-width: 991px) {
  .say-hello {
    margin-bottom: 30px;
    display: inline-block;
  }
}

.tp-help-form-text {
  font-size: 24px;
}
@media (max-width: 767px),
  only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-help-form-text {
    margin-bottom: 30px;
    display: inline-block;
  }
}

.tp-help-search input {
  height: 60px;
  width: 100%;
  border: 2px solid #f6f6f6;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
  padding-left: 50px;
  padding-right: 50px;
  font-size: 16px;
  border-radius: 6px;
  color: var(--tp-grey-5);
  padding-right: 250px;
}
.tp-help-search input::-webkit-input-placeholder {
  color: var(--tp-grey-5);
}
.tp-help-search input:-moz-placeholder {
  color: var(--tp-grey-5);
}
.tp-help-search input::-moz-placeholder {
  color: var(--tp-grey-5);
}
.tp-help-search input:-ms-input-placeholder {
  color: var(--tp-grey-5);
}
.tp-help-search input:focus {
  border-color: var(--tp-theme-redical);
}
@media (max-width: 767px) {
  .tp-help-search input {
    padding-right: 20px;
  }
}
.tp-help-search-form-icon {
  display: inline-block;
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
}
@media (max-width: 576px) {
  .tp-help-search-form-icon {
    top: 30px;
  }
}
.tp-help-search .tp-btn {
  padding: 13px 29px;
}

.help-search-btn {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}
@media (max-width: 576px) {
  .help-search-btn {
    position: static;
    transform: translateY(0);
    margin-top: 30px;
  }
}

.input-item .nice-select:after {
  height: auto;
  width: auto;
  border: 0;
  content: "\f107";
  font-family: var(--tp-ff-fontawesome);
  position: absolute;
  right: 15px;
  top: 50%;
  transform-origin: 0;
  transform: translateY(-50%) rotate(0);
  padding: 0;
  margin: 0;
  color: var(--tp-theme-orange);
}
.input-item .nice-select ul.list {
  width: 100%;
}

/*----------------------------------------*/
/* 15. FAQ CSS
/*----------------------------------------*/
@media only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .app-faq-img {
    margin-bottom: 30px;
  }
}
.app-faq-img img {
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
}
@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .app-faq-img img {
    width: 100%;
  }
}

.faq-vidoe-play-btn {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  text-align: center;
  width: 120px;
  height: 120px;
  margin: 0 auto;
  transform: translateY(-50%);
  background-color: white;
  border-radius: 50%;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06);
  line-height: 120px;
  font-size: 20px;
  color: var(--tp-theme-mandalay);
  animation: pulse 2s infinite;
}
@media (max-width: 767px) {
  .faq-vidoe-play-btn {
    font-size: 13px;
    width: 60px;
    height: 60px;
    line-height: 60px;
  }
}
.faq-vidoe-play-btn:hover {
  color: var(--tp-theme-mandalay);
}

.tp-ha-faq-area .tp-accordion .accordion-button::after {
  color: black;
  background-color: #f1f1f1;
}
.tp-ha-faq-area .tp-accordion .accordion-button:not(.collapsed)::after {
  color: white;
  background-color: var(--tp-theme-mandalay);
}

@media only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (min-width: 992px) and (max-width: 1199px) {
  .seo-faq-cotent {
    margin-bottom: 60px;
  }
}

.seo-faq-area .tp-accordion .accordion-button {
  border: 1px solid #e6f3fb;
}
.seo-faq-area .tp-accordion .accordion-button::after {
  color: var(--tp-theme-lochmara);
  background-color: rgba(6, 134, 216, 0.102);
}
.seo-faq-area .tp-accordion .accordion-button:not(.collapsed) {
  border: 1px solid #e6f3fb;
}
.seo-faq-area .tp-accordion .accordion-button:not(.collapsed)::after {
  color: white;
  background-color: var(--tp-theme-lochmara);
}

.sv-fea-area .tp-accordion .accordion-button {
  border: 1px solid #efefef;
}
.sv-fea-area .tp-accordion .accordion-button::after {
  color: var(--tp-theme-cornblue);
  background-color: rgba(108, 96, 254, 0.102);
}
.sv-fea-area .tp-accordion .accordion-button:not(.collapsed) {
  border: 1px solid #efefef;
}
.sv-fea-area .tp-accordion .accordion-button:not(.collapsed)::after {
  color: white;
  background-color: var(--tp-theme-cornblue);
}

@media (max-width: 767px),
  only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (min-width: 992px) and (max-width: 1199px) {
  .seo-faq-img img {
    max-width: 100%;
  }
}

.seo-yt-btn {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
}
@media (max-width: 767px),
  only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (min-width: 992px) and (max-width: 1199px) {
  .seo-yt-btn {
    text-align: center;
  }
}

/*----------------------------------------*/
/* 16. JOB CSS
/*----------------------------------------*/
.tp-job-item {
  border-radius: 8px;
  padding: 30px 40px;
  margin-bottom: 80px;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  margin-top: -50px;
}
@media (max-width: 767px) {
  .tp-job-item {
    padding: 30px;
  }
}
.tp-job-item__info span {
  color: #757c80;
  display: inline-block;
  margin-bottom: 3px;
}
@media (max-width: 767px) {
  .tp-job-item__info {
    margin-bottom: 30px;
  }
}
.tp-job-item__title {
  font-size: 20px;
}
.tp-job-item__title a {
  background-image: linear-gradient(#fff, #fff), linear-gradient(#fff, #fff);
  display: inline;
  background-size: 0% 1px, 0 1px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.4s linear;
}
.tp-job-item__title a:hover {
  background-size: 0% 1px, 100% 1px;
}
.tp-job-item__btn {
  color: #b2b8bc;
  font-weight: 400;
}
.tp-job-item__btn :hover {
  color: #ccc8ff;
}
.tp-job-item__btn i {
  padding-left: 8px;
  padding-top: 5px;
  display: inline-block;
}
.tp-job-item:hover {
  background-color: var(--tp-theme-cornblue);
}
.tp-job-item:hover .tp-job-item__info span {
  color: #ccc8ff;
}
.tp-job-item:hover .tp-job-item__title {
  color: #fff;
}
.tp-job-item:hover .tp-job-item__btn {
  color: #fff;
}

.tp-job-details-box {
  border-style: solid;
  border-width: 2px;
  border-color: #efefef;
  border-radius: 8px;
}
@media (max-width: 767px),
  only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-job-details-box {
    padding: 30px 15px;
  }
}

.tp-inner-pg-list ul li {
  font-size: 18px;
  position: relative;
  padding-left: 20px;
  margin-bottom: 15px;
  color: #757c80;
}
.tp-inner-pg-list ul li::before {
  content: "";
  width: 4px;
  height: 4px;
  position: absolute;
  left: 0;
  top: 10px;
  background-color: rgb(7, 7, 7);
  display: inline-block;
  border-radius: 50%;
}

.job-about-cicle-img img {
  animation: rotate 15s linear infinite;
}

/*----------------------------------------*/
/* 17. HELP CSS
/*----------------------------------------*/
.tp-help-item {
  padding: 50px;
  border: 2px solid #f6f6f6;
  margin-bottom: 30px;
  border-radius: 8px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
@media (max-width: 767px) {
  .tp-help-item {
    padding: 50px 15px;
  }
}
.tp-help-item__icon {
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06);
  width: 140px;
  height: 140px;
  display: inline-block;
  margin-bottom: 35px;
  border-radius: 50%;
}
.tp-help-item__icon span {
  font-size: 60px;
  display: inline-block;
  text-align: center;
  line-height: 140px;
  color: var(--tp-theme-cornblue);
}
.tp-help-item__title {
  font-size: 24px;
}
.tp-help-item__title a {
  background-image: linear-gradient(#000, #000), linear-gradient(#000, #000);
  display: inline;
  background-size: 0% 1px, 0 1px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.4s linear;
}
.tp-help-item__title a:hover {
  background-size: 0% 1px, 100% 1px;
}
.tp-help-item p {
  font-size: 16px;
  color: var(--tp-grey-1);
}
.tp-help-item:hover {
  border-color: var(--tp-theme-cornblue);
}
.tp-help-item:hover .tp-help-item__icon span {
  animation-name: wobble-vertical;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}

.bg {
  border-style: solid;
  border-width: 2px;
  border-color: #f6f6f6;
  border-radius: 8px;
  background-color: rgb(255, 255, 255);
  position: absolute;
  left: 311px;
  top: 745px;
  width: 414px;
  height: 372px;
  z-index: 84;
}

/*----------------------------------------*/
/* 18. PRICE CSS
/*----------------------------------------*/
.tp-price-area {
  background-image: -moz-linear-gradient(
    0deg,
    rgb(239, 237, 226) 0%,
    rgb(208, 239, 254) 100%
  );
  background-image: -webkit-linear-gradient(
    0deg,
    rgb(239, 237, 226) 0%,
    rgb(208, 239, 254) 100%
  );
  background-image: -ms-linear-gradient(
    0deg,
    rgb(239, 237, 226) 0%,
    rgb(208, 239, 254) 100%
  );
}

.tp-price-box {
  padding: 30px 50px 50px 50px;
  border-radius: 8px;
}
@media (max-width: 576px) {
  .tp-price-box {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.tp-price-box.app-box-shadow {
  box-shadow: 0px 16px 32px 0px rgba(23, 39, 187, 0.06);
}
.tp-price-box.active .tp-grd-border-btn {
  color: var(--tp-common-white);
}
.tp-price-box.active .tp-grd-border-btn::after {
  opacity: 0;
}
.tp-price-box.active .tp-price-box__star {
  background-image: -webkit-linear-gradient(180deg, #fe7a8f, #5a6ef8);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.tp-price-box.active .tp-price-box__star.color-redical {
  -webkit-text-fill-color: var(--tp-theme-redical);
}
.tp-price-box__star {
  position: absolute;
  top: 20px;
  right: 20px;
  color: var(--tp-border-7);
}
.tp-price-box__info b {
  font-size: 80px;
  position: relative;
  padding-left: 25px;
}
.tp-price-box__info b span {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 40px;
  color: var(--tp-theme-cornblue);
}
.tp-price-box__info-title {
  font-size: 24px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-price-box__info-title {
    font-size: 20px;
  }
}
.tp-price-box__info span {
  font-size: 16px;
  color: var(--tp-grey-1);
}

.tp-price-fea-list ul li {
  position: relative;
  padding-left: 35px;
  margin-bottom: 10px;
  font-size: 18px;
  color: var(--tp-grey-1);
}
.tp-price-fea-list ul li:last-child {
  margin-bottom: 0;
}
.tp-price-fea-list ul li b {
  color: var(--tp-common-black);
}
.tp-price-fea-list ul li i {
  position: absolute;
  left: 0;
  top: 5px;
  background-image: -webkit-linear-gradient(180deg, #fe7a8f, #5a6ef8);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.tp-price-fea-list.app-list ul li {
  position: relative;
  padding-left: 35px;
  margin-bottom: 10px;
  font-size: 18px;
  color: var(--tp-grey-1);
}
.tp-price-fea-list.app-list ul li:last-child {
  margin-bottom: 0;
}
.tp-price-fea-list.app-list ul li b {
  color: var(--tp-theme-mirage);
}
.tp-price-fea-list.app-list ul li i {
  position: absolute;
  left: 0;
  top: 5px;
  background-image: -webkit-linear-gradient(180deg, #fe7a8f, #5a6ef8);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: var(--tp-theme-cornblue);
}

/*----------------------------------------*/
/* 19. FEATURE CSS 
/*----------------------------------------*/
.da-feature-list-top {
  background-image: linear-gradient(
    90deg,
    rgb(239, 237, 226) 0%,
    rgb(208, 239, 254) 100%
  );
}

.da-feature-item-top span {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--tp-common-white);
  text-align: center;
  margin-right: 20px;
  display: inline-block;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.06);
}
.da-feature-item-top i {
  font-size: 18px;
  line-height: 40px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.06);
  background-image: -webkit-linear-gradient(180deg, #fe7a8f, #5a6ef8);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.da-feature-item-top b {
  font-size: 18px;
  font-weight: 400;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .da-feature-item-top b {
    font-size: 14px;
  }
}

.app-feature-box {
  padding: 45px 50px;
  margin-bottom: 30px;
  border: 2px solid transparent;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
@media (max-width: 576px) {
  .app-feature-box {
    padding: 45px 15px;
    flex-direction: column;
  }
}
.app-feature-box:hover {
  border: 2px solid var(--tp-theme-cornblue);
}
.app-feature-box:hover .app-feature__arrow {
  opacity: 1;
  top: 20px;
}
.app-feature-box-icon {
  margin-right: 30px;
}
@media (max-width: 576px) {
  .app-feature-box-icon {
    margin-left: 0;
    margin-bottom: 20px;
  }
}
.app-feature-box-icon img {
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
}
.app-feature-box-icon i {
  font-size: 32px;
}
.app-feature__arrow {
  position: absolute;
  top: 35px;
  right: 20px;
  font-size: 24px;
  transform: rotate(-50deg);
  color: var(--tp-theme-cornblue);
  opacity: 0;
  visibility: visible;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.app-feature__arrow:hover {
  color: var(--tp-theme-redical);
}
.app-feature__info span {
  color: var(--tp-grey-1);
  font-size: 16px;
}
.app-feature__title:hover {
  color: var(--tp-theme-cornblue);
}

.seo-feature-area .tp-section p {
  color: #93adbb;
}

@media (max-width: 767px),
  only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (min-width: 992px) and (max-width: 1199px) {
  .seo-feature-item {
    margin-bottom: 60px;
  }
}
.seo-feature-item.up {
  transform: translateY(-60px);
}
@media (max-width: 767px),
  only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (min-width: 992px) and (max-width: 1199px) {
  .seo-feature-item.up {
    transform: translateY(0);
  }
}
.seo-feature-item__icon {
  width: 180px;
  height: 180px;
  border: 2px dashed #1c5779;
  border-radius: 50%;
  margin: 0 auto;
}
.seo-feature-item__content {
  padding-left: 45px;
  padding-right: 45px;
}
.seo-feature-item__content-title {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
}
.seo-feature-item__content-title a {
  background-image: linear-gradient(#fff, #fff), linear-gradient(#fff, #fff);
  display: inline;
  background-size: 0% 1px, 0 1px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.4s linear;
}
.seo-feature-item__content-title a:hover {
  background-size: 0% 1px, 100% 1px;
}
.seo-feature-item__content p {
  color: #93adbb;
}

.seo-fea-line-share {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  text-align: center;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .seo-fea-line-share {
    top: 47%;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .seo-fea-line-share img {
    width: 80%;
  }
}

.seo-circle-bg {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  width: 150px;
  height: 150px;
  transform: translateY(-50%);
  margin: 0 auto;
  background-color: var(--tp-theme-lochmara);
  line-height: 150px;
  border-radius: 50%;
}
.seo-circle-bg i {
  color: #fff;
  font-size: 50px;
}
.seo-circle-bg i::before {
  line-height: inherit;
}

.seo-fea-shapes .icon-left {
  position: absolute;
  top: 37%;
  font-size: 40px;
  left: 10%;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #195576;
  text-align: center;
  line-height: 80px;
  color: white;
  animation: pulse 2s infinite;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 1400px) and (max-width: 1600px) {
  .seo-fea-shapes .icon-left {
    left: 4%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (min-width: 992px) and (max-width: 1199px) {
  .seo-fea-shapes .icon-left {
    top: 70%;
    left: 5%;
  }
}
@media (max-width: 767px) {
  .seo-fea-shapes .icon-left {
    display: none;
  }
}
.seo-fea-shapes .icon-right {
  position: absolute;
  top: 20%;
  font-size: 40px;
  right: 10%;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #195576;
  text-align: center;
  line-height: 80px;
  color: white;
  animation: pulse 2.5s infinite;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 1400px) and (max-width: 1600px) {
  .seo-fea-shapes .icon-right {
    top: 24%;
    right: 4%;
  }
}
@media (max-width: 767px) {
  .seo-fea-shapes .icon-right {
    display: none;
  }
}

.about-feature-item {
  border-radius: 8px;
  box-shadow: 0px 30px 40px 0px rgba(0, 0, 0, 0.04);
}
.about-feature-item .app-feature-box-icon {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.about-feature-item:hover {
  border-color: transparent;
}
.about-feature-item:hover .app-feature-box-icon {
  transform: translateY(-15px) rotateY(180deg);
}

.ab-megapone-icon {
  background-color: #f4f3ff;
  display: inline-block;
  width: 140px;
  height: 140px;
  line-height: 140px;
  border-radius: 50%;
  color: var(--tp-theme-cornblue);
  animation: pulse 2s infinite;
  margin-bottom: 35px;
  margin-right: 0;
}
.ab-megapone-icon i {
  line-height: inherit;
}

.ab-computer-icon {
  background-color: #fff0f2;
  display: inline-block;
  width: 140px;
  height: 140px;
  line-height: 140px;
  border-radius: 50%;
  color: var(--tp-theme-redical);
  animation: pulse 2s infinite;
  margin-bottom: 35px;
  margin-right: 0;
}
.ab-computer-icon i {
  line-height: inherit;
}

.ab-bubble-icon {
  background-color: #f4f3ff;
  display: inline-block;
  width: 140px;
  height: 140px;
  line-height: 140px;
  border-radius: 50%;
  color: #3c41ff;
  animation: pulse 2s infinite;
  margin-bottom: 35px;
  margin-right: 0;
}
.ab-bubble-icon i {
  line-height: inherit;
}

.sv-page-fea-img-1 {
  position: absolute;
  width: 50%;
  height: 100%;
  top: 0;
  right: 0;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (max-width: 767px),
  only screen and (min-width: 768px) and (max-width: 991px) {
  .sv-page-fea-img-1 {
    position: static;
    width: 100%;
    height: 500px;
    margin-bottom: 60px;
  }
}

.sv-page-fea-img-2 {
  position: absolute;
  width: 50%;
  height: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (max-width: 767px),
  only screen and (min-width: 768px) and (max-width: 991px) {
  .sv-page-fea-img-2 {
    position: static;
    width: 100%;
    height: 500px;
    margin-bottom: 60px;
  }
}

@media (max-width: 767px),
  only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-sv-page-feature-area {
    padding-top: 0;
  }
}

@media (max-width: 767px),
  only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-sv-fea-ab-content {
    margin-right: 0;
    margin-left: 0;
  }
}
.tp-sv-fea-ab-content .tp-sv-fea-list li {
  color: #d9d9d9;
}
.tp-sv-fea-ab-content .tp-sv-fea-list li span {
  background-color: transparent;
  border: 1px solid #232323;
}
.tp-sv-fea-ab-content .tp-sv-fea-list li span i {
  color: var(--tp-theme-redical);
}

.sv-details-fea-item {
  position: relative;
  border: 1px solid hsla(0deg, 0%, 100%, 0.1);
  padding: 30px;
}
.sv-details-fea-item__title {
  font-size: 18px;
  color: #fff;
}
.sv-details-fea-item__arrow {
  position: absolute;
  top: 10px;
  color: #3b3b3b;
  right: 15px;
  transform: rotate(-45deg);
  font-size: 24px;
}

.pf-dt-process-item__border {
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  width: 180px;
  border: 2px dashed #e5e5e5;
  height: 180px;
  border-radius: 50%;
  z-index: -1;
  display: inline-block;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.pf-dt-process-item__icon {
  position: absolute;
  font-size: 50px;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: auto;
  background-color: var(--tp-theme-redical);
  width: 150px;
  height: 150px;
  color: var(--tp-common-white);
  line-height: 150px;
  border-radius: 50%;
  display: inline-block;
}
.pf-dt-process-item__icon.cornblue-bg {
  background-color: var(--tp-theme-cornblue);
}
.pf-dt-process-item__icon.orange-bg {
  background-color: #ff823e;
}
.pf-dt-process-item__icon.blue-bg {
  background-color: #065fd8;
}
.pf-dt-process-item__title {
  font-size: 18px;
  font-weight: 600;
}
.pf-dt-process-item__title a {
  background-image: linear-gradient(#000, #000), linear-gradient(#000, #000);
  display: inline;
  background-size: 0% 1px, 0 1px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.4s linear;
}
.pf-dt-process-item__title a:hover {
  background-size: 0% 1px, 100% 1px;
}

/*----------------------------------------*/
/* 20. NEWSLETTER CSS 
/*----------------------------------------*/
.tp-app-newsletter {
  background-repeat: no-repeat;
  background-size: cover;
}

.app-newsletter-img {
  margin-left: -110px;
}
@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .app-newsletter-img {
    margin-left: -50px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .app-newsletter-img {
    margin-left: -25px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .app-newsletter-img {
    margin-left: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .app-newsletter-img {
    margin-left: 0;
    margin-bottom: 25px;
  }
}
.app-newsletter-img img {
  border-radius: 20px 20px 0 0;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .app-newsletter-img img {
    max-width: 100%;
  }
}

.app-hero__subscribe-input input {
  font-size: 16px;
  width: 100%;
  height: 70px;
  padding-left: 50px;
  padding-right: 200px;
  border: 0;
  background-color: var(--tp-common-white);
  border-radius: 8px;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06);
}
@media (max-width: 576px) {
  .app-hero__subscribe-input input {
    padding-right: 50px;
    height: 60px;
  }
}
.app-hero__subscribe-input input::-webkit-input-placeholder {
  color: #bbbbbb;
}
.app-hero__subscribe-input input:-moz-placeholder {
  color: #bbbbbb;
}
.app-hero__subscribe-input input::-moz-placeholder {
  color: #bbbbbb;
}
.app-hero__subscribe-input input:-ms-input-placeholder {
  color: #bbbbbb;
}
.app-hero__subscribe-input i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 30px;
  color: var(--tp-theme-mirage);
  display: inline-block;
}

@media (max-width: 767px) {
  .app-hero-feature {
    padding-bottom: 50px;
  }
}
@media (max-width: 576px) {
  .app-hero-feature span {
    display: block;
    margin-bottom: 10px;
  }
}
.app-hero-feature__review {
  font-size: 18px;
  padding-right: 18px;
  color: var(--tp-common-white);
}
.app-hero-feature__review b {
  color: var(--tp-common-black);
}
.app-hero-feature__review i {
  color: var(--tp-common-white);
  margin-right: 5px;
}
.app-hero-feature__credit {
  color: var(--tp-common-white);
  font-size: 18px;
  padding-left: 18px;
}
@media (max-width: 576px) {
  .app-hero-feature__credit {
    padding-left: 0;
    border-left: 0;
  }
}
.app-hero-feature__credit i {
  color: var(--tp-common-white);
  margin-right: 5px;
}

@media (max-width: 576px) {
  .app-hero__subscribe-box .hero-submit-btn {
    position: static;
    margin-top: 50px;
    width: 100%;
  }
}

.tp-seo-newsletter {
  background-repeat: no-repeat;
  background-position: right;
}

.seo-nl-feature span {
  font-size: 18px;
  padding-right: 18px;
  font-weight: 600;
  color: var(--tp-theme-seagreen);
  margin-bottom: 5px;
  display: block;
}
.seo-nl-feature span i {
  margin-right: 7px;
  color: var(--tp-theme-atlantis);
}

.seo_newsletter-form input {
  border-radius: 35px;
}
.seo_newsletter-form .app-hero__subscribe-input i {
  color: var(--tp-theme-seagreen);
}
.seo_newsletter-form .hero-submit-btn {
  border-radius: 35px;
  background-color: var(--tp-theme-lochmara);
}
.seo_newsletter-form .hero-submit-btn:hover {
  background-color: var(--tp-theme-atlantis);
}
@media (max-width: 767px) {
  .seo_newsletter-form {
    margin-right: 0;
  }
}

/*----------------------------------------*/
/* 21. SEARCH CCSS 
/*----------------------------------------*/
.tp-search-item {
  padding-right: 80px;
  margin-bottom: 60px;
}
@media (max-width: 767px) {
  .tp-search-item {
    padding-right: 0;
  }
}
.tp-search-item-meta span {
  color: var(--tp-common-black);
}
.tp-search-item-meta a {
  color: var(--tp-theme-cornblue);
  font-weight: 400;
  margin-bottom: 10px;
  display: inline-block;
}
.tp-search-item-meta .spt {
  color: var(--tp-grey-1);
}
.tp-search-item__title {
  font-size: 24px;
}

/*----------------------------------------*/
/*  22. BLOG CSS
/*----------------------------------------*/
.it-blog__thumb {
  position: relative;
}
.it-blog__thumb .fix {
  border-radius: 8px 8px 0 0;
}
.it-blog-date {
  position: absolute;
  top: -20px;
  right: 20px;
  background-color: #fff;
  border-radius: 8px;
  text-align: center;
  color: var(--tp-grey-1);
  padding: 11px 19px;
  border-bottom: 4px solid var(--tp-theme-orange);
  box-shadow: 0px -16px 32px 0px rgba(0, 0, 0, 0.06);
}
.it-blog-date b {
  display: block;
  font-size: 24px;
  color: var(--tp-theme-vogue);
  line-height: 1;
}
.it-blog__meta {
  margin-bottom: 10px;
}
.it-blog__meta span a {
  color: #c6c6c6;
}
.it-blog__meta span .it-blog-cta {
  color: var(--tp-theme-orange);
}
.it-blog__meta span .it-blog-cta:hover {
  color: var(--tp-theme-vogue);
}
.it-blog__meta span b {
  color: var(--tp-theme-vogue);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.it-blog__meta span b:hover {
  color: var(--tp-theme-orange);
}
.it-blog__title {
  font-size: 24px;
  color: var(--tp-theme-vogue);
  margin-bottom: 15px;
  text-transform: capitalize;
}
.it-blog__title.fz-20 {
  font-size: 20px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .it-blog__title.fz-20 {
    font-size: 18px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .it-blog__title {
    font-size: 21px;
  }
}
.it-blog__title a {
  background-image: linear-gradient(#000, #000), linear-gradient(#000, #000);
  display: inline;
  background-size: 0% 1px, 0 1px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.4s linear;
}
.it-blog__title a:hover {
  background-size: 0 1px, 100% 1px;
}
.it-blog p {
  color: var(--tp-grey-1);
}
.it-blog:hover {
  box-shadow: 0px -16px 32px 0px rgba(0, 0, 0, 0.06);
}
.it-blog:hover .it-blog__thumb img {
  transform: scale(1.1);
}

.app-blog {
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.04);
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
  border-radius: 6px;
}
.app-blog:hover {
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.04);
  border-color: rgb(243, 243, 243);
}
.app-blog .text-mirage {
  color: var(--tp-theme-mirage);
}

.it-blog-info {
  padding: 25px;
  border-radius: 0 0 8px 8px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .it-blog-info {
    padding: 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .it-blog-info {
    padding: 30px 20px;
  }
}
@media (max-width: 767px) {
  .it-blog-info {
    padding: 30px 20px;
  }
}

.tp-app-blog .it-blog__meta span b {
  color: var(--tp-theme-mirage);
}
.tp-app-blog .it-blog__meta span b:hover {
  color: var(--tp-theme-redical);
}
.tp-app-blog .it-blog__meta span b.it-blog-cta {
  color: var(--tp-theme-redical);
}
.tp-app-blog .it-blog__meta span b.it-blog-cta:hover {
  color: var(--tp-theme-mirage);
}

.app-blog-info {
  padding: 20px 10px 20px 20px;
  border-radius: 0 0 8px 8px;
}
.app-blog-info a.blog-btn {
  color: #b4b4b5;
}
.app-blog-info a.blog-btn:hover {
  color: var(--tp-common-black);
}
.app-blog-info a.blog-btn:hover i {
  color: var(--tp-theme-cornblue);
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .app-blog-info {
    padding: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .app-blog-info {
    padding: 15px 10px;
  }
}
@media (max-width: 767px) {
  .app-blog-info {
    padding: 20px 10px;
  }
}

.da-blog {
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
}
.da-blog__thumb {
  position: relative;
}
.da-blog__thumb .fix {
  border-radius: 8px 8px 0 0;
}
.da-blog-date {
  position: absolute;
  top: -20px;
  right: 20px;
  background-image: linear-gradient(-90deg, #fe7a8f, #5a6ef8);
  box-shadow: 0px -16px 32px 0px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  text-align: center;
  color: var(--tp-grey-1);
  padding: 11px 19px;
  z-index: 9;
}
.da-blog-date::after {
  position: absolute;
  bottom: 3px;
  left: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  content: "";
  display: inline-block;
  background-color: white;
  border-radius: 5px;
  z-index: -1;
}
.da-blog-date b {
  display: block;
  font-size: 36px;
  color: #000;
  line-height: 1;
}
.da-blog__meta {
  margin-bottom: 10px;
  color: #c6c6c6;
}
.da-blog__meta span .da-blog-cta {
  background-image: linear-gradient(90deg, #5a6ef8, #fe7a8f);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}
.da-blog__meta span .da-blog-cta:hover {
  background-image: linear-gradient(-90deg, #5a6ef8, #fe7a8f);
}
.da-blog__meta span b {
  color: var(--tp-common-black);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.da-blog__meta span b:hover {
  color: var(--tp-theme-vogue);
}
.da-blog__title {
  font-size: 24px;
  color: #000;
  margin-bottom: 15px;
  text-transform: capitalize;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .da-blog__title {
    font-size: 21px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .da-blog__title {
    font-size: 21px;
  }
}
@media (max-width: 767px) {
  .da-blog__title {
    font-size: 21px;
  }
}
.da-blog__title a {
  background-image: linear-gradient(#000, #000), linear-gradient(#000, #000);
  display: inline;
  background-size: 0% 1px, 0 1px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.4s linear;
}
.da-blog__title a:hover {
  background-size: 0 1px, 100% 1px;
}
.da-blog p {
  color: var(--tp-grey-1);
}
.da-blog:hover .da-blog-info::after {
  left: 2px;
  right: 2px;
  bottom: 2px;
}
.da-blog:hover .da-blog__thumb img {
  transform: scale(1.1);
}

.da-blog-info {
  position: relative;
  padding: 40px;
  border-radius: 0 0 8px 8px;
  background-color: #fff;
  z-index: 2;
  background-image: linear-gradient(-90deg, #fe7a8f, #5a6ef8);
  transition: none;
}
.da-blog-info::after {
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  display: block;
  border-radius: 0 0 6px 6px;
  background-color: #fff;
  z-index: -1;
  opacity: 1;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .da-blog-info {
    padding: 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .da-blog-info {
    padding: 30px 20px;
  }
}
@media (max-width: 767px) {
  .da-blog-info {
    padding: 30px 20px;
  }
}

.blog-inner-zin {
  z-index: 5;
}

.ptg-blog-area {
  position: relative;
  z-index: 1;
}

.ptg-blog__item {
  position: relative;
}
.ptg-blog__item::after {
  position: absolute;
  content: "";
  left: -2px;
  right: -2px;
  top: -2px;
  bottom: -2px;
  display: inline-block;
  background-image: linear-gradient(
    100.16deg,
    #5a6ef8 9.94%,
    #a874c6 54.82%,
    #fe7a8f 95.56%
  );
  z-index: 3;
  border-radius: 6px;
  opacity: 0;
}
.ptg-blog__item-thumb {
  position: relative;
  z-index: 4;
  overflow: hidden;
}
.ptg-blog__item-thumb img {
  border-radius: 6px 6px 0 0;
}
.ptg-blog__item-content {
  position: relative;
  padding: 40px;
  border-radius: 0 0 6px 6px;
  z-index: 99;
}
@media (max-width: 767px) {
  .ptg-blog__item-content {
    padding: 40px 15px;
  }
}
.ptg-blog__item-meta {
  color: white;
  margin-bottom: 10px;
}
.ptg-blog__item-meta a {
  text-transform: uppercase;
  font-size: 14px;
}
@media (max-width: 576px) {
  .ptg-blog__item-meta a {
    font-size: 12px;
  }
}
.ptg-blog__item-meta a:hover {
  opacity: 0.9;
}
.ptg-blog__item-meta a b.ptg-blog-cta {
  background-image: linear-gradient(90deg, #5a6ef8, #fe7a8f);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.ptg-blog__item-title {
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: -1px;
  color: white;
  text-transform: uppercase;
  margin-bottom: 25px;
}
@media (max-width: 767px) {
  .ptg-blog__item-title {
    font-size: 18px;
    letter-spacing: 0;
  }
}
@media (max-width: 576px) {
  .ptg-blog__item-title {
    font-size: 16px;
  }
}
.ptg-blog__item-title a {
  background-image: linear-gradient(#fff, #fff), linear-gradient(#fff, #fff);
  display: inline;
  background-size: 0% 1px, 0 1px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.4s linear;
}
.ptg-blog__item-title a:hover {
  background-size: 0% 1px, 100% 1px;
}
.ptg-blog__item:hover::after {
  opacity: 1;
}
.ptg-blog__item:hover .ptg-blog__item-thumb {
  border-radius: 6px 6px 0 0;
}
.ptg-blog__item:hover .ptg-blog__item-thumb img {
  transform: scale(1.1);
}

.ptg-blog-btn {
  color: #636363;
}
.ptg-blog-btn:hover {
  color: #fff;
}

.law-blog .it-blog-date {
  border-color: var(--tp-theme-red);
}
.law-blog .it-blog-date b {
  color: var(--tp-theme-wine);
}
.law-blog .it-blog__meta span .it-blog-cta {
  color: var(--tp-theme-red);
}
.law-blog .it-blog__meta span .it-blog-cta:hover {
  color: var(--tp-theme-wine);
}
.law-blog .it-blog__meta span b {
  color: var(--tp-theme-wine);
}
.law-blog .it-blog__meta span b:hover {
  color: var(--tp-theme-red);
}
.law-blog .it-blog__title {
  color: var(--tp-theme-wine);
  text-transform: uppercase;
}
.law-blog .it-blog-info {
  border: 2px solid transparent;
  border-top: none;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.law-blog:hover .it-blog-info {
  border-color: var(--tp-theme-red);
}

.ca-blog-area-bg {
  background-image: linear-gradient(
    100.16deg,
    #5a6ef8 9.94%,
    #a874c6 54.82%,
    #fe7a8f 95.56%
  );
  margin-bottom: -130px;
}

.ca-blog-item {
  height: 430px;
  padding-top: 40px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 25px;
  margin-bottom: 30px;
  background-size: cover;
  background-repeat: repeat;
  position: relative;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.ca-blog-item::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.4) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  z-index: 1;
}
.ca-blog-item__date span {
  background-color: white;
  font-weight: 400;
  padding: 6px 15px;
  border-radius: 14px;
  position: relative;
  z-index: 2;
}
.ca-blog-item__date span i {
  margin-right: 5px;
}
.ca-blog-item__content {
  position: relative;
  z-index: 2;
}
.ca-blog-item__content-meta {
  color: white;
  margin-bottom: 10px;
}
.ca-blog-item__content-meta a {
  font-weight: 400;
}
.ca-blog-item__content-meta a:hover {
  opacity: 0.8;
}
.ca-blog-item__content-title {
  color: white;
  font-size: 24px;
  line-height: 1.4;
  text-transform: capitalize;
  letter-spacing: -0.3px;
}
.ca-blog-item__content-title a {
  background-image: linear-gradient(#fff, #fff), linear-gradient(#fff, #fff);
  display: inline;
  background-size: 0% 1px, 0 1px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.4s linear;
}
.ca-blog-item__content-title a:hover {
  background-size: 0% 1px, 100% 1px;
}
.ca-blog-item:hover {
  opacity: 0.9;
}
@media (max-width: 767px) {
  .ca-blog-item {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.ha-blog .it-blog__title {
  color: #000;
  line-height: 1.6;
}
.ha-blog .it-blog__meta span b {
  color: #000;
}
.ha-blog .it-blog__meta span .it-blog-cta {
  color: var(--tp-theme-mandalay);
}

.seo-blog .it-blog-date {
  border-color: var(--tp-theme-atlantis);
}
.seo-blog .it-blog__title {
  margin-bottom: 40px;
}
.seo-blog span b {
  color: var(--tp-theme-seagreen);
}
.seo-blog span b:hover {
  color: var(--tp-theme-lochmara);
}
.seo-blog span .it-blog-cta {
  color: var(--tp-theme-lochmara);
}
.seo-blog span .it-blog__title {
  color: var(--tp-theme-seagreen);
}
.seo-blog .it-portfolio-item__btn {
  background-color: #f2f6f8;
  border-radius: 6px;
  font-size: 14px;
  padding: 16px 30px;
}
.seo-blog .it-portfolio-item__btn:hover {
  background-color: var(--tp-theme-atlantis);
}
.seo-blog .it-portfolio-item__btn span {
  color: var(--tp-theme-seagreen);
}

.tp-lasted-blog {
  margin-bottom: 60px;
}
.tp-lasted-blog .it-blog-date {
  border-color: var(--tp-theme-redical);
}
.tp-lasted-blog .it-blog__title {
  margin-bottom: 40px;
  color: var(--tp-common-black);
}
.tp-lasted-blog .it-blog-info {
  border: 2px solid #f6f6f6;
  border-top: 0;
}
.tp-lasted-blog span b {
  color: var(--tp-common-black);
}
.tp-lasted-blog span b:hover {
  color: var(--tp-theme-lochmara);
}
.tp-lasted-blog span .it-blog-cta {
  color: var(--tp-theme-cornblue);
}
.tp-lasted-blog span .it-blog__title {
  color: var(--tp-common-black);
}
.tp-lasted-blog .it-portfolio-item__btn {
  background-color: #f2f6f8;
  border-radius: 6px;
  font-size: 14px;
  padding: 11px 18px;
  color: var(--tp-common-black);
}
.tp-lasted-blog .it-portfolio-item__btn:hover {
  color: var(--tp-common-white);
  background-color: var(--tp-theme-redical);
}
.tp-lasted-blog .it-portfolio-item__btn span {
  color: var(--tp-theme-seagreen);
}

.postbox__border {
  padding: 50px 40px;
  border: 2px solid #f3f3f3;
}
@media (max-width: 767px) {
  .postbox__border {
    padding: 50px 15px;
    margin-right: 0;
  }
}
.postbox__wrapper {
  border-right: 2px solid #f3f3f3;
}
@media (max-width: 767px),
  only screen and (min-width: 768px) and (max-width: 991px) {
  .postbox__wrapper {
    border-right: 0;
    padding-right: 0;
    margin-right: 0;
  }
}
.postbox__video span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 80px;
  height: 80px;
  text-align: center;
  line-height: 80px;
  border-radius: 50%;
  background-color: var(--tp-common-white);
  color: var(--tp-theme-redical);
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06);
  animation: pulse 2s infinite;
}
.postbox__audio {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  z-index: 3;
}
.postbox__tag {
  background-color: var(--tp-theme-redical);
  display: inline-block;
  position: absolute;
  left: 50px;
  transform: translateY(-50%);
  font-weight: 400;
  padding: 5px 20px;
  color: var(--tp-common-white);
}
.postbox__author {
  background-color: white;
  position: absolute;
  right: 50px;
  transform: translateY(-50%);
  padding: 8px;
}
.postbox__author span {
  font-weight: 400;
  padding-left: 10px;
  padding-right: 10px;
}
.postbox__title {
  font-size: 34px;
  line-height: 1.3;
  margin-bottom: 20px;
}
.postbox__title a {
  background-image: linear-gradient(#000, #000), linear-gradient(#000, #000);
  display: inline;
  background-size: 0% 1px, 0 1px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.4s linear;
}
.postbox__title a:hover {
  background-size: 0% 1px, 100% 1px;
}
.postbox__read-more-btn {
  padding: 15px 30px;
  background-color: var(--tp-theme-redical);
  font-size: 16px;
  font-weight: 400;
  color: var(--tp-common-white);
  border-radius: 6px;
  display: inline-block;
  position: relative;
}
.postbox__read-more-btn:hover {
  color: white;
}
.postbox__content {
  padding: 40px 50px;
  border: 2px solid #f3f3f3;
}
.postbox__content img {
  margin-bottom: 30px;
}
.postbox__content-single {
  padding: 0px;
  border: none;
}
.postbox__text p {
  font-size: 16px;
  margin-bottom: 30px;
  display: inline-block;
  color: var(--tp-grey-1);
}
.postbox__text-single p {
  font-size: 16px;
  margin-bottom: 30px;
  display: inline-block;
  color: var(--tp-grey-1);
}
.postbox__meta span {
  color: var(--tp-grey-1);
  margin-right: 30px;
}
@media (max-width: 576px) {
  .postbox__meta span {
    display: block;
    margin-bottom: 15px;
  }
}
.postbox__meta span i {
  padding-right: 8px;
  color: var(--tp-theme-redical);
}

blockquote {
  padding: 50px;
  border: 2px solid #f3f3f3;
  margin-bottom: 60px;
  display: inline-block;
}
@media (max-width: 767px) {
  blockquote {
    padding: 50px 15px;
  }
}
blockquote .quote-icon {
  width: 60px;
  height: 60px;
  font-size: 40px;
  background-color: var(--tp-theme-cornblue);
  line-height: 60px;
  display: inline-block;
  border-radius: 50%;
  color: var(--tp-common-white);
  margin-bottom: 25px;
}
blockquote .quote-box p {
  font-size: 28px;
  line-height: 1.3;
  color: var(--tp-common-black);
  font-weight: 400;
}
@media (max-width: 767px) {
  blockquote .quote-box p {
    font-size: 20px;
  }
}
blockquote cite {
  font-size: 18px;
  font-weight: 400;
  display: block;
}
@media (max-width: 767px) {
  blockquote cite {
    font-size: 16px;
  }
}
blockquote span {
  color: var(--tp-grey-1);
  text-transform: capitalize;
}

.tp-blog-dt-pagination-title {
  font-size: 20px;
}
@media (max-width: 767px) {
  .tp-blog-dt-pagination-tagcloud {
    margin-bottom: 30px;
  }
}
.tp-blog-dt-pagination-tagcloud a {
  background-color: var(--tp-common-white);
  padding: 8px 20px;
  margin-bottom: 10px;
  margin-right: 10px;
  display: inline-block;
  color: var(--tp-grey-1);
  font-size: 12px;
  font-weight: 400;
  text-transform: capitalize;
  border: 2px solid #f4f4f4;
  border-radius: 6px;
}
.tp-blog-dt-pagination-tagcloud a:hover {
  background-color: var(--tp-theme-redical);
  color: var(--tp-common-white);
  border-color: var(--tp-theme-redical);
}
.tp-blog-dt-pagination-social a {
  color: var(--tp-grey-1);
  margin-right: 30px;
  font-size: 16px;
}
.tp-blog-dt-pagination-social a:last-child {
  margin-right: 0;
}
.tp-blog-dt-pagination-social a:hover {
  color: var(--tp-theme-redical);
}

.format-audio .postbox__thumb {
  position: relative;
}
.format-audio .postbox__thumb::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  opacity: 0.702;
}

.basic-pagination ul li {
  display: inline-block;
}
.basic-pagination ul li:not(:last-child) {
  margin-right: 10px;
}
.basic-pagination ul li a,
.basic-pagination ul li span {
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 46px;
  text-align: center;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -o-border-radius: 7px;
  -ms-border-radius: 7px;
  border-radius: 7px;
  border: 2px solid #f1f1f1;
  font-size: 14px;
  font-weight: 400;
}
@media (max-width: 576px) {
  .basic-pagination ul li a,
  .basic-pagination ul li span {
    width: 40px;
    height: 40px;
    line-height: 36px;
    margin-bottom: 30px;
  }
}
.basic-pagination ul li a:hover,
.basic-pagination ul li a.current,
.basic-pagination ul li span:hover,
.basic-pagination ul li span.current {
  background: var(--tp-theme-redical);
  border-color: var(--tp-theme-redical);
  color: var(--tp-common-white);
}

.tp-post-dt-author-area {
  border: 2px solid #f3f3f3;
  padding: 40px;
  padding-bottom: 10px;
}
@media (max-width: 767px) {
  .tp-post-dt-author-area {
    padding: 40px 15px;
  }
}
.tp-post-dt-author__info span {
  color: var(--tp-grey-1);
  display: inline-block;
  margin-bottom: 10px;
  font-size: 12px;
}
.tp-post-dt-author__info p {
  color: #757c80;
}
.tp-post-dt-author__name {
  font-size: 36px;
  margin-bottom: 15px;
}

@media (max-width: 767px) {
  .postbox__comment-box {
    margin-right: 0;
  }
}
@media (max-width: 767px) {
  .postbox__comment-avater {
    margin-bottom: 30px;
  }
}
.postbox__comment-title {
  font-size: 26px;
  margin-bottom: 30px;
}
.postbox__comment-info {
  flex-direction: column;
}
.postbox__comment-name {
  margin-bottom: 30px;
}
.postbox__comment-name span {
  color: var(--tp-grey-1);
  text-transform: capitalize;
  display: block;
}
.postbox__comment-name span i {
  margin-right: 5px;
}
@media (max-width: 767px) {
  .postbox__comment-name p {
    margin-bottom: 15px;
  }
}
.postbox__comment-reply {
  margin-top: 10px;
  position: absolute;
  top: 0;
  right: 0;
  border: 2px solid #f3f3f3;
  padding: 5px 15px;
  border-radius: 6px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
@media (max-width: 767px),
  only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (min-width: 992px) and (max-width: 1199px) {
  .postbox__comment-reply {
    position: static;
    display: inline-block;
  }
}
.postbox__comment-reply:hover {
  background-color: var(--tp-theme-redical);
  color: var(--tp-common-white);
}
.postbox__comment-reply i {
  transform: rotateY(180deg);
  margin-right: 5px;
}
.postbox__comment p {
  color: var(--tp-grey-3);
  padding-bottom: 25px;
  margin-bottom: 30px;
  border-bottom: 1px solid #f3f3f3;
}
.postbox__comment ul li {
  margin-bottom: 10px;
  list-style: none;
}
.postbox__comment ul li.children {
  margin-left: 100px;
}
@media (max-width: 767px) {
  .postbox__comment ul li.children {
    margin-left: 15px;
  }
}

.tp-blog-comment-form {
  padding: 40px;
  border: 2px solid #f3f3f3;
  border-radius: 6px;
}
@media (max-width: 767px) {
  .tp-blog-comment-form {
    padding: 40px 15px;
  }
}
.tp-blog-comment-form textarea {
  background-color: transparent;
  width: 100%;
  height: 150px;
  padding: 30px;
  margin-bottom: 30px;
  outline: 0;
  border: 2px solid #f3f3f3;
  resize: none;
  color: var(--tp-grey-3);
  border-radius: 6px;
}
@media (max-width: 767px) {
  .tp-blog-comment-form textarea {
    padding: 30px 15px;
  }
}
.tp-blog-comment-form textarea::-webkit-input-placeholder {
  color: var(--tp-grey-3);
}
.tp-blog-comment-form textarea:-moz-placeholder {
  color: var(--tp-grey-3);
}
.tp-blog-comment-form textarea::-moz-placeholder {
  color: var(--tp-grey-3);
}
.tp-blog-comment-form textarea:-ms-input-placeholder {
  color: var(--tp-grey-3);
}
.tp-blog-comment-form textarea:focus {
  border-color: var(--tp-theme-redical);
}
.tp-blog-comment-form input {
  width: 100%;
  height: 60px;
  margin-bottom: 30px;
  padding: 30px;
  outline: 0;
  border: 2px solid #f3f3f3;
  resize: none;
  color: var(--tp-grey-3);
  border-radius: 6px;
}
@media (max-width: 767px) {
  .tp-blog-comment-form input {
    padding: 30px 15px;
  }
}
.tp-blog-comment-form input::-webkit-input-placeholder {
  color: var(--tp-grey-3);
}
.tp-blog-comment-form input:-moz-placeholder {
  color: var(--tp-grey-3);
}
.tp-blog-comment-form input::-moz-placeholder {
  color: var(--tp-grey-3);
}
.tp-blog-comment-form input:-ms-input-placeholder {
  color: var(--tp-grey-3);
}
.tp-blog-comment-form input:focus {
  border-color: var(--tp-theme-redical);
}

.tp-blog-nav span {
  color: var(--tp-grey-1);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-blog-nav-title {
    font-size: 24px;
  }
}

/*----------------------------------------*/
/* 23. SIDEBAR CSS
/*----------------------------------------*/
.sidebar__widget-content {
  margin-bottom: 50px;
}
.sidebar__widget-content ul li {
  font-size: 16px;
  color: var(--tp-grey-1);
  margin-bottom: 20px;
  position: relative;
}
.sidebar__widget-content ul li::before {
  content: "\f105";
  font-family: var(--tp-ff-fontawesome);
  font-weight: 300;
  font-size: 16px;
  padding-right: 10px;
}
.sidebar__widget-content ul li span {
  position: absolute;
  right: 0;
  color: var(--tp-common-black);
  font-weight: 400;
}
.sidebar__widget-content .tagcloud a {
  background-color: var(--tp-theme-redical);
  padding: 8px 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  display: inline-block;
  color: var(--tp-common-white);
  font-size: 12px;
  font-weight: 400;
  text-transform: capitalize;
}
.sidebar__widget-title {
  font-size: 20px;
  position: relative;
  margin-bottom: 50px;
}
.sidebar__widget-title::after {
  content: "";
  position: absolute;
  bottom: -20px;
  width: 100%;
  height: 2px;
  left: 0;
  background-color: #f1f1f1;
  display: inline-block;
}
.sidebar__widget-title::before {
  content: "";
  position: absolute;
  bottom: -20px;
  width: 80px;
  height: 2px;
  left: 0;
  background-color: var(--tp-theme-redical);
  display: inline-block;
  z-index: 1;
}
.sidebar__about img {
  margin-bottom: 25px;
}
.sidebar__about-name {
  font-size: 18px;
  margin-bottom: 15px;
}
.sidebar__about p {
  font-size: 16px;
  color: var(--tp-grey-1);
  margin-bottom: 25px;
}
.sidebar__about-social span {
  margin-right: 20px;
  color: var(--tp-grey-1);
}
.sidebar__about-social span:last-child {
  margin-right: 0;
}
.sidebar__about-social span:hover {
  color: var(--tp-theme-redical);
}
.sidebar__search-input {
  position: relative;
}
.sidebar__search-input input {
  height: 60px;
  width: 100%;
  padding-left: 20px;
  padding-right: 80px;
  color: var(--tp-grey-4);
  border: 2px solid #f6f6f6;
  color: var(--tp-grey-4);
}
.sidebar__search-input input::-webkit-input-placeholder {
  color: var(--tp-grey-4);
}
.sidebar__search-input input:-moz-placeholder {
  color: var(--tp-grey-4);
}
.sidebar__search-input input::-moz-placeholder {
  color: var(--tp-grey-4);
}
.sidebar__search-input input:-ms-input-placeholder {
  color: var(--tp-grey-4);
}
.sidebar__search-input button {
  position: absolute;
  right: 0;
  top: 0;
  width: 60px;
  height: 60px;
  background-color: var(--tp-theme-redical);
  color: var(--tp-common-white);
}

.rc__post-title {
  font-size: 16px;
}
.rc__post-content {
  padding-right: 40px;
}
.rc__meta span {
  color: var(--tp-grey-4);
}

/*----------------------------------------*/
/* 24. SHOP CSS 
/*----------------------------------------*/
.tp-product__img {
  position: relative;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.tp-product__action {
  position: absolute;
  bottom: 10%;
  right: 0;
  left: 0;
  text-align: center;
  transform: translateY(50%);
  opacity: 0;
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}
.tp-product__action a {
  background-color: #fff;
  padding: 15px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  margin-right: 5px;
  border-radius: 6px;
}
.tp-product__action a:hover {
  background-color: var(--tp-theme-redical);
  color: var(--tp-common-white);
}
.tp-product__title {
  font-size: 16px;
  color: var(--tp-grey-3);
  margin-bottom: 5px;
}
.tp-product__price {
  font-size: 18px;
  font-weight: 400;
  color: #000;
}
.tp-product:hover .tp-product__action {
  opacity: 1;
  bottom: 50%;
}
.tp-product:hover .tp-product__img img {
  transform: scale(1.1);
}

.tp-product-page-subtitle {
  font-size: 18px;
  color: var(--tp-grey-3);
}
.tp-product-page-subtitle b {
  color: var(--tp-common-black);
}

.tp-view-number-icon .nice-select {
  border: 0;
}
.tp-view-number-icon .nice-select::after {
  border-color: var(--tp-theme-redical);
}

.product__result {
  margin-bottom: 30px;
}
.product__result p {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 400;
  color: var(--tp-text-12);
  margin-bottom: 0;
}
.product__result p span {
  color: var(--tp-common-black);
}
.product__thumb {
  position: relative;
}
.product__thumb::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(34, 34, 34, 0.377);
  opacity: 0;
  visibility: hidden;
}
.product__title {
  color: #777777;
  font-size: 16px;
  font-weight: 400;
}
.product__title:hover {
  color: var(--tp-theme-1);
}
.product__price {
  color: var(--tp-common-black);
  font-size: 18px;
  font-weight: 400;
}
.product__filter-wrapper {
  margin-bottom: 30px;
}
.product__filter-btn {
  margin-right: 25px;
  padding-right: 25px;
  position: relative;
}
.product__filter-btn::after {
  position: absolute;
  content: "";
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 2px;
  height: 15px;
  background-color: #e7e7e7;
}
.product__filter-btn button {
  font-size: 14px;
  color: var(--tp-common-black);
  text-transform: uppercase;
  font-weight: 400;
}
.product__filter-btn button i {
  margin-right: 3px;
}
.product__filter-count span {
  font-size: 14px;
  color: var(--tp-common-black);
  text-transform: uppercase;
}
.product__filter-count .nice-select {
  background-color: transparent;
  border: 0;
  font-size: 14px;
  float: none;
  height: 26px;
  line-height: 26px;
  padding-right: 15px;
  padding-left: 4px;
}
.product__filter-count .nice-select::after {
  font-size: 14px;
  color: var(--tp-common-black);
  margin-top: -1px;
}
.product__filter-count .nice-select .current {
  font-weight: 600;
}
.product__filter-style {
  border: 0;
  margin-left: 13px;
}
.product__filter-style .nav-link {
  padding: 0;
  margin: 0;
  border: 0;
  font-size: 16px;
  color: var(--tp-common-black);
  margin-left: 17px;
}
.product__filter-style .nav-link.active {
  border: 0;
  color: var(--tp-theme-1);
}
.product__filter-style .nav-link.active:focus {
  border: 0;
}
.product__icon {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  z-index: 9;
}
.product__icon a {
  height: 40px;
  width: 40px;
  background-color: var(--tp-common-white);
  line-height: 40px;
  color: var(--tp-theme-1);
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}
.product__icon a:hover {
  background-color: var(--tp-theme-1);
  color: var(--tp-common-white);
}

.product__item:hover .product__thumb img {
  transform: scale(1.1);
}
.product__item:hover .product__thumb::after {
  opacity: 1;
  visibility: visible;
}
.product__item:hover .product__icon {
  opacity: 1;
  visibility: visible;
  bottom: 30px;
}

.product-img-tabs {
  display: flex;
}
.product-img-tabs .nav-links img {
  opacity: 50%;
}
.product-img-tabs .nav-links.active img {
  opacity: 100%;
}
.product-img-tabs .nav-tabs {
  border-bottom: 0;
  width: 70px;
  flex-direction: column;
}
.product-img-tabs .nav-tabs .nav-item {
  margin-bottom: 30px;
}
.product-img-tabs .tab-content {
  margin-left: 30px;
}

.product-top {
  display: flex;
}
.product-top .product-tag a {
  color: var(--tp-common-white);
  background-color: var(--tp-theme-cornblue);
  font-weight: 400;
  font-size: 14px;
  padding: 4px;
  margin-right: 15px;
}
.product-top .product-rating a {
  color: #ffbf14;
}
.product-top .product-review a {
  color: #777777;
  font-size: 14px;
  font-weight: 400;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .product-img-tabs {
    margin-bottom: 50px;
  }
}

.product-details-title {
  font-size: 26px;
  font-weight: 400;
  color: var(--tp-common-black);
}

.product-price .old-price {
  color: #cfcfcf;
  font-size: 20px;
  font-weight: 400;
  text-decoration: line-through;
}
.product-price .new-price {
  color: var(--tp-theme-1);
  font-size: 36px;
  font-weight: 400;
}

.product-paragraph p {
  font-size: 16px;
  color: #777777;
  font-weight: 400;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .product-paragraph p br {
    display: none;
  }
}

.product-quantity-wapper {
  overflow: hidden;
}
.product-quantity-wapper .product-quantity {
  border: 1px solid #d3d3d3;
  padding: 0 20px;
  margin-right: 20px;
  border-radius: 6px;
}
.product-quantity-wapper .cart-plus {
  color: #d3d3d3;
}
.product-quantity-wapper .cart-minus {
  color: #d3d3d3;
}
.product-quantity-wapper input {
  height: 60px;
  width: 60px;
  padding: 0 20px;
  outline: 0;
  border: 0;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
}
@media (max-width: 767px) {
  .product-quantity-wapper input {
    margin-top: 10px;
  }
}
@media (max-width: 767px) {
  .product-quantity-wapper .product-quantity-button {
    margin-top: 10px;
  }
}
.product-quantity-wapper .heart-icon {
  border: 1px solid #d3d3d3;
  padding: 19px 20px;
  border-radius: 6px;
  font-size: 16px;
}
@media (max-width: 767px) {
  .product-quantity-wapper .heart-icon {
    margin-top: 10px;
  }
}
.product-quantity-wapper .heart-icon:hover {
  background-color: var(--tp-theme-redical);
  color: var(--tp-common-white);
}

.product-details-meta .sku span {
  font-size: 16px;
  font-weight: 400;
  color: var(--tp-common-black);
}
.product-details-meta .sku a {
  font-size: 16px;
  font-weight: 400;
  color: #777777;
}
.product-details-meta .categories span {
  font-size: 16px;
  font-weight: 400;
  color: var(--tp-common-black);
}
.product-details-meta .categories a {
  font-size: 16px;
  font-weight: 400;
  color: #777777;
}
.product-details-meta .tag span {
  font-size: 16px;
  font-weight: 400;
  color: var(--tp-common-black);
}
.product-details-meta .tag a {
  font-size: 16px;
  font-weight: 400;
  color: #777777;
}

.product-details-share span {
  font-weight: 400;
  font-size: 16px;
  color: var(--tp-common-black);
  margin-right: 10px;
}
.product-details-share a {
  color: #999999;
  font-size: 15px;
  margin-right: 15px;
}
.product-details-share a:hover {
  color: var(--tp-theme-1);
}

.product-additional-tab {
  display: flex;
}
@media only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .product-additional-tab {
    flex-wrap: wrap;
  }
}
.product-additional-tab .nav-tabs {
  flex-direction: column;
  border-bottom: 0;
  width: 260px;
  flex: 0 0 auto;
}
@media only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .product-additional-tab .nav-tabs {
    width: 100%;
    margin-bottom: 30px;
  }
}
.product-additional-tab .nav-tabs .nav-item {
  margin-bottom: 10px;
}
.product-additional-tab .nav-tabs .nav-item .nav-links {
  font-size: 16px;
  color: var(--tp-common-black);
  font-weight: 400;
  text-align: left;
  padding: 15px 30px;
  width: 100%;
  border: 1px solid #ebebeb;
  border-radius: 8px;
}
.product-additional-tab .nav-tabs .nav-item .nav-links:hover,
.product-additional-tab .nav-tabs .nav-item .nav-links.active {
  background-color: var(--tp-theme-cornblue);
  color: var(--tp-common-white);
}

.tp-content-tab {
  margin-left: 40px;
  width: 100%;
}
@media only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .tp-content-tab {
    margin-left: 0;
  }
}
.tp-content-tab .tab-pane p {
  color: #777777;
  font-size: 16px;
  font-weight: 400;
}

.shop-details-active .tp-porduct-item {
  margin: 0 15px;
}

.table td {
  border: 0;
  padding: 15px;
}
.table .add-info {
  font-size: 18px;
  font-weight: 400;
}

.comments-title {
  font-size: 24px;
  color: var(--tp-common-black);
  font-weight: 400;
  text-transform: capitalize;
}

.product-details-comment .comment-title h3 {
  font-size: 24px;
  color: var(--tp-common-black);
  font-weight: 400;
  text-transform: capitalize;
}
.product-details-comment .comment-title p {
  font-size: 16px;
  color: #777777;
  font-weight: 400;
}
.product-details-comment .comment-rating span {
  font-size: 15px;
  color: #777777;
  font-weight: 400;
  padding-right: 5px;
}
.product-details-comment .comment-rating ul li {
  display: inline-block;
}
.product-details-comment .comment-rating ul li a {
  color: #ffbf14;
}

.comment-input textarea {
  width: 100%;
  height: 130px;
  resize: none;
  outline: 0;
  border: 1px solid #e7e7e7;
  padding: 20px;
  margin-bottom: 20px;
}
.comment-input textarea:focus {
  border: 1px solid #222;
}
.comment-input textarea::placeholder {
  color: #777777;
  font-size: 14px;
  font-weight: 400;
}
.comment-input input {
  width: 100%;
  height: 50px;
  resize: none;
  outline: 0;
  border: 1px solid #e7e7e7;
  padding: 20px;
  margin-bottom: 30px;
}
.comment-input input:focus {
  border: 1px solid #222;
}
.comment-input input::placeholder {
  color: #777777;
  font-size: 14px;
  font-weight: 400;
}

.comment-submit button {
  background-color: var(--tp-theme-1);
  padding: 15px 20px;
  color: var(--tp-common-white);
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
}
.comment-submit button:hover {
  background-color: var(--tp-common-black);
}

.latest-comments {
  margin-bottom: 55px;
}
.latest-comments .comments-box {
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.latest-comments .comments-box .avatar-name h5 {
  color: var(--tp-common-black);
  font-weight: 400;
  text-transform: uppercase;
}
.latest-comments .comments-box .comments-date span {
  font-size: 14px;
  color: #777777;
}
.latest-comments .comments-box .user-rating ul li {
  display: inline-block;
  border: 0;
}
.latest-comments .comments-box .user-rating ul li a {
  color: #ffbf14;
}
.latest-comments .comments-box p {
  color: #777777;
  font-size: 16px;
  font-weight: 400;
}

.product-details-size-table .table {
  border-collapse: collapse;
}
.product-details-size-table .table thead tr {
  background-color: #f4f4f4;
  border: 0;
}
.product-details-size-table .table thead tr th {
  border: 0;
  padding: 18px;
}
.product-details-size-table .table tbody {
  border: 0;
}
.product-details-size-table .table tbody tr:nth-child(2n + 2) {
  background-color: #f4f4f4;
}

.pd-sd-button-prev,
.pd-sd-button-next {
  font-size: 20px;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  background-color: var(--tp-common-white);
  color: var(--tp-common-black);
  border: 1px solid #ebebeb;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  border-radius: 8px;
}
.pd-sd-button-prev:hover,
.pd-sd-button-next:hover {
  color: var(--tp-common-white);
  background-color: var(--tp-theme-redical);
  border-color: transparent;
}

.pd-sd-button-prev {
  margin-right: 30px;
}

.product-quantity .cart-minus {
  cursor: pointer;
}
.product-quantity .cart-minus:hover {
  color: var(--tp-common-black);
}
.product-quantity .cart-plus:hover {
  color: var(--tp-common-black);
}

/*----------------------------------------*/
/*  25. CHECKOUT CSS 
/*----------------------------------------*/
/* 16. Cart */
.table-content .table > :not(:first-child) {
  border-top: 0;
}
.table-content table {
  background: #ffffff;
  border-color: #eaedff;
  border-radius: 0;
  border-style: solid;
  border-width: 1px 0 0 1px;
  text-align: center;
  width: 100%;
  margin-bottom: 0;
}

.table-content .table > :not(:last-child) > :last-child > * {
  border-bottom-color: #eaedff;
}

.table-content .product-quantity {
  float: none;
}

.table-content table td.product-name {
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
}

.table-content table td.product-name a:hover {
  color: var(--tp-theme-1);
}

.table-content table td {
  border-top: medium none;
  padding: 20px 10px;
  vertical-align: middle;
  font-size: 16px;
}

.table-content table th,
.table-content table td {
  border-bottom: 1px solid #eaedff;
  border-right: 1px solid #eaedff;
}

.table-content table td.product-subtotal {
  font-size: 16px;
}

.table-content table td .cart-plus-minus {
  float: none;
  margin: 0 auto;
}

.coupon-all {
  margin-top: 50px;
}

.coupon {
  float: left;
}

@media (max-width: 767px) {
  .coupon {
    float: none;
  }
}
#coupon_code {
  height: 58px;
  border: 2px solid #eaedff;
  padding: 0 15px;
  margin-right: 10px;
}

@media (max-width: 767px) {
  #coupon_code {
    margin-bottom: 15px;
  }
}
.coupon2 {
  float: right;
}

@media (max-width: 767px) {
  .coupon2 {
    float: none;
    margin-top: 15px;
  }
}
.cart-page-total {
  padding-top: 50px;
}

.cart-page-total > h2 {
  font-size: 25px;
  margin-bottom: 20px;
  text-transform: capitalize;
}

.cart-page-total > ul {
  border: 1px solid #eaedff;
}

.cart-page-total > ul > li {
  list-style: none;
  font-size: 15px;
  color: #6f7172;
  padding: 10px 30px;
  border-bottom: 1px solid #eaedff;
  font-weight: 400;
}

.cart-page-total ul > li > span {
  float: right;
}

.cart-page-total li:last-child {
  border-bottom: 0;
}

td.product-thumbnail img {
  width: 125px;
}

/* 17. Checkout */
.coupon-accordion h3 {
  background-color: #f7f7f7;
  border-top: 3px solid var(--tp-theme-1);
  font-size: 14px;
  font-weight: 400;
  margin: 0 0 25px;
  padding: 1em 2em 1em 3.5em;
  position: relative;
  width: auto;
}

.coupon-accordion h3::before {
  content: "\f07b";
  left: 15px;
  top: 13px;
  position: absolute;
  color: var(--tp-theme-1);
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
}

.coupon-accordion span {
  color: #6f7172;
  cursor: pointer;
  transition: 0.3s;
  font-weight: 400;
}

.coupon-accordion span:hover,
p.lost-password a:hover {
  color: var(--tp-common-black);
}

.coupon-content {
  border: 1px solid #eaedff;
  display: none;
  margin-bottom: 20px;
  padding: 30px;
}

.coupon-info p.coupon-text {
  margin-bottom: 15px;
}

.coupon-info p {
  margin-bottom: 0;
}

.coupon-info p.form-row-first label,
.coupon-info p.form-row-last label {
  display: block;
  color: #6f7172;
}

.coupon-info p.form-row-first label span.required,
.coupon-info p.form-row-last label span.required {
  color: var(--tp-theme-1);
  font-weight: 400;
}

.coupon-info p.form-row-first input,
.coupon-info p.form-row-last input {
  border: 1px solid #eaedff;
  height: 45px;
  margin: 0 0 14px;
  max-width: 100%;
  padding: 0 0 0 10px;
  width: 100%;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}
.coupon-info p.form-row-first input:focus,
.coupon-info p.form-row-last input:focus {
  border-color: var(--tp-common-black);
}

.coupon-info p.form-row input[type="submit"]:hover,
p.checkout-coupon input[type="submit"]:hover {
  background: #3e976c none repeat scroll 0 0;
}

.coupon-info p.form-row input[type="checkbox"] {
  position: relative;
  top: 2px;
}

.form-row > label {
  margin-top: 15px;
  margin-left: 15px;
  color: #6f7172;
}

.buttons-cart input,
.coupon input[type="submit"],
.buttons-cart a,
.coupon-info p.form-row input[type="submit"] {
  background: #252525 none repeat scroll 0 0;
  border: medium none;
  color: #fff;
  display: inline-block;
  float: left;
  font-size: 12px;
  font-weight: 400;
  height: 40px;
  line-height: 40px;
  margin-right: 15px;
  padding: 0 15px;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
}

p.lost-password {
  margin-top: 15px;
}

p.lost-password a {
  color: #6f6f6f;
}

p.checkout-coupon input[type="text"] {
  height: 45px;
  padding: 0 15px;
  width: 100%;
  border: 1px solid #eaedff;
  margin-bottom: 15px;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}
p.checkout-coupon input[type="text"]:focus {
  border-color: var(--tp-common-black);
}

.coupon-checkout-content {
  display: none;
}

.checkbox-form h3 {
  border-bottom: 1px solid #eaedff;
  font-size: 26px;
  margin: 0 0 20px;
  padding-bottom: 10px;
  width: 100%;
}

.country-select {
  margin-bottom: 30px;
  position: relative;
}

.country-select select {
  width: 100%;
  background-color: transparent;
  border: 1px solid #eaedff;
  padding: 0 10px;
  height: 50px;
}

.country-select label,
.checkout-form-list label {
  color: #6f7172;
  display: block;
  margin: 0 0 5px;
}

.country-select label span.required,
.checkout-form-list label span.required {
  color: var(--tp-theme-1);
}

.country-select .nice-select {
  border: 1px solid #eaedff;
  height: 45px;
  padding-left: 10px;
  width: 100%;
  color: #6f7172;
  margin-bottom: 20px;
}

.country-select .nice-select .list {
  width: 100%;
}

.checkout-form-list {
  margin-bottom: 30px;
}

.checkout-form-list label {
  color: #6f7172;
}

.checkout-form-list input[type="text"],
.checkout-form-list input[type="password"],
.checkout-form-list input[type="email"] {
  background: #ffffff;
  border: 1px solid #eaedff;
  border-radius: 0;
  height: 45px;
  padding: 0 0 0 10px;
  width: 100%;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}
.checkout-form-list input[type="text"]:focus,
.checkout-form-list input[type="password"]:focus,
.checkout-form-list input[type="email"]:focus {
  border-color: var(--tp-common-black);
}

.checkout-form-list input[type="text"]::-moz-placeholder,
.checkout-form-list input[type="password"]::-moz-placeholder,
.checkout-form-list input[type="email"]::-moz-placeholder {
  color: #6f7172;
  opacity: 1;
}

.checkout-form-list input[type="text"]::placeholder,
.checkout-form-list input[type="password"]::placeholder,
.checkout-form-list input[type="email"]::placeholder {
  color: #6f7172;
  opacity: 1;
}

.checkout-form-list input[type="checkbox"] {
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: 1px;
}

.create-acc label {
  color: #6f7172;
  display: inline-block;
}

.create-account {
  display: none;
}

.ship-different-title h3 label {
  display: inline-block;
  margin-right: 20px;
  color: var(--tp-common-black);
}

.order-notes textarea {
  border: 1px solid #eaedff;
  height: 120px;
  padding: 15px;
  width: 100%;
  outline: 0;
  resize: none;
}

.order-notes textarea:focus {
  border-color: var(--tp-common-black);
}

.order-notes textarea::-moz-placeholder {
  color: #6f7172;
  opacity: 1;
}

.order-notes textarea::placeholder {
  color: #6f7172;
  opacity: 1;
}

#ship-box-info {
  display: none;
}

.panel-group .panel {
  border-radius: 0;
}

.panel-default > .panel-heading {
  border-radius: 0;
}

.your-order {
  padding: 30px 40px 45px;
  border: 3px solid #f7f7f7;
}

@media (max-width: 767px) {
  .your-order {
    padding: 15px;
  }
}
.your-order h3 {
  border-bottom: 1px solid #eaedff;
  font-size: 30px;
  margin: 0 0 20px;
  padding-bottom: 10px;
  width: 100%;
}

.your-order-table table {
  background: none;
  border: 0;
  width: 100%;
}

.your-order-table table th,
.your-order-table table td {
  border-bottom: 1px solid #eaedff;
  border-right: medium none;
  color: #6f7172;
  font-size: 14px;
  padding: 15px 0;
  text-align: left;
}

@media (max-width: 767px) {
  .your-order-table table th,
  .your-order-table table td {
    padding-right: 10px;
  }
}
.your-order-table table th {
  border-top: medium none;
  color: #6f7172;
  font-weight: normal;
  text-align: left;
  vertical-align: middle;
  white-space: nowrap;
  width: 250px;
}

.panel-body > p {
  color: #222;
}

.your-order-table table .shipping ul li {
  list-style: none;
}

.your-order-table table .shipping ul li input {
  position: relative;
  top: 2px;
}

.your-order-table table .shipping ul li label {
  color: #6f7172;
}

.your-order-table table .shipping th {
  vertical-align: top;
}

.your-order-table table .order-total th {
  border-bottom: 0;
  font-size: 14px;
}

.your-order-table table .order-total td {
  border-bottom: medium none;
}

.your-order-table table tr.cart_item:hover {
  background: #f9f9f9;
}

.your-order-table table tr.order-total td span {
  color: var(--tp-common-black);
  font-size: 18px;
  font-weight: 400;
}

.payment-method {
  margin-top: 40px;
}
.payment-method .accordion-item:last-of-type {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.payment-method .accordion-item {
  background-color: #fff;
  border: 0;
  border-bottom: 1px solid #e7e7e7;
}
.payment-method .accordion-button {
  font-size: 16px;
  font-weight: 400;
  color: var(--tp-common-black);
  padding: 23px 0;
  border: none;
}
.payment-method .accordion-button:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}
.payment-method .accordion-button::after {
  position: absolute;
  content: "\f067";
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  font-family: var(--tp-ff-fontawesome);
  font-size: 16px;
  font-weight: 400;
  margin-left: 0;
  background-image: none;
  color: var(--tp-theme-1);
}
.payment-method .accordion-button:not(.collapsed) {
  color: var(--tp-common-black);
  background-color: var(--tp-common-white);
  box-shadow: none;
}
.payment-method .accordion-button:not(.collapsed)::after {
  content: "\f068";
}
.payment-method .accordion-body {
  padding: 8px 0;
  padding-bottom: 40px;
}
.payment-method .accordion-collapse {
  border: none;
}

.panel-title > a {
  display: block;
}

.order-button-payment input {
  background: #232323 none repeat scroll 0 0;
  border: medium none;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  height: 40px;
  margin: 0px 0 0;
  padding: 0;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
  width: 100%;
}

.order-button-payment input:hover {
  background: #3e976c none repeat scroll 0 0;
}

.payment-method .btn-link {
  -moz-user-select: none;
  background: no-repeat;
  border: medium none;
  border-radius: 0;
  color: #444;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 1;
  margin-bottom: 0;
  padding: 3px 10px;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
  vertical-align: middle;
  white-space: nowrap;
  text-decoration: none;
}

.payment-method .card {
  background-color: #ffffff;
  border: 1px solid #eaedff;
  border-radius: 0;
  margin-bottom: 10px;
}

.payment-method .accordion .card:first-of-type {
  border: 1px solid #eaedff;
}

.card-header:first-child {
  border-radius: 0;
}

.payment-method .card-header {
  background-color: #ffffff;
  border-bottom: 1px solid #eaedff;
}

.order-button-payment button {
  width: 100%;
}

/*----------------------------------------*/
/*  26. FOOTER CSS
/*----------------------------------------*/
.bs-footer__top-social a,
.da-footer__top-social a {
  font-size: 16px;
  display: inline-flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 35px;
  height: 35px;
  background-color: var(--tp-common-white);
  border: 2px solid var(--tp-border-6);
  text-align: center;
  line-height: 46px;
  margin-right: 5px;
  border-radius: 8px;
}

/* .bs-footer__top-social a {
  font-size: 16px;
  width: 35px;
  height: 35px;
  background-color: var(--tp-common-white);
  border: 2px solid var(--tp-border-6);
  text-align: center;
  display: inline-flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin-right: 5px;
  border-radius: 8px;
} */

.bs-footer__top-social a:hover,
.da-footer__top-social a:hover {
  border-color: transparent;
  color: var(--tp-common-white);
  background-color: var(--tp-theme-cornblue);
}

.law-footer__main .tp-footer__widget ul li:hover a {
  color: white;
}

.about-me-socials .bs-footer__top-social a {
  border-color: transparent;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06);
}

.bs-footer__circle {
  position: absolute;
  left: 180px;
  top: 55px;
  border-radius: 50%;
  background-image: -moz-linear-gradient(
    145deg,
    rgb(246, 246, 246) 0%,
    rgb(255, 255, 255) 100%
  );
  background-image: -webkit-linear-gradient(
    145deg,
    rgb(246, 246, 246) 0%,
    rgb(255, 255, 255) 100%
  );
  background-image: -ms-linear-gradient(
    145deg,
    rgb(246, 246, 246) 0%,
    rgb(255, 255, 255) 100%
  );
  position: absolute;
  width: 204px;
  height: 204px;
  z-index: 1;
  animation: moving 5s ease-in-out infinite;
}

.da-footer__top-social a {
  color: var(--tp-theme-vogue);
  background-color: var(--tp-common-white);
}
.da-footer__top-social a:hover {
  color: var(--tp-common-white);
  background-color: var(--tp-theme-orange);
}

.footer-blog-thum img {
  border-radius: 6px;
}
.footer-blog-info-meta {
  color: var(--tp-grey-1);
  font-size: 12px;
}
.footer-blog-info-meta b {
  color: var(--tp-theme-vogue);
}
.footer-blog-info-meta b:hover {
  color: var(--tp-theme-orange);
}
.footer-blog .ft-blog-cta {
  color: var(--tp-theme-orange);
}
.footer-blog .ft-blog-cta:hover {
  color: var(--tp-theme-vogue);
}
.footer-blog-info-title {
  font-size: 14px;
  line-height: 1.5;
}
.footer-blog-info-title a {
  background-image: linear-gradient(#000, #000), linear-gradient(#000, #000);
  display: inline;
  background-size: 0% 1px, 0 1px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.4s linear;
}
.footer-blog-info-title a:hover {
  background-size: 0 1px, 100% 1px;
}

.fotter-btn-bottom {
  border-bottom: 1px solid var(--tp-border-7);
}

.it-footer .tp-footer__widget-title {
  color: var(--tp-theme-vogue);
}
.it-footer .ft-blog-cta {
  color: var(--tp-theme-orange);
}
.it-footer .footer-blog-info-title {
  color: var(--tp-theme-vogue);
}

.tp-footer__widget-title {
  font-size: 16px;
  font-weight: 400;
  color: #c9c9ce !important;
}
.tp-footer__widget ul li a {
  font-size: 12px;
  margin-bottom: 4px;
  display: inline-block;
  color: var(--tp-grey-1);
  position: relative;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.tp-footer__widget ul li a:hover {
  color: var(--tp-theme-red) !important;
}
.tp-footer__widget ul li a span:hover {
  color: var(--tp-theme-red) !important;
}

.tp-footer__widget ul li a::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 1px;
  background-color: #000;
  display: inline-block;
}
.font-12 {
  font-size: 12px !important;
}
.tp-footer__widget ul li:hover a {
  color: var(--tp-common-black);
}
.tp-footer__widget ul li:hover a::after {
  right: auto;
  left: 0;
  width: 100%;
}
.tp-footer__widget ul#company {
  overflow: hidden;
}
.tp-footer__widget ul#company li {
  width: 50%;
  float: left;
}

.tp-footer__widget p {
  color: var(--tp-grey-1);
  font-size: 12px;
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .tp-footer__widget p {
    padding-right: 0;
  }
}
.tp-footer-cta .call-icon img {
  padding: 12px;
  border: 2px solid var(--tp-border-8);
  margin-right: 20px;
  border-radius: 4px;
}
.tp-footer-cta span p {
  color: var(--tp-theme-redical);
}
.tp-footer-cta span p.text-cornblue {
  color: var(--tp-theme-cornblue);
}
.tp-footer-cta span b {
  font-size: 16px;
}
.tp-footer-cta span b :hover {
  color: var(--tp-grey-1);
}

.ha-footer .footer-blog-info-title:hover a {
  background-image: linear-gradient(#fff, #fff), linear-gradient(#fff, #fff);
  display: inline;
  background-size: 0% 1px, 0 1px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.4s linear;
}
.ha-footer .footer-blog-info-title:hover a:hover {
  background-size: 0% 1px, 100% 1px;
}
.ha-footer ul li a::after {
  background-color: #fff;
}
.ha-footer ul li:hover a {
  color: var(--tp-common-black);
}
.ha-footer ul li:hover a::after {
  right: auto;
  left: 0;
  width: 100%;
}

.law-footer ul li a::after {
  background-color: #fff;
}
.law-footer ul li:hover a {
  color: var(--tp-common-black);
}
.law-footer ul li:hover a::after {
  right: auto;
  left: 0;
  width: 100%;
}

.tp-footer-from form input {
  height: 40px;
  padding-left: 50px;
  padding-right: 75px;
  width: 100%;
  border: 2px solid var(--tp-border-8);
  border-radius: 8px;
}
.tp-footer-from form input::-webkit-input-placeholder {
  color: var(--tp-grey-1);
}
.tp-footer-from form input:-moz-placeholder {
  color: var(--tp-grey-1);
}
.tp-footer-from form input::-moz-placeholder {
  color: var(--tp-grey-1);
}
.tp-footer-from form input:-ms-input-placeholder {
  color: var(--tp-grey-1);
}
.tp-footer-from form button {
  height: 30px;
  font-size: 14px !important;
  /* width: 50px; */
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  background-color: var(--tp-theme-redical);
  color: var(--tp-common-white);
  border-radius: 8px;
}
.tp-footer-from form button:hover {
  background-color: var(--tp-theme-cornblue);
}
.tp-footer-from form span i {
  font-size: 16px;
  position: absolute;
  top: 50%;
  left: 24px;
  transform: translateY(-50%);
}

.tp-footer-from-2 form input {
  height: 60px;
  padding-left: 50px;
  padding-right: 120px;
  width: 100%;
  font-size: 16px;
  border: 2px solid var(--tp-border-8);
  border-radius: 8px;
}
.tp-footer-from-2 form input::-webkit-input-placeholder {
  color: var(--tp-grey-1);
}
.tp-footer-from-2 form input:-moz-placeholder {
  color: var(--tp-grey-1);
}
.tp-footer-from-2 form input::-moz-placeholder {
  color: var(--tp-grey-1);
}
.tp-footer-from-2 form input:-ms-input-placeholder {
  color: var(--tp-grey-1);
}
.tp-footer-from-2 form button {
  position: absolute;
  top: 50%;
  right: 30px;
  font-size: 16px;
  font-weight: 400;
  transform: translateY(-50%);
  color: black;
  border-radius: 8px;
}
.tp-footer-from-2 form button:hover {
  color: var(--tp-theme-mandalay);
}
.tp-footer-from-2 form span i {
  font-size: 16px;
  position: absolute;
  top: 50%;
  left: 24px;
  transform: translateY(-50%);
  color: var(--tp-theme-mandalay);
}

.tp-copyrigh-text {
  font-size: 12px;
  color: var(--tp-grey-1);
}
.tp-copyrigh-text a {
  font-weight: 400;
  color: var(--tp-common-black);
}
.tp-copyrigh-text a:hover {
  color: var(--tp-theme-red) !important;
}

.it-footer .tp-copyrigh-text a:hover {
  color: var(--tp-theme-orange);
}
.it-footer .tp-footer-menu ul li:last-child {
  margin-right: 0;
}
.it-footer .tp-footer-menu ul li:hover a {
  color: var(--tp-theme-orange);
}

.tp-footer-menu {
  font-size: 16px;
  font-weight: 400;
  color: var(--tp-common-black);
}
.tp-footer-menu ul li {
  font-size: 12px !important;
  display: inline-block;
  margin-right: 30px;
}
.tp-footer__widget ul li {
  list-style: none !important;
}

.tp-footer-menu ul li:last-child {
  margin-right: 0;
}

.tp-footer-menu ul li a {
  color: #c9c9ce !important;
}
.tp-footer-menu ul li a:hover {
  color: var(--tp-theme-red) !important;
}

@media (max-width: 767px),
  only screen and (min-width: 576px) and (max-width: 767px),
  only screen and (min-width: 768px) and (max-width: 991px) {
  .tp-footer-cta {
    margin-bottom: 30px;
  }
}

.tp-form-note {
  color: var(--tp-common-black) !important;
}
.tp-form-note span {
  color: var(--tp-theme-cornblue);
}

.bs-pl-60 {
  margin-left: 60px;
}
@media (max-width: 767px),
  only screen and (min-width: 576px) and (max-width: 767px),
  only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (min-width: 992px) and (max-width: 1199px) {
  .bs-pl-60 {
    margin-left: 0;
  }
}

.da-footer .tp-copyrigh-text a:hover,
.ca-footer .tp-copyrigh-text a:hover {
  background-image: linear-gradient(90deg, #5a6ef8, #fe7a8f);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}
.da-footer .tp-footer-menu ul li:hover a,
.ca-footer .tp-footer-menu ul li:hover a {
  background-image: linear-gradient(90deg, #5a6ef8, #fe7a8f);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}
.da-footer .tp-footer-from form button,
.ca-footer .tp-footer-from form button {
  background-image: linear-gradient(
    100.16deg,
    #5a6ef8 9.94%,
    #a874c6 54.82%,
    #fe7a8f 95.56%
  );
  color: var(--tp-common-white);
  border-radius: 8px;
}

.da-ft-social {
  color: var(--tp-common-black);
}
.da-ft-social span {
  padding: 0 8px;
  color: var(--tp-grey-1);
}
.da-ft-social span:hover {
  color: var(--tp-common-black);
}

.da-footer-bg {
  background-image: -moz-linear-gradient(
    90deg,
    rgb(255, 255, 255) 0%,
    rgb(237, 243, 245) 100%
  );
  background-image: -webkit-linear-gradient(
    90deg,
    rgb(255, 255, 255) 0%,
    rgb(237, 243, 245) 100%
  );
  background-image: -ms-linear-gradient(
    90deg,
    rgb(255, 255, 255) 0%,
    rgb(237, 243, 245) 100%
  );
}

.da-ft-copyright-bg {
  background-color: rgb(255, 255, 255);
  box-shadow: 0px -16px 32px 0px rgba(0, 0, 0, 0.04);
  padding-left: 40px;
  padding-right: 40px;
}

.ptg-footer__top-social a {
  position: relative;
  font-size: 16px;
  display: inline-block;
  width: 50px;
  height: 50px;
  color: white;
  background-color: var(--tp-common-dark);
  border: 2px solid #262626;
  text-align: center;
  line-height: 49px;
  margin-right: 5px;
  border-radius: 8px;
  z-index: 12;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.ptg-footer__top-social a::after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-image: linear-gradient(
    100.16deg,
    #5a6ef8 9.94%,
    #a874c6 54.82%,
    #fe7a8f 95.56%
  );
  z-index: -1;
  opacity: 0;
}
.ptg-footer__top-social a:hover {
  color: var(--tp-common-white);
  border-color: transparent;
}
.ptg-footer__top-social a:hover::after {
  opacity: 1;
}

.ptg-footer-info span {
  color: var(--tp-grey-4);
  font-size: 18px;
  margin-bottom: 30px;
  display: inline-block;
}
@media (max-width: 767px) {
  .ptg-footer-info span {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .ptg-footer-info span br {
    display: none;
  }
}
.ptg-footer-info span a {
  color: white;
  font-weight: 400;
  text-decoration: underline;
}

.ptg-footer-copyright {
  font-size: 16px;
  color: var(--tp-grey-1);
}
.ptg-footer-copyright a {
  font-weight: 400;
  color: var(--tp-common-white);
}
.ptg-footer-copyright a:hover {
  background-image: linear-gradient(90deg, #5a6ef8, #fe7a8f);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.ptg-footer-menu {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}
.ptg-footer-menu ul li {
  display: inline-block;
  margin-right: 30px;
}
.ptg-footer-menu ul li:last-child {
  margin-right: 0;
}
.ptg-footer-menu ul li:hover a {
  background-image: linear-gradient(90deg, #5a6ef8, #fe7a8f);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.mp-footer-section-title {
  color: #fff;
  display: block;
  padding-bottom: 25px;
}

.mp-footer-title {
  padding-top: 30px;
  color: #fff;
  font-size: 60px;
  letter-spacing: -2px;
}
@media (max-width: 767px) {
  .mp-footer-title {
    font-size: 30px;
    letter-spacing: inherit;
  }
}
.mp-footer-title span {
  color: #323232;
}

.ft-mail-btn {
  color: #fff;
  font-size: 16px;
  border: 2px solid #171717;
  border-radius: 50%;
  padding: 25px 60px;
}
.ft-mail-btn:hover {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.9);
}

.ft-phone-btn {
  color: #fff;
  font-size: 16px;
  border: 2px solid #171717;
  border-radius: 50%;
  padding: 25px 60px;
}
.ft-phone-btn:hover {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.9);
}

.mp-footer-copyright {
  color: var(--tp-grey-4);
}
.mp-footer-copyright a {
  color: #fff;
}

.mp-footer-menu {
  font-size: 16px;
  font-weight: 400;
  color: var(--tp-grey-4);
}
.mp-footer-menu ul li {
  display: inline-block;
  margin-right: 15px;
}
.mp-footer-menu ul li:last-child {
  margin-right: 0;
}
.mp-footer-menu ul li:hover a {
  color: #fff;
}

.law-footer__main .tp-footer__widget-title {
  color: #fff;
}
.law-footer__main .tp-footer__widget ul li a {
  color: #bea8b4;
}
.law-footer__main .tp-footer__widget p {
  color: #bea8b4;
}
.law-footer__main .tp-footer-cta p span {
  color: #bea8b4;
}
.law-footer__main .tp-footer-cta p a {
  color: #fff;
}
.law-footer__main .tp-footer-cta .call-icon img {
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.06);
  border-color: transparent;
  background-color: #fff;
  border-radius: 8px;
}
.law-footer__main .tp-footer-from form input::-webkit-input-placeholder {
  color: #bbbbbb;
}
.law-footer__main .tp-footer-from form input:-moz-placeholder {
  color: #bbbbbb;
}
.law-footer__main .tp-footer-from form input::-moz-placeholder {
  color: #bbbbbb;
}
.law-footer__main .tp-footer-from form input:-ms-input-placeholder {
  color: #bbbbbb;
}
.law-footer__main .tp-footer-from form button {
  background-color: var(--tp-theme-red);
}
.law-footer__main .tp-footer-from form span i {
  color: var(--tp-theme-wine);
}
.law-footer__main .tp-form-note {
  color: #99798b !important;
}
.law-footer__main .tp-form-note span {
  color: #99798b;
}
.law-footer__main .da-ft-social {
  color: white !important;
}
.law-footer__main .da-ft-social span {
  color: #94808b;
}
.law-footer__main .da-ft-social span:hover {
  color: white;
}

.law-footer__bottom {
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 8px;
}
.law-footer__bottom .tp-copyrigh-text {
  color: #fff;
}
.law-footer__bottom .tp-copyrigh-text a {
  color: white;
}
.law-footer__bottom .tp-copyrigh-text a:hover {
  opacity: 0.8;
}
.law-footer__bottom .tp-footer-menu {
  color: #fff;
}
.law-footer__bottom .tp-footer-menu ul li:hover a {
  opacity: 0.8;
  color: #fff;
}

.ca-footer .da-footer__top-social a {
  color: #000;
  position: relative;
  z-index: 3;
}
.ca-footer .da-footer__top-social a::after {
  position: absolute;
  top: 0;
  right: 0;
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-image: linear-gradient(
    100.16deg,
    #5a6ef8 9.94%,
    #a874c6 54.82%,
    #fe7a8f 95.56%
  );
  z-index: -1;
  opacity: 0;
}
.ca-footer .da-footer__top-social a:hover {
  color: white;
  background-color: inherit;
}
.ca-footer .da-footer__top-social a:hover::after {
  opacity: 1;
}
.ca-footer .tp-footer__widget-title {
  color: black;
}
.ca-footer .footer-blog-info-meta span a b {
  color: black;
}

.ha-footer .da-footer__top-social a {
  color: #939393;
  background-color: #111111;
  border-color: #111111;
}
.ha-footer .da-footer__top-social a:hover {
  color: white;
  background-color: var(--tp-theme-mandalay);
}
.ha-footer .tp-footer__widget-title {
  color: white;
}
.ha-footer .tp-footer__widget p {
  color: #b2b2b2;
}
.ha-footer .footer-blog-info-meta span a b {
  color: black;
}
.ha-footer .footer-blog-info-meta span {
  color: #b2b2b2;
  font-size: 16px;
}
.ha-footer .footer-blog-info-title {
  color: white;
}
.ha-footer .tp-border-bottom,
.ha-footer .tp-border-top,
.ha-footer .fotter-btn-bottom {
  border-color: #141414;
}
.ha-footer .tp-footer__widget ul li:hover a {
  color: white;
}

.ha-footer-copyright {
  background-color: var(--tp-theme-mandalay);
  box-shadow: 0px -16px 32px 0px rgba(0, 0, 0, 0.04);
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 12px 12px 0 0;
}
.ha-footer-copyright .tp-copyrigh-text {
  color: white;
}
.ha-footer-copyright .tp-copyrigh-text a {
  color: #fff;
}
.ha-footer-copyright .tp-copyrigh-text a:hover {
  opacity: 0.7;
}
.ha-footer-copyright .tp-footer-menu ul li a {
  color: white;
}
.ha-footer-copyright .tp-footer-menu ul li a:hover {
  opacity: 0.7;
}

.seo-footer .tp-footer__widget-title {
  color: var(--tp-theme-seagreen);
}
.seo-footer .da-footer__top-social a {
  color: var(--tp-theme-seagreen);
  font-size: 16px;
  display: inline-block;
  width: 50px;
  height: 50px;
  background-color: var(--tp-common-white);
  border: 2px solid var(--tp-border-6);
  text-align: center;
  line-height: 49px;
  margin-right: 5px;
  border-radius: 8px;
}
.seo-footer .da-footer__top-social a:hover {
  color: var(--tp-common-white);
  background-color: var(--tp-theme-lochmara);
}
.seo-footer .footer-blog-info-title {
  color: var(--tp-theme-seagreen);
}
.seo-footer .footer-blog-info-meta b {
  color: var(--tp-theme-seagreen);
}
.seo-footer .footer-blog-info-meta b.ft-blog-cta {
  color: var(--tp-theme-lochmara);
}
.seo-footer .tp-copyrigh-text a {
  color: var(--tp-theme-seagreen);
}
.seo-footer .tp-copyrigh-text a:hover {
  color: var(--tp-theme-lochmara);
}
.seo-footer .tp-footer-menu {
  color: var(--tp-theme-seagreen);
}
.seo-footer .tp-footer-menu a:hover {
  color: var(--tp-theme-lochmara);
}

/*# sourceMappingURL=style.css.map */
