
/* 3.2 wpo-blog-hero-area */

.wpo-blog-hero-area {


    .wpo-blog-grids {
        margin: 0 -7.5px;

        .grid {
            width: 25%;
            float: left;
            padding: 0 7.5px 15px;
            width: 50%;
            justify-content: space-between;

            img {
                width: 100%;
            }

            @media(max-width:1200px) {
                width: 100%;
            }

            &.s2 {
                display: flex;
                margin: 0 -10px;
                padding-right: 0;

                @media(max-width:767px) {
                    margin: 0;
                    padding-right: 7.5px;
                }

                @media(max-width:767px) {
                    display: block;
                }

                .img-holder {
                    margin: 0 10px;

                    @media(max-width:767px) {
                        margin: 0;
                        margin-bottom: 15px;
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }

            img {
                width: 100%;
            }

            .img-holder {
                position: relative;
                border-radius: 15px;
                overflow: hidden;
                z-index: 1;

                img {
                    border-radius: 15px;
                    transform: scale(1);
                    transition: all .3s;
                }

                &:hover {
                    img {
                        transform: scale(1.2);
                    }
                }

                &:before {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    content: "";
                    background: transparentize(#070707, .6);
                    border-radius: 15px;
                    z-index: 11;
                }

                .wpo-blog-content {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    padding: 30px;
                    z-index: 19;

                    @media(max-width:450px) {
                        padding: 15px;
                    }

                    .thumb {
                        padding: 3px 15px;
                        background: transparentize($white, .2);
                        color: $theme-primary-color-s2;
                        text-align: center;
                        font-size: 20px;
                        border-radius: 5px;
                        display: inline-block;
                        font-weight: 400;

                        @media(max-width:450px) {
                            font-size: 16px;
                        }
                    }

                    h2 {
                        font-size: 35px;
                        color: $white;
                        font-weight: 600;
                        margin-top: 15px;


                        @media(max-width:450px) {
                            font-size: 20px;
                        }

                        a {
                            color: $white;
                        }
                    }

                    p {
                        color: $white;
                        margin-top: 15px;

                        @media(max-width:450px) {
                            font-size: 14px;
                            margin-bottom: 0;
                        }
                    }

                    ul {
                        display: flex;
                        align-items: center;
                        list-style: none;

                        li {
                            position: relative;
                            color: $white;

                            &+li {
                                margin-left: 20px;
                            }

                            &:last-child {
                                padding-left: 10px;

                                &:before {
                                    position: absolute;
                                    left: -8px;
                                    top: 6px;
                                    width: 8px;
                                    height: 8px;
                                    content: "";
                                    background: $white;
                                    border-radius: 50%;
                                }
                            }

                            a {
                                color: $white;
                            }

                            img {
                                width: 40px;
                                height: 40px;
                                border-radius: 50%;
                            }
                        }
                    }
                }
            }

            &+.grid {
                .wpo-blog-content {
                    h2 {
                        font-size: 25px;

                        @media(max-width:450px) {
                            font-size: 20px;
                            margin-top: 8px;
                            margin-bottom: 6px;
                        }
                    }
                }
            }
        }
    }
}


/* 3.3 wpo-breacking-news */

.wpo-breacking-news {
    .b-title {
        padding-left: 0;

        span {
            font-size: 15px;
            padding: 5px 10px;
            background: $theme-primary-color;
            display: inline-block;
            border-radius: 10px 10px 0 0;
            color: $white;
            margin-bottom: 0;
        }
    }

    .wpo-breacking-wrap {
        background: $section-bg-color;
        border-top: 1px solid #e9edff;
        border-bottom: 1px solid #e9edff;
        padding: 0;

        .wpo-breacking-item {
            display: flex;
            padding: 20px;
            border-left: 1px solid #e9edff;
            border-right: 1px solid #e9edff;
            align-items: center;
            margin: -1px;

            .wpo-breacking-img {
                flex-basis: 30%;
                border-radius: 6px;

                img {
                    border-radius: 6px;
                    width: 100%;
                }
            }

            .wpo-breacking-text {
                flex-basis: 70%;
                padding-left: 15px;

                h3 {
                    font-size: 18px;
                    font-weight: 600;
                    margin-top: 10px;

                    a {
                        color: $text-color;

                        &:hover {
                            color: $theme-primary-color;
                        }
                    }
                }
            }
        }

        .owl-nav {
            display: none;
        }
    }
}



/* 3.4 wpo-blog-highlights-section */

.wpo-blog-highlights-section {

    .wpo-blog-highlights-wrap {
        position: relative;
        z-index: 1;
        border: 1px solid $border-color;
        padding: 30px;

        @media(max-width:767px) {
            padding-bottom: 60px;
        }

        @media(max-width:575px) {
            padding: 15px;
        }

        .wpo-blog-item {
            margin-bottom: 30px;
            padding-bottom: 20px;
            border-bottom: 1px solid $border-color;

            .wpo-blog-img {
                overflow: hidden;
                position: relative;
                border-radius: 6px;

                .thumb {
                    position: absolute;
                    left: 15px;
                    top: 15px;
                    padding: 7px 25px 6px;
                    background: $theme-primary-color;
                    color: $white;
                    text-transform: uppercase;
                    font-size: 14px;
                    border-radius: 5px;
                }

                img {
                    width: 100%;
                    -webkit-filter: grayscale(0);
                    -moz-filter: grayscale(0);
                    -o-filter: grayscale(0);
                    -ms-filter: grayscale(0);
                    filter: grayscale(0);
                    transition: all .3s;
                    transform: scale(1);
                    border-radius: 6px;
                }
            }

            &:hover {
                .wpo-blog-img {
                    img {
                        -webkit-filter: grayscale(100%);
                        -moz-filter: grayscale(100%);
                        -o-filter: grayscale(100%);
                        -ms-filter: grayscale(100%);
                        filter: grayscale(100%);
                        transform: scale(1.2);
                    }
                }
            }


            .wpo-blog-content {
                padding-top: 20px;

                ul {
                    list-style: none;
                    display: flex;
                    margin-bottom: 15px;
                    align-items: center;

                    li {
                        color: $theme-primary-color-s2;
                        font-size: 16px;

                        &:last-child {
                            padding-left: 30px;
                            position: relative;

                            &:before {
                                position: absolute;
                                left: 10px;
                                top: 50%;
                                transform: translateY(-50%);
                                width: 6px;
                                height: 6px;
                                content: "";
                                background: $theme-primary-color;
                                border-radius: 50%;
                            }
                        }

                        a {
                            color: $theme-primary-color-s2;

                            &:hover {
                                color: $theme-primary-color;
                            }
                        }

                        img {
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            margin-right: 10px;
                        }
                    }
                }

                h2 {
                    font-size: 20px;
                    font-family: $heading-font;
                    margin-bottom: 20px;
                    line-height: 30px;
                    font-weight: 400;
                    margin-top: 10px;


                    @media(max-width:1400px) {
                        font-size: 22px;
                        margin-bottom: 10px;
                        line-height: 26px;
                    }

                    @media(max-width:1200px) {
                        font-size: 20px;
                    }

                    a {
                        color: $text-color;

                        &:hover {
                            color: $theme-primary-color;
                        }
                    }
                }

                p {
                    font-size: 16px;
                    color: $text-color;
                    margin-bottom: 0;
                }

            }
        }

        .col {

            &:nth-child(5),
            &:nth-child(6) {
                .wpo-blog-item {
                    margin-bottom: 0;
                    padding-bottom: 0;
                    border-bottom: 0;
                }
            }
        }
    }

    @media screen and (min-width: 992px) {
        .blog-sidebar {
            padding-left: 10px;
        }
    }

    @media (max-width: 991px) {
        .blog-sidebar {
            margin-top: 80px;
            max-width: 100%;
        }
    }
}



/* 3.5 wpo-blog-sponsored-section */

.wpo-blog-sponsored-section {
    padding-bottom: 50px;

    &.s2{
        padding-bottom: 0;
    }
    
    .wpo-blog-sponsored-wrap {
        position: relative;
        z-index: 1;

        .wpo-blog-item {
            margin-bottom: 30px;

            .wpo-blog-img {
                overflow: hidden;
                position: relative;
                border-radius: 6px;

                .thumb {
                    position: absolute;
                    left: 15px;
                    top: 15px;
                    padding: 7px 25px 6px;
                    background: $theme-primary-color;
                    color: $white;
                    text-transform: uppercase;
                    font-size: 14px;
                    border-radius: 5px;

                }

                img {
                    width: 100%;
                    -webkit-filter: grayscale(0);
                    -moz-filter: grayscale(0);
                    -o-filter: grayscale(0);
                    -ms-filter: grayscale(0);
                    filter: grayscale(0);
                    transition: all .3s;
                    transform: scale(1);
                    border-radius: 6px;
                }
            }

            &:hover {
                .wpo-blog-img {
                    img {
                        -webkit-filter: grayscale(100%);
                        -moz-filter: grayscale(100%);
                        -o-filter: grayscale(100%);
                        -ms-filter: grayscale(100%);
                        filter: grayscale(100%);
                        transform: scale(1.2);
                    }
                }
            }


            .wpo-blog-content {
                padding-top: 20px;

                ul {
                    list-style: none;
                    display: flex;
                    margin-bottom: 15px;
                    align-items: center;

                    li {
                        color: $theme-primary-color-s2;
                        font-size: 16px;

                        &:last-child {
                            padding-left: 30px;
                            position: relative;

                            &:before {
                                position: absolute;
                                left: 10px;
                                top: 50%;
                                transform: translateY(-50%);
                                width: 6px;
                                height: 6px;
                                content: "";
                                background: $theme-primary-color;
                                border-radius: 50%;
                            }
                        }

                        a {
                            color: $theme-primary-color-s2;

                            &:hover {
                                color: $theme-primary-color;
                            }
                        }

                        img {
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            margin-right: 10px;
                        }
                    }
                }

                h2 {
                    font-size: 20px;
                    font-family: $heading-font;
                    margin-bottom: 20px;
                    line-height: 30px;
                    font-weight: 400;
                    margin-top: 10px;


                    @media(max-width:1400px) {
                        font-size: 18px;
                        margin-bottom: 10px;
                        line-height: 26px;
                    }

                    @media(max-width:1200px) {
                        font-size: 20px;
                    }

                    a {
                        color: $text-color;

                        &:hover {
                            color: $theme-primary-color;
                        }
                    }
                }

                p {
                    font-size: 16px;
                    color: $text-color;
                    margin-bottom: 0;
                }

            }
        }
    }
}


/* 3.6 wpo-subscribe-section  */

.wpo-subscribe-section {
    padding-top: 0;

    .wpo-subscribe-wrap {
        padding: 70px 100px;
        background: url(../../images/newslatter-bg.png) no-repeat center center;
        border-radius: 60px;
        position: relative;
        overflow: hidden;
        background-size: cover;
        z-index: 1;

        @media(max-width:1199px) {
            padding: 70px;
        }

        @media(max-width:991px) {
            padding: 50px 30px;
        }

        @media(max-width:575px) {
            padding: 40px 15px;
            border-radius: 30px;
        }

        .subscribe-text {
            text-align: center;
            margin-bottom: 40px;

            @media(max-width:991px) {
                margin-bottom: 20px;
            }

            p {
                font-size: 21px;
                color: $white;

                @media(max-width:991px) {
                    font-size: 16px;
                }
            }

            h3 {
                font-size: 40px;
                color: $white;
                margin-top: 10px;
                margin-bottom: 10px;
                font-weight: 400;

                @media(max-width:1199px) {
                    font-size: 32px;
                }

                @media(max-width:991px) {
                    font-size: 28px;
                    margin-top: 0;
                }


                @media(max-width:575px) {
                    font-size: 24px;
                }

            }
        }

        .subscribe-form {
            flex-basis: 70%;

            .input-field {
                position: relative;
                max-width: 600px;
                margin: 0 auto;

                input {
                    width: 100%;
                    padding: 40px;
                    padding-right: 190px;
                    height: 75px;
                    border-radius: 5px;
                    background: $white;
                    border: 0;
                    color: $text-color;

                    @media(max-width:767px) {
                        height: 55px;
                        padding: 30px;
                        padding-right: 145px;
                    }

                    @media(max-width:450px) {
                        padding: 10px;
                        padding-right: 100px;
                    }

                    &:focus {
                        outline: none;
                        box-shadow: none;
                    }
                }

                button {
                    position: absolute;
                    right: 8px;
                    top: 8px;
                    height: 65px;
                    width: 170px;
                    border: 0;
                    background: $theme-primary-color;
                    border-radius: 3px;
                    color: $white;
                    padding-right: 40px;

                    @media(max-width:767px) {
                        height: 45px;
                        width: 130px;
                    }

                    @media(max-width:450px) {
                        width: 80px;
                        height: 40px;
                    }

                    &:before {
                        position: absolute;
                        right: 40px;
                        top: 50%;
                        content: "\f117";
                        font-family: "Flaticon";
                        transform: translateY(-50%);

                        @media(max-width:767px) {
                            right: 20px;
                        }

                        @media(max-width:450px) {
                            display: none;
                        }

                    }

                }

                ::-webkit-input-placeholder {
                    color: $text-color;
                }

                :-ms-input-placeholder {
                    color: $text-color;
                }

                ::placeholder {
                    color: $text-color;
                }
            }

        }
    }

    &.s2{
       padding-top: 80px;
    }
}